// frontend\src\pages\LeadDetails.js
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef, useMemo, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronDownIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  VStack,
  Input,
  HStack,
  Text,
  Button,
  IconButton,
  Divider,
  Heading,
  useToast,
  Badge,
  Tooltip,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  SimpleGrid,
  Spinner,
  ButtonGroup,
  EditableInput,
  EditablePreview,
  Editable,
  FormControl,
  FormLabel,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spacer,
  useEditableControls,
  chakra,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  InputGroup,
  InputRightElement,
  ModalFooter,
  Textarea,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdAdd,
  MdSend,
  MdCall,
  MdSms,
  MdMic,
  MdVolumeUp,
  MdPhoneSlash,
  MdMicrophoneAlt,
  MdMicrophoneAltSlash,
  MdUsers,
  MdUserAlt,
  MdMicOff,
  MdVolumeOff,
  MdCallEnd,
  MdEdit,
  MdDelete,
  MdArchive,
  MdUnarchive,
} from 'react-icons/md';
import {
  FaPhone,
  FaPhoneSlash,
  FaMicrophoneAlt,
  FaMicrophoneAltSlash,
  FaUsers,
  FaUserAlt,
  FaGlobe,
  FaClipboardList,
  FaHome,
  FaHistory,
  FaUserFriends,
  FaRedoAlt,
  FaFire,
  FaLeaf,
  FaBinoculars,
  FaBolt,
  FaCog,
  FaSms,
  FaEdit,
  FaHeart,
  FaPhoneAlt,
  FaVolumeMute,
  FaVolumeUp,
  FaMicrophoneSlash,
  FaMicrophone,
} from 'react-icons/fa';
import axios from 'axios';
import { Device } from '@twilio/voice-sdk'; // Ensure Twilio Device is imported
import TagsBox from '../components/TagsBox';
import LeadTimeline from '../components/LeadTimeline';
import NotesBox from '../components/NotesBox';
import TasksBox from '../components/TasksBox';
import AlertsBox from '../components/AlertsBox';
import ShowingsBox from '../components/ShowingsBox';
import AddNoteModal from '../components/AddNoteModal';
import { motion } from 'framer-motion';
import io from 'socket.io-client';
import { TwilioContext } from '../context/TwilioContext';
import CallActions from '../components/CallActions'; // Import the new component
import socket from '../socket'; // Ensure socket is imported correctly
import { debounce } from 'lodash';
import LeadProfileSection from '../components/LeadProfileSection';
import MAWScore from '../components/MAWScore';

// Add these utility functions at the top of the file
const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');
  // Ensure the number starts with a country code (assuming US, so add +1 if not present)
  return cleaned.startsWith('1') ? `+${cleaned}` : `+1${cleaned}`;
};

const callOptions = [
  { value: 'direct', label: 'Direct Call', icon: FaPhone },
  { value: 'personal', label: 'Call Personal Cell', icon: FaPhoneSlash },
];


const getUserIdentity = () => {
  return 'user_' + Math.random().toString(36).substr(2, 9); // generates a random user ID
};


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';


const formatPropertyUrl = (name, condosCaId) => {
  if (!name || !condosCaId) return null;
  const formattedName = name.toLowerCase().replace(/\s+/g, '_');
  return `https://crm.property.ca/leads/${formattedName}_${condosCaId}`;
};

const LeadDetails = () => {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  
  // 1. First, move all useState declarations
  const [socket, setSocket] = useState(null);
  const [lead, setLead] = useState(null);
  const [activities, setActivities] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [callLogs, setCallLogs] = useState([]);
  const [smsLogs, setSmsLogs] = useState([]); // SMS Logs State
  const [showings, setShowings] = useState([]);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const [timelineFilter, setTimelineFilter] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [timelineItems, setTimelineItems] = useState([]);
  const [isCallActive, setIsCallActive] = useState(false); // Tracks if a call is active
  const [activeCall, setActiveCall] = useState(null);
  const [call, setCall] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isSpeakerOn, setIsSpeakerOn] = useState(false);
  const [callSid, setCallSid] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [leadSource, setLeadSource] = useState('');
  const [error, setError] = useState(null);
  const [device, setDevice] = useState(null);
  const [isDeviceReady, setIsDeviceReady] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const deviceRef = useRef(null);
  const activeCallRef = useRef(null);
  const [callDuration, setCallDuration] = useState(0);
  const [isInitializingDevice, setIsInitializingDevice] = useState(true);
  const [activeConnection, setActiveConnection] = useState(null);
  const [notes, setNotes] = useState([]);
  const [isAudioContextReady, setIsAudioContextReady] = useState(false);
  const [isTwilioDeviceInitialized, setIsTwilioDeviceInitialized] = useState(false);
  const [callStatus, setCallStatus] = useState('idle');
  const [callType, setCallType] = useState('direct'); // 'direct' or 'personal'
  const [timeline, setTimeline] = useState([]);
  const { isOpen: isDeleteAlertOpen, onOpen: onDeleteAlertOpen, onClose: onDeleteAlertClose } = useDisclosure();
  const cancelRef = useRef();
  const [noteContent, setNoteContent] = useState('');
  
  // Get the twilioDevice and isTwilioDeviceReady from TwilioContext
  const { device: twilioDevice, isDeviceReady: isTwilioDeviceReady } = useContext(TwilioContext);

  // 1. Add these state declarations with your other useState declarations
  const [isJobUpdating, setIsJobUpdating] = useState(false);
  const [jobUpdateStatus, setJobUpdateStatus] = useState(null);

  // Add or update the current call info state
  const [currentCallInfo, setCurrentCallInfo] = useState(null);

  // 1. Remove the separate debounce wrapper and put it inside useCallback
  const debouncedJobUpdate = useCallback(
    (value) => {
      const delayedUpdate = debounce((val) => {
        // Only make API call if value is not empty
        if (!val.trim()) return;

        setIsJobUpdating(true);
        axios.put(`/api/leads/${id}`, {
          ...lead,
          job: val,
          name: lead.name,
          email: lead.email,
          phone: lead.phone,
        })
          .then((response) => {
            if (response.data) {
              setLead(response.data);
              setJobUpdateStatus('success');
              toast({
                title: 'Update Successful',
                description: 'Job title has been updated',
                status: 'success',
                duration: 3000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.error('Error updating job:', error);
            setJobUpdateStatus('error');
            toast({
              title: 'Update Failed',
              description: error.response?.data?.message || 'Failed to update job title',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });

            setLead((prevLead) => ({
              ...prevLead,
              job: lead.job,
            }));
          })
          .finally(() => {
            setIsJobUpdating(false);
            setTimeout(() => {
              setJobUpdateStatus(null);
            }, 2000);
          });
      }, 1000);

      delayedUpdate(value);
      
      // Cleanup function
      return () => delayedUpdate.cancel();
    },
    [id, lead, toast, setLead, setIsJobUpdating, setJobUpdateStatus]
  );

  // 2. Then, define the debounced function using useCallback
  const debouncedUpdate = useCallback(
    (field, value) => {
      if (value.trim() === '') return;

      axios.put(`/api/leads/${id}`, {
        ...lead,
        [field]: value,
        // Include required fields
        name: lead.name,
        email: lead.email,
        phone: lead.phone
      })
        .then((response) => {
          if (response.data) {
            setLead(response.data);
            toast({
              title: 'Update Successful',
              description: `Successfully updated ${field}`,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.error(`Error updating ${field}:`, error);
          toast({
            title: 'Update Failed',
            description: error.response?.data?.message || `Failed to update ${field}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          
          // Revert the optimistic update
          setLead(prevLead => ({
            ...prevLead,
            [field]: lead[field] // Revert to previous value
          }));
        });
    },
    [id, lead, toast]
  );

  // 3. Finally, define handleJobUpdate with proper dependencies
  const handleJobUpdate = useCallback((newValue) => {
    // Allow empty values or any length input
    setLead(prevLead => ({
      ...prevLead,
      job: newValue
    }));
    
    // Only show validation warning if user has stopped typing and value is too short
    if (newValue.trim() && newValue.length < 3) {
      setJobUpdateStatus('warning');
      return;
    }

    // Only trigger update if value is empty or at least 3 characters
    if (!newValue.trim() || newValue.length >= 3) {
      debouncedJobUpdate(newValue);
    }
  }, [debouncedJobUpdate, setLead, setJobUpdateStatus]); // Added all dependencies

  // 3. Move the updateCallStatus callback here
  const updateCallStatus = useCallback(async (callSid, status) => {
    try {
      const response = await axios.put(`/api/twilio/calls/${callSid}`, { status });
      if (response.data) {
        setTimeline(prevTimeline => 
          prevTimeline.map(item => 
            item.type === 'Call' && item.twilioCallSid === callSid
              ? { ...item, status }
              : item
          )
        );
      }
    } catch (error) {
      console.error('Error updating call status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update call status',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast, setTimeline]);

  // 4. Move other useCallback declarations here
  const fetchActivities = useCallback(async () => {
    if (!lead || !lead._id) return;

    try {
      const response = await axios.get(`/api/leads/${lead._id}/activities`);
      const activities = response.data;

      const updatedActivities = await Promise.all(activities.map(async (activity) => {
        if (activity.type === 'Call' && activity.twilioCallSid) {
          const callDetails = await axios.get(`/api/twilio/calls/${activity.twilioCallSid}`);
          return { ...activity, ...callDetails.data };
        }
        return activity;
      }));

      setTimelineItems(updatedActivities);
    } catch (error) {
      console.error('Error fetching activities:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch lead activities',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [lead, toast]);

  // 5. Move handleInputChange callback here
  const handleInputChange = useCallback((field, value) => {
    // Optimistic update
    setLead(prevLead => ({
      ...prevLead,
      [field]: value
    }));
    
    debouncedUpdate(field, value);
  }, [debouncedUpdate]);

  // Add this function to your component
  const handleStartScript = useCallback(() => {
    if (!lead) {
      toast({
        title: "Error",
        description: "Lead data is not available.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Cleanup any active calls or devices before navigation
    if (activeCallRef.current) {
      try {
        activeCallRef.current.disconnect?.();
        activeCallRef.current.removeAllListeners?.();
      } catch (err) {
        console.error('Error disconnecting active call:', err);
      }
      activeCallRef.current = null;
    }

    if (deviceRef.current) {
      try {
        deviceRef.current.unregister?.();
        deviceRef.current.destroy?.();
      } catch (err) {
        console.error('Error cleaning up device:', err);
      }
      deviceRef.current = null;
    }

    navigate('/call-script', { 
      state: { 
        leadData: lead,
        currentStageIndex: { section: 0, stage: 0 }
      }
    });
  }, [lead, navigate, toast]);

  // Add these handler functions before using them
  const handleCallAccepted = useCallback(() => {
    console.log('Call accepted');
    setCallStatus('in-progress');
    toast({
      title: 'Call Connected',
      description: 'The call is now in progress.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }, [toast]);

  const handleCallDisconnected = useCallback(() => {
    console.log('Call disconnected');
    setIsCallActive(false);
    setCall(null);
    activeCallRef.current = null;
    toast({
      title: 'Call Ended',
      description: 'The call has been disconnected.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  }, [toast]);

  const handleCallCanceled = useCallback(() => {
    console.log('Call canceled');
    setIsCallActive(false);
    setCall(null);
    activeCallRef.current = null;
    toast({
      title: 'Call Canceled',
      description: 'The call was canceled.',
      status: 'warning',
      duration: 3000,
      isClosable: true,
    });
  }, [toast]);

  const handleCallError = useCallback((error) => {
    console.error('Call error:', error);
    setIsCallActive(false);
    setCall(null);
    activeCallRef.current = null;
    toast({
      title: 'Call Error',
      description: error.message || 'An error occurred during the call.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  }, [toast]);

  // Move all useEffects to the top level, after state declarations and handlers
  useEffect(() => {
    // Component cleanup effect
    return () => {
      // Cleanup for active call
      if (activeCallRef.current) {
        try {
          const currentCall = activeCallRef.current;
          if (typeof currentCall.disconnect === 'function') {
            currentCall.disconnect();
          }
          currentCall.removeAllListeners?.();
        } catch (err) {
          console.error('Error cleaning up active call:', err);
        } finally {
          activeCallRef.current = null;
        }
      }

      // Cleanup for Twilio device
      if (deviceRef.current) {
        try {
          const device = deviceRef.current;
          if (typeof device.unregister === 'function') {
            device.unregister();
          }
          if (typeof device.destroy === 'function') {
            device.destroy();
          }
        } catch (err) {
          console.error('Error cleaning up Twilio device:', err);
        } finally {
          deviceRef.current = null;
        }
      }
    };
  }, []);

  // Call effect
  useEffect(() => {
    if (!call) return;

    activeCallRef.current = call;
    const handleDisconnect = () => {
      setIsCallActive(false);
      setCall(null);
      activeCallRef.current = null;
    };

    call.on('disconnect', handleDisconnect);
    call.on('error', handleCallError);
    call.on('accept', handleCallAccepted);

    return () => {
      if (activeCallRef.current) {
        try {
          activeCallRef.current.removeListener('disconnect', handleDisconnect);
          activeCallRef.current.removeListener('error', handleCallError);
          activeCallRef.current.removeListener('accept', handleCallAccepted);
        } catch (err) {
          console.error('Error removing call listeners:', err);
        }
      }
    };
  }, [call, handleCallError, handleCallAccepted]);

  // 1. First, define pollCallStatus before handleStartCall
  const pollCallStatus = useCallback(async (callSid) => {
    if (!callSid) return;
    
    try {
      const response = await axios.get(`/api/twilio/call-status/${callSid}`);
      const status = response.data.status;
      
      setCallStatus(status);
      
      // Continue polling if call is still active
      if (['initiated', 'ringing', 'in-progress'].includes(status)) {
        window.pollCallStatusTimeout = setTimeout(() => pollCallStatus(callSid), 3000);
      } else {
        // Call ended, cleanup
        if (status === 'completed' || status === 'failed') {
          setIsCallActive(false);
          setActiveCall(null);
          setCallSid(null);
        }
      }
    } catch (error) {
      console.error('Error polling call status:', error);
      // Stop polling on error
      setCallStatus('failed');
      setIsCallActive(false);
      setActiveCall(null);
      setCallSid(null);
    }
  }, [setCallStatus, setIsCallActive, setActiveCall, setCallSid]);

  // 2. Then define handleStartCall which uses pollCallStatus
  const handleStartCall = useCallback(async (callType) => {
    if (!lead?._id) {
      toast({
        title: 'Error',
        description: 'Lead information is not available',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      let call;
      if (callType === 'direct') {
        call = await device.connect({ 
          params: { 
            To: formatPhoneNumber(lead.phone),
            leadId: lead._id,
            callType: 'direct'
          } 
        });
        
        // Store references
        activeCallRef.current = call;
        deviceRef.current = device;
        
        // Update state
        setActiveCall(call);
        setIsCallActive(true);
        
        // Set up event listeners
        call.on('accept', (acceptedCall) => {
          const sid = acceptedCall.parameters.CallSid;
          setCallSid(sid);
          pollCallStatus(sid);
        });

        call.on('disconnect', () => {
          handleCallDisconnected();
          if (window.pollCallStatusTimeout) {
            clearTimeout(window.pollCallStatusTimeout);
          }
        });

      } else if (callType === 'personal') {
        const response = await axios.post('/api/twilio/initiate-call', {
          to: formatPhoneNumber(lead.phone),
          leadId: lead._id,
          callType: 'personal'
        });

        if (response.data.success) {
          setCallSid(response.data.callSid);
          setIsCallActive(true);
          pollCallStatus(response.data.callSid);
        }
      }
    } catch (error) {
      console.error('Error starting call:', error);
      handleCallError(error);
    }
  }, [device, lead, pollCallStatus, handleCallDisconnected, handleCallError]);

  // 3. Add cleanup effect for polling
  useEffect(() => {
    return () => {
      if (window.pollCallStatusTimeout) {
        clearTimeout(window.pollCallStatusTimeout);
      }
    };
  }, []);

  // Function to initiate a direct call
  const connectDirectly = async (phoneNumber) => {
    console.log('Connecting directly to:', phoneNumber);
    try {
      if (!device) {
        console.error('Twilio device is not initialized');
        return;
      }
      
      console.log('Device state before connection:', device.state);
      
      // Only attempt to register if the device is unregistered
      if (device.state === 'unregistered') {
        try {
          console.log('Device is unregistered, attempting to register...');
          await device.register();
        } catch (error) {
          if (error.message.includes('already registered')) {
            console.log('Device was already registered');
          } else {
            throw error;
          }
        }
      }
      
      console.log('Device state after registration check:', device.state);
      
      if (device.state === 'registered') {
        const call = await device.connect({ params: { To: phoneNumber } });
        console.log('Call initiated:', call);
        setActiveCall(call);
        setCallStatus('initiated');
      } else {
        console.error('Device is not in registered state:', device.state);
        toast({
          title: 'Call Error',
          description: 'Device is not ready. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error connecting directly:', error);
      toast({
        title: 'Call Error',
        description: `Failed to connect: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


   // Function to call a personal number
   const callPersonalNumber = async () => {
    try {
      const response = await axios.post('/api/twilio/initiate-call', {
        to: lead.phone,
        leadId: lead._id,
        callType: 'personal'
      });

      if (response.data.success) {
        setCallSid(response.data.callSid);
        pollCallStatus(response.data.callSid);
      } else {
        throw new Error(response.data.message || 'Failed to initiate personal call');
      }
    } catch (error) {
      console.error('Error initiating personal call:', error);
      toast({
        title: 'Call Error',
        description: `Failed to initiate personal call: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  
  // Function to handle ending a call
  const handleEndCall = async () => {
    if (activeCall) {
      activeCall.disconnect();
    }
  
    setIsCallActive(false);
    setActiveCall(null);
    setCallSid(null);
  
    toast({
      title: 'Call Ended',
      description: 'The call has been successfully ended.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  
    // Only update the call log if we have a valid callSid
    if (callSid) {
      await updateCallLog(lead._id, callSid);
    }
  
    // Open the notes modal instead of using prompt()
    onOpen();
  
    // Refresh activities
    fetchActivities();
  };
  
  // Function to end a call
  const endCall = async () => {
    if (activeConnection) {
      activeConnection.disconnect();
    }
    if (callSid) {
      try {
        await axios.post(`/api/twilio/end-call/${callSid}`);
        setIsCallActive(false); // Set isCallActive to false when ending the call
        setCallSid(null);
        setCallStatus('completed');
        toast({
          title: 'Call Ended',
          description: 'The call has been successfully ended.',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        
        // Open the notes modal instead of using prompt()
        onOpen();
        
        fetchActivities(); // Refresh activities
      } catch (error) {
        toast({
          title: 'End Call Error',
          description: `Failed to end call: ${error.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // Function to toggle mute
  const handleToggleMute = () => {
    if (call) {
      call.mute(!isMuted);
      setIsMuted(!isMuted);
    }
  };

  // Function to toggle speaker
  const handleToggleSpeaker = () => {
    if (call && call.getLocalStream()) {
      const audioEl = new Audio();
      if (!isSpeakerOn) {
        audioEl.srcObject = call.getLocalStream();
        audioEl.play();
      } else {
        audioEl.srcObject = null;
      }
      setIsSpeakerOn(!isSpeakerOn);
    }
  };

   // Function to send SMS
   const sendSms = async () => {
    console.log('Attempting to send SMS with lead:', lead);

    const leadId = lead._id;
    const phoneNumber = lead.phone;
    const leadName = lead.name || 'this lead';

    if (!leadId || !phoneNumber) {
      console.error('Missing required data:', { leadId, phoneNumber });
      toast({
        title: 'Error',
        description: 'Missing required lead information.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const message = prompt(`Enter the SMS message to send to ${leadName}:`);
    if (!message) {
      toast({
        title: 'SMS Cancelled',
        description: 'No message was entered.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post('/api/sms/send', {
        to: phoneNumber,
        message: message,
        leadId: leadId
      });

      if (response.data.success) {
        // Add the new SMS to the timeline immediately
        const newSms = response.data.sms;
        setTimelineItems(prevItems => [{
          type: 'SMS',
          direction: 'outgoing', // Explicitly set direction
          content: message,
          timestamp: new Date(),
          from: process.env.REACT_APP_TWILIO_PHONE_NUMBER,
          to: phoneNumber,
          status: 'sent',
          ...newSms
        }, ...prevItems]);

        toast({
          title: 'SMS Sent',
          description: `Message sent to ${leadName}.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        
        // Refresh activities to show the new SMS
        fetchActivities();
      }
    } catch (error) {
      console.error('SMS Error:', error);
      const errorMessage = error.response?.data?.error || error.message;
      toast({
        title: 'SMS Error',
        description: `Failed to send SMS: ${errorMessage}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const handleSendSms = sendSms;


  // Initial toast to confirm component loaded
  useEffect(() => {
    toast({
      title: "Component Loaded",
      description: "LeadDetails component has been loaded.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }, [toast]);

  const setupAudioDevices = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Audio permissions granted');
      // Stop the tracks immediately to release the microphone
      stream.getTracks().forEach(track => track.stop());
      setIsAudioContextReady(true);
      return true;
    } catch (error) {
      console.error('Error accessing audio devices:', error);
      toast({
        title: 'Audio Device Error',
        description: 'Unable to access microphone. Please check your permissions.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
  }, [toast]);

  // Also update the initializeTwilioDevice function
  const initializeTwilioDevice = useCallback(async () => {
    try {
      const userIdentity = getUserIdentity();
      const response = await axios.get('/api/twilio/token', {
        params: { userId: userIdentity },
      });
      const { token } = response.data;

      console.log('Received token:', token);

      if (typeof token !== 'string') {
        throw new Error('Invalid token received from server');
      }

      // If there's an existing device, destroy it first
      if (deviceRef.current) {
        try {
          await deviceRef.current.destroy();
        } catch (error) {
          console.warn('Error destroying existing device:', error);
        }
      }

      // Create a new Device instance
      const device = new Device(token, {
        codecPreferences: ['opus', 'pcmu'],
        fakeLocalDTMF: true,
        debug: true
      });

      // Register event listeners
      device.on('registered', () => {
        console.log('Twilio device registered successfully');
        setIsDeviceReady(true);
      });

      device.on('unregistered', () => {
        console.log('Twilio device unregistered');
        setIsDeviceReady(false);
      });

      device.on('error', (error) => {
        console.error('Twilio device error:', error);
        toast({
          title: 'Twilio Device Error',
          description: `Twilio Device encountered an error: ${error.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });

      // Store the device instance in state and ref
      setDevice(device);
      deviceRef.current = device;

      // Initial registration
      try {
        await device.register();
      } catch (error) {
        if (!error.message.includes('already registered')) {
          throw error;
        }
      }

      console.log('Twilio Device initialized successfully');
      return device;
    } catch (error) {
      console.error('Error initializing Twilio Device:', error);
      toast({
        title: 'Device Initialization Error',
        description: 'Failed to initialize Twilio Device. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      throw error;
    }
  }, [toast]);

  // Add cleanup in useEffect
  useEffect(() => {
    return () => {
      // Cleanup function
      if (deviceRef.current) {
        console.log('Cleaning up Twilio device...');
        deviceRef.current.destroy().catch(error => {
          console.warn('Error destroying device during cleanup:', error);
        });
      }
    };
  }, []);

  // useEffect to request microphone and initialize Twilio Device
  useEffect(() => {
    const requestMic = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log('Microphone access granted');
        await initializeTwilioDevice();
      } catch (error) {
        console.error('Microphone access denied:', error);
        toast({
          title: 'Microphone Access Denied',
          description: 'Please allow microphone access to make calls.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    requestMic();
  }, [initializeTwilioDevice, toast]);

  

  // Fetch lead details
  const fetchLeadDetails = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.log('Fetching lead details for ID:', id);
      const response = await axios.get(`/api/leads/${id}`, {
        headers: {
          'Cache-Control': 'no-store', // Ensure the request bypasses cache
        },
      });
      console.log('Lead data received:', response.data);
      setLead(response.data);
      // Initialize the timeline with the lead's activities
      setTimeline(response.data.activities || []);
      setLeadSource(response.data.leadSource || '');
    } catch (error) {
      console.error('Error fetching lead details:', error);
      let errorMessage = 'Failed to fetch lead details';
      if (error.response) {
        if (error.response.status === 404) {
          errorMessage = `Lead with ID ${id} not found`;
          // Redirect to lead management page after showing the error
          setTimeout(() => {
            navigate('/leads');
          }, 3000);
        } else {
          errorMessage += `: ${error.response.data.message || error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = 'No response received from the server';
      } else {
        errorMessage += `: ${error.message}`;
      }
      setError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id, toast, navigate]);


  // UseEffect to fetch lead details on component mount
  useEffect(() => {
    console.log('Fetching lead with ID:', id); // Debugging
    fetchLeadDetails();
  }, [fetchLeadDetails, id]);

  // UseEffect to fetch activities when lead._id is available
  useEffect(() => {
    if (lead && lead._id) {
      fetchActivities();
    }
  }, [lead, fetchActivities]);

  // Modified handleLeadUpdate function
  const handleLeadUpdate = async (field, value) => {
    try {
      // If value is not provided, toggle the current value
      const newValue = value !== undefined ? value : !lead[field];
      
      const response = await axios.put(`/api/leads/${id}`, {
        [field]: newValue,
        // Include required fields
        name: lead.name,
        email: lead.email,
        phone: lead.phone
      });

      if (response.data) {
        setLead(prevLead => ({
          ...prevLead,
          [field]: newValue
        }));

        toast({
          title: 'Update Successful',
          description: `${field.charAt(0).toUpperCase() + field.slice(1)} has been updated`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating lead:', error);
      toast({
        title: 'Update Failed',
        description: error.response?.data?.message || 'Failed to update lead. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    // This effect handles call setup and cleanup
    if (call) {
      activeCallRef.current = call;

      const handleDisconnect = () => {
        setIsCallActive(false);
        setCall(null);
        activeCallRef.current = null;
        toast({
          title: 'Call Ended',
          description: 'The call has been disconnected.',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        fetchActivities(); // Refresh activities after call disconnect
      };

      const handleError = (error) => {
        console.error('Call error:', error);
        handleDisconnect();
      };

      const handleAccept = () => {
        setIsCallActive(true);
      };

      call.on('disconnect', handleDisconnect);
      call.on('error', handleError);
      call.on('accept', handleAccept);

      return () => {
        if (activeCallRef.current) {
          try {
            activeCallRef.current.removeListener('disconnect', handleDisconnect);
            activeCallRef.current.removeListener('error', handleError);
            activeCallRef.current.removeListener('accept', handleAccept);
          } catch (err) {
            console.error('Error removing call listeners:', err);
          }
        }
      };
    }
  }, [call, toast, fetchActivities]);

  // Add these new functions
  const handleAddNote = async (noteData) => {
    try {
      const response = await axios.post(`/api/notes/lead/${lead._id}`, noteData);
      await fetchNotes(lead._id); // Refresh notes after adding
      toast({
        title: 'Note Added',
        description: 'Note has been successfully added.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding note:', error);
      toast({
        title: 'Error',
        description: 'Failed to add note. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditNote = async (noteId, noteData) => {
    try {
      await axios.put(`/api/notes/${noteId}`, noteData);
      await fetchNotes(lead._id); // Refresh notes after editing
      toast({
        title: 'Note Updated',
        description: 'Note has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating note:', error);
      toast({
        title: 'Error',
        description: 'Failed to update note. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await axios.delete(`/api/notes/${noteId}`);
      await fetchNotes(lead._id); // Refresh notes after deleting
      toast({
        title: 'Note Deleted',
        description: 'Note has been successfully deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting note:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete note. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Filter Timeline Items
  const filteredTimelineItems = timelineItems
  .filter(item => {
    if (timelineFilter === 'All') return true;
    if (timelineFilter === 'Notes') return item.type === 'Note';
    if (timelineFilter === 'Calls') return item.type === 'Call';
    if (timelineFilter === 'SMS') return item.type === 'SMS';
    return true;
  })
  .filter(
    item =>
      item.content?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.callType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.to?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.from?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (item.direction && item.direction.toLowerCase().includes(searchQuery.toLowerCase()))
  );

    
  // Add this new function to handle lead source selection
  const handleLeadSourceSelect = async sourceType => {
    if (sourceType !== leadSource) {
      try {
        console.log('Updating lead source to:', sourceType);
        console.log('Current lead state:', lead);
  
        const response = await axios.put(`/api/leads/${id}`, { 
          leadSource: sourceType,
          // Include other required fields
          name: lead.name,
          email: lead.email,
          phone: lead.phone
        });
  
        if (response.data) {
          console.log('Lead source update response:', response.data);
          setLeadSource(sourceType);
          setLead(prevLead => ({ ...prevLead, leadSource: sourceType }));
          toast({
            title: 'Lead Source Updated',
            description: `Lead source set to ${sourceType}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error updating lead source:', error);
        console.error('Error response:', error.response);
        const errorMessage = error.response?.data?.message || error.message;
        toast({
          title: 'Update Error',
          description: `Failed to update lead source: ${errorMessage}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // Function to handle filter changes
  const handleFilterChange = filter => {
    setTimelineFilter(filter);
  };

  // Update the handleCriteriaUpdate function
  const handleCriteriaUpdate = useCallback(async (field, value) => {
    try {
      // Create an optimistic update
      setLead(prevLead => ({
        ...prevLead,
        [field]: value
      }));

      // Send only the updated field to the backend
      const response = await axios.put(`/api/leads/${id}/maw`, {
        field,
        value
      });
      
      if (response.data) {
        toast({
          title: 'Update Successful',
          description: `Successfully updated ${field}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Revert the optimistic update on error
      setLead(prevLead => ({
        ...prevLead,
        [field]: !value
      }));
      
      console.error('Error updating criteria:', error);
      toast({
        title: 'Update Failed',
        description: `Failed to update ${field}. Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [id, toast]);

  // Update the mawOptions array
  const mawOptions = [
    { 
      key: 'motivation', 
      label: 'Motivation', 
      emoji: '🔥',
      tooltip: 'How eager is the lead to make a move? High motivation indicates a strong desire to buy or sell soon.'
    },
    { 
      key: 'ability', 
      label: 'Ability', 
      emoji: '💰',
      tooltip: 'Can the lead afford to make a move? High ability suggests financial readiness and fewer obstacles.'
    },
    { 
      key: 'willingness', 
      label: 'Willingness', 
      emoji: '🤝',
      tooltip: 'How open is the lead to your guidance? High willingness indicates trust and receptiveness to your advice.'
    },
  ];

  // First, update the bucketOptions array with the new structure
  const bucketOptions = [
    {
      value: 'A',
      label: (
        <HStack spacing={2}>
          <Text as="span" color={lead?.bucket === 'A' ? 'white' : 'red.500'} fontWeight="bold">A</Text>
          <Text>🔥 Hot Lead</Text>
          <Badge colorScheme="red">&lt;60 Days</Badge>
        </HStack>
      ),
      description: 'High priority leads requiring immediate attention and follow-up',
      tooltip: 'Ready to transact within 60 days. High MAW score (2-3) indicates strong potential.',
      colorScheme: 'red',
      criteria: 'MAW Score ≥ 2, Recent Activity, Clear Intent',
      actions: [
        'Schedule immediate follow-up call',
        'Send personalized property matches daily',
        'Prioritize showing requests',
        'Provide market analysis within 24hrs'
      ]
    },
    {
      value: 'B',
      label: (
        <HStack spacing={2}>
          <Text as="span" color={lead?.bucket === 'B' ? 'white' : 'green.500'} fontWeight="bold">B</Text>
          <Text>🌱 Nurture Lead</Text>
          <Badge colorScheme="green">60-120 Days</Badge>
        </HStack>
      ),
      description: 'Active leads requiring regular engagement and nurturing',
      tooltip: 'Planning to transact in 2-4 months. Medium MAW score (1-2) shows potential.',
      colorScheme: 'green',
      criteria: 'MAW Score ≥ 1, Engaged in Communication, Defined Timeline',
      actions: [
        'Bi-weekly check-in calls',
        'Weekly property recommendations',
        'Educational content sharing',
        'Market update newsletters'
      ]
    },
    {
      value: 'C',
      label: (
        <HStack spacing={2}>
          <Text as="span" color={lead?.bucket === 'C' ? 'white' : 'orange.500'} fontWeight="bold">C</Text>
          <Text>🔭 Watch Lead</Text>
          <Badge colorScheme="orange">120+ Days</Badge>
        </HStack>
      ),
      description: 'Long-term leads requiring occasional monitoring',
      tooltip: 'Timeline 4+ months out. Low MAW score (0-1) indicates longer nurture needed.',
      colorScheme: 'orange',
      criteria: 'MAW Score < 1, Limited Engagement, Undefined Timeline',
      actions: [
        'Monthly check-in calls',
        'Monthly market snapshots',
        'Quarterly value updates',
        'Special occasion follow-ups'
      ]
    }
  ];


  // Function to add a call log to the lead's timeline
  const addCallLogToLead = async (leadId, callSid, callType) => {
    try {
      const response = await axios.post(`/api/leads/${leadId}/call-logs`, {
        callSid,
        callType,
        startTime: new Date(),
        status: 'initiated',
      });
      console.log('Call log added:', response.data);
    } catch (error) {
      console.error('Error adding call log:', error);
    }
  };

  // Function to add an SMS log to the lead's timeline
  const addSmsLogToLead = async (leadId, smsSid, direction, message) => {
    try {
      const response = await axios.post(`/api/leads/${leadId}/sms-logs`, {
        smsSid,
        direction,
        message,
        timestamp: new Date(),
      });
      console.log('SMS log added:', response.data);
    } catch (error) {
      console.error('Error adding SMS log:', error);
    }
  };
  

  
  // **Function to update the call log when the call ends**
  const updateCallLog = async (leadId, callSid) => {
    try {
      // Fix the API endpoint path
      const response = await axios.put(`/api/twilio/calls/${leadId}/call-logs/${callSid}`);
      console.log('Call log updated:', response.data);
    } catch (error) {
      console.error('Error updating call log:', error?.response?.data || error.message);
      toast({
        title: 'Update Error',
        description: 'Failed to update call log.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // **Utility function to validate Call SID (implement as needed)**
  const isValidCallSid = sid => {
    // Example validation
    return typeof sid === 'string' && sid.length > 0;
  };


  // useEffect to handle call duration
  useEffect(() => {
    let interval;
    if (callStatus === 'in-progress') {
      interval = setInterval(() => {
        setCallDuration(prevDuration => prevDuration + 1);
      }, 1000);
    } else {
      setCallDuration(0);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [callStatus]);

  // Function to format call duration
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // Updated useEffect for socket connection and call status updates
  useEffect(() => {
    console.log('Setting up socket connection...');
    
    if (!socket || !lead) return;

    const handleCallStatusUpdate = (data) => {
      console.log('Received call status update:', data);
      if (data.leadId === lead._id) {
        setCallStatus(data.status);
        updateCallStatus(data.callSid, data.status);
        
        switch (data.status) {
          case 'initiated':
            toast({
              title: "Call Initiated",
              description: "The call is being initiated.",
              status: "info",
              duration: 3000,
              isClosable: true,
            });
            break;
          case 'ringing':
            toast({
              title: "Call Ringing",
              description: "The phone is ringing.",
              status: "info",
              duration: 3000,
              isClosable: true,
            });
            break;
          case 'in-progress':
            toast({
              title: "Call Connected",
              description: "The call is now in progress.",
              status: "success",
              duration: null, // This will make the toast stay until manually closed
              isClosable: true,
              position: "top", // This will make the toast appear at the top of the screen
            });
            break;
          case 'completed':
            toast({
              title: "Call Ended",
              description: "The call has been completed.",
              status: "info",
              duration: 3000,
              isClosable: true,
            });
            setIsCallActive(false);
            setActiveCall(null);
            break;
          case 'failed':
            toast({
              title: "Call Failed",
              description: "The call could not be completed.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setIsCallActive(false);
            setActiveCall(null);
            break;
          default:
            toast({
              title: "Call Status Update",
              description: `Call status: ${data.status}`,
              status: "info",
              duration: 3000,
              isClosable: true,
            });
        }
      }
    };

    const handleCallUpdate = (data) => {
      if (data.leadId === lead._id) {
        setCallStatus(data.status);
        updateCallStatus(data.callSid, data.status);
        
        setTimeline(prevTimeline => prevTimeline.map(item => {
          if (item.type === 'Call' && item.twilioCallSid === data.callSid) {
            return {
              ...item,
              status: data.status,
              transcript: data.transcript,
              analysis: data.analysis
            };
          }
          return item;
        }));

        switch (data.status) {
          case 'initiated':
            toast({
              title: "Call Initiated",
              description: "The call is being initiated.",
              status: "info",
              duration: 3000,
              isClosable: true,
            });
            break;
          case 'ringing':
            toast({
              title: "Call Ringing",
              description: "The phone is ringing.",
              status: "info",
              duration: 3000,
              isClosable: true,
            });
            break;
          case 'in-progress':
            toast({
              title: "Call Connected",
              description: "The call is now in progress.",
              status: "success",
              duration: null, // This will make the toast stay until manually closed
              isClosable: true,
              position: "top", // This will make the toast appear at the top of the screen
            });
            break;
          case 'completed':
            toast({
              title: "Call Ended",
              description: "The call has been completed.",
              status: "info",
              duration: 3000,
              isClosable: true,
            });
            setIsCallActive(false);
            setActiveCall(null);
            break;
          case 'failed':
            toast({
              title: "Call Failed",
              description: "The call could not be completed.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setIsCallActive(false);
            setActiveCall(null);
            break;
          default:
            toast({
              title: "Call Status Update",
              description: `Call status: ${data.status}`,
              status: "info",
              duration: 3000,
              isClosable: true,
            });
        }
      }
    };

    socket.on('callStatusUpdate', handleCallStatusUpdate);
    socket.on('callUpdate', handleCallUpdate);

    return () => {
      console.log('Cleaning up socket connection...');
      socket.off('callStatusUpdate', handleCallStatusUpdate);
      socket.off('callUpdate', handleCallUpdate);
    };
  }, [lead, toast, updateCallStatus, socket, setCallStatus, setIsCallActive, setActiveCall, setTimeline]);



  // Move this useEffect before any return statements
  useEffect(() => {
    const handleStatusUpdateResponse = (event) => {
      if (event.data.type === 'STATUS_UPDATE_RESPONSE') {
        const response = event.data.payload;
        if (response.status === 'queued') {
          toast({
            title: 'Status Update Queued',
            description: 'Status update has been queued in the extension',
            status: 'info',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    };

    window.addEventListener('message', handleStatusUpdateResponse);
    return () => window.removeEventListener('message', handleStatusUpdateResponse);
  }, [toast]);

  // Function to fetch notes for a lead
  const fetchNotes = useCallback(async (leadId) => {
    try {
      const response = await axios.get(`/api/notes/lead/${leadId}`);
      setNotes(response.data);
    } catch (error) {
      console.error('Error fetching notes:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch notes',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]); // Add toast as a dependency

  // Add this to your existing useEffect
  useEffect(() => {
    if (lead?._id) {
      fetchNotes(lead._id);
    }
  }, [lead?._id, fetchNotes]); // Add fetchNotes as a dependency

  // Update the call info whenever relevant states change
  useEffect(() => {
    if (isCallActive && lead) {
      setCurrentCallInfo({
        leadName: lead.name,
        to: lead.phone,
        status: callStatus,
        duration: 0, // You can add call duration tracking if needed
      });
    } else {
      setCurrentCallInfo(null);
    }
  }, [isCallActive, lead, callStatus]);

  // Then your conditional returns
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" color="orange.400" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, orange.400, orange.600)"
          backgroundClip="text"
        >
          Error
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          {error}
        </Text>
        <Text color={'gray.500'} mb={6}>
          Redirecting to Lead Management page...
        </Text>
      </Box>
    );
  }

  // Add this check
  if (!lead) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Text>No lead data available.</Text>
      </Box>
    );
  }

  // Add this function to handle editable field updates
  const handleEditableSubmit = async (field, value) => {
    try {
      // Validation based on field type
      if (field === 'name') {
        if (value.length < 2 || value.length > 100) {
          toast({
            title: 'Invalid Name',
            description: 'Name must be between 2 and 100 characters',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      } else if (field === 'email') {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if (value && !value.includes('@placeholder.com') && !emailRegex.test(value)) {
          toast({
            title: 'Invalid Email',
            description: 'Please enter a valid email address',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      } else if (field === 'phone') {
        const phoneRegex = /^\+?1?\d{10}$/;
        if (value && !phoneRegex.test(value.replace(/\D/g, ''))) {
          toast({
            title: 'Invalid Phone',
            description: 'Please enter a valid 10-digit phone number',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      }

      const response = await axios.put(`/api/leads/${id}`, {
        [field]: value,
        // Include other required fields
        name: field === 'name' ? value : lead.name,
        email: field === 'email' ? value : lead.email,
        phone: field === 'phone' ? value : lead.phone,
      });
      
      if (response.data) {
        setLead(prevLead => ({ ...prevLead, [field]: value }));
        toast({
          title: 'Update Successful',
          description: `${field.charAt(0).toUpperCase() + field.slice(1)} has been updated.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      toast({
        title: 'Update Failed',
        description: error.response?.data?.message || `Failed to update ${field}. Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const statusOptions = [
    { value: 'TBD', color: 'gray.500' },
    { value: 'Do Not Contact', color: 'black' },
    { value: 'Not Responsive', color: 'purple.500' },
    { value: 'Bogus', color: 'orange.400' },
    { value: 'Just Browsing', color: 'blue.500' },
    { value: 'Criteria Obtained', color: 'green.500' },
    { value: 'Future Follow Up', color: 'cyan.500' },
    { value: 'Warm', color: 'yellow.500' },
    { value: 'HOT', color: 'red.500' },
    { value: 'Taken on Showings', color: 'pink.500' },
    { value: 'Submitted Offer', color: 'teal.500' },
    { value: 'Client', color: 'green.500' },
    { value: 'Raving Fan', color: 'gold' },
  ];

  const handleStatusChange = async (newStatus) => {
    try {
      const response = await axios.put(`/api/leads/${id}`, {
        ...lead,
        criteria: {
          ...lead.criteria,
          status: newStatus
        }
      });

      if (response.data) {
        setLead(prevLead => ({
          ...prevLead,
          criteria: {
            ...prevLead.criteria,
            status: newStatus
          }
        }));

        // Check if lead has condosCaId before attempting extension sync
        if (lead.condosCaId) {
          const formattedName = lead.name.toLowerCase().replace(/\s+/g, '_');
          const urls = [
            `https://crm.condos.ca/leads/${formattedName}_${lead.condosCaId}`,
            `https://crm.property.ca/leads/${formattedName}_${lead.condosCaId}`
          ];

          // Safely check for Chrome extension using window.postMessage
          window.postMessage({
            type: 'UPDATE_CONDOS_CA_STATUS',
            payload: {
              name: lead.name,
              status: newStatus,
              urls: urls
            }
          }, '*');

          toast({
            title: 'Status Update Initiated',
            description: 'Updating status in Condos.ca CRM...',
            status: 'info',
            duration: 3000,
            isClosable: true,
          });
        }

        toast({
          title: 'Status Updated',
          description: `Lead status updated to ${newStatus}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating lead status:', error);
      toast({
        title: 'Update Failed',
        description: 'Failed to update lead status. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Add this code if it's not already present
  const sendSMS = async (message) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/twilio/send-sms`, {
        to: lead.phone,
        body: message,
        leadId: lead._id,
      });
      if (response.data.success) {
        // Handle successful SMS send
        toast({
          title: 'SMS Sent',
          description: `Message sent to ${lead.name}.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        // Refresh messages or add the new message to the existing list
      }
    } catch (error) {
      console.error('Error sending SMS:', error);
      toast({
        title: 'SMS Error',
        description: `Failed to send SMS: ${error.response?.data?.message || error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Function to add a new lead
  const handleAddLead = async (newLeadData) => {
    try {
      const formattedLeadData = {
        ...newLeadData,
        status: newLeadData.status || 'TBD',
        notes: [],
        calls: [],
        smsLogs: [],
        activities: []
      };

      const response = await axios.post('/api/leads', formattedLeadData);
      
      if (response.status === 201) {
        setLead(response.data);
        toast({
          title: "Lead Created",
          description: "New lead has been successfully created with status 'TBD'.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error adding lead:', error);
      toast({
        title: "Error",
        description: "There was an error creating the lead.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Function to update lead status
  const handleUpdateLeadStatus = async (updatedStatus) => {
    try {
      const response = await axios.patch(`/api/leads/${id}`, {
        status: updatedStatus,
      });

      if (response.status === 200) {
        setLead(response.data);
        toast({
          title: "Status Updated",
          description: `Lead status has been updated to '${updatedStatus}'.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating lead status:', error);
      toast({
        title: "Error",
        description: "There was an error updating the lead status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteLead = async () => {
    try {
      await axios.delete(`/api/leads/${id}`);
      
      toast({
        title: "Lead Deleted",
        description: "The lead has been successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Navigate back to leads page
      navigate('/leads');

    } catch (error) {
      console.error('Error deleting lead:', error);
      toast({
        title: "Delete Failed",
        description: error.response?.data?.message || "Failed to delete lead. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        
      });
    }
  };

  const handleToggleArchive = async () => {
    try {
      const action = lead?.isArchived ? 'unarchive' : 'archive';
      const response = await axios.patch(`/api/leads/${id}/${action}`);
      
      if (response.data) {
        setLead(prevLead => ({
          ...prevLead,
          isArchived: !prevLead.isArchived,
          archivedAt: !prevLead.isArchived ? new Date().toISOString() : null
        }));

        toast({
          title: lead?.isArchived ? "Lead Restored" : "Lead Archived",
          description: lead?.isArchived 
            ? "The lead has been successfully restored."
            : "The lead has been archived and can be restored later.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Add to activity timeline
        const activityType = lead?.isArchived ? 'LEAD_RESTORED' : 'LEAD_ARCHIVED';
        const activityDescription = lead?.isArchived 
          ? 'Lead was restored from archive'
          : 'Lead was archived';
        
        await axios.post(`/api/leads/${id}/activities`, {
          type: activityType,
          description: activityDescription,
          timestamp: new Date().toISOString()
        });

        fetchActivities(); // Refresh activities
      }
    } catch (error) {
      console.error('Error toggling archive status:', error);
      toast({
        title: "Action Failed",
        description: error.response?.data?.message || "Failed to update lead status. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Add this function to handle permanent deletion
  const handlePermanentDelete = async () => {
    try {
      const response = await axios.delete(`/api/leads/${id}`);
      if (response.status === 200) {
        toast({
          title: 'Lead Deleted',
          description: 'Lead has been permanently deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/leads'); // Redirect to leads list
      }
    } catch (error) {
      console.error('Error deleting lead:', error);
      toast({
        title: 'Delete Failed',
        description: error.response?.data?.message || 'Failed to delete lead. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bg="black" 
      minHeight="100vh"
      w="100%"
      p={6}
    >
      {/* Call Actions Component */}
      <Box bg="black" borderColor="orange.400" borderWidth="1px" borderRadius="0" mb={6}>
        <CallActions
          callType={callType}
          setCallType={setCallType}
          handleStartCall={handleStartCall}
          handleSendSms={handleSendSms}
          isCallActive={isCallActive}
          isMuted={isMuted}
          handleToggleMute={handleToggleMute}
          isSpeakerOn={isSpeakerOn}
          handleToggleSpeaker={handleToggleSpeaker}
          handleEndCall={handleEndCall}
          currentCallInfo={currentCallInfo} // Add this prop
        />
      </Box>
  
  {/* Lead Header Section (Name, Phone, Email) */}
 
  <VStack spacing={6} align="stretch">
    
          {/* Lead Profile Section with orange border */}
          <Box bg="black" borderColor="orange.400" borderWidth="1px" borderRadius="0" p={6}>

{/* Contact Information and Primary Actions */}
<Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="flex-start">
      <VStack align="flex-start" spacing={3}>
      <HStack spacing={2} align="center">
  <Editable
    defaultValue={lead.name}
    onSubmit={(newName) => handleEditableSubmit('name', newName)}
    w="full"
  >
    <EditablePreview
      as={Heading}
      size="lg"
      bgGradient="linear(to-r, orange.400, orange.600)"
      bgClip="text"
      css={{
        '&:hover': {
          textShadow: '0 0 20px rgba(237, 137, 54, 0.6)',
          transition: 'text-shadow 0.3s ease-in-out'
        }
      }}
    />
    <EditableInput
      size="lg"
      fontWeight="bold"
      _focus={{
        bgGradient: "linear(to-r, orange.400, orange.600)",
        bgClip: "text",
        borderColor: "orange.400",
        boxShadow: "0 0 0 1px #ED8936"
      }}
    />
  </Editable>
  {lead.condosCaId && (
    <Tooltip label="View on Property.ca">
      <chakra.a
        href={formatPropertyUrl(lead.name, lead.condosCaId)}
        target="_blank"
        rel="noopener noreferrer"
        fontSize="sm"
        color="gray.500"
        minWidth="120px" // Added minimum width
        display="inline-block" // Ensures the width is respected
        _hover={{ color: "orange.400", textDecoration: "underline" }}
      >
        (ID: {lead.condosCaId.toString()}) {/* Just display the ID as is */}
      </chakra.a>
    </Tooltip>
  )}
</HStack>
        <HStack spacing={4}>
          <Flex align="center">
            <Icon as={MdPhone} color="orange.400" mr={2} boxSize={6} />
            <Editable
              defaultValue={lead?.phone || 'N/A'}
              onSubmit={(value) => handleEditableSubmit('phone', value)}
            >
              <EditablePreview 
                color="white" 
                fontSize="xl" 
                borderColor="orange.400"
                borderWidth="0px"
                borderRadius="md"
                px={2}
                py={1}
              />
              <EditableInput 
                color="white" 
                fontSize="xl"
                borderColor="orange.400"
                borderWidth="1px"
                borderRadius="md"
                px={2}
                py={1}
              />
            </Editable>
          </Flex>
          <Flex align="center">
            <Icon as={MdEmail} color="orange.400" mr={2} boxSize={6} />
            <Editable
              defaultValue={lead?.email || 'N/A'}
              onSubmit={(value) => handleEditableSubmit('email', value)}
            >
              <EditablePreview 
                color="white" 
                fontSize="xl"
                borderColor="orange.400"
                borderWidth="0px"
                borderRadius="md"
                px={2}
                py={1}
              />
              <EditableInput 
                color="white" 
                fontSize="xl"
                borderColor="orange.400"
                borderWidth="1px"
                borderRadius="md"
                px={2}
                py={1}
              />
            </Editable>
          </Flex>
        </HStack>
      </VStack>
      {/* Primary Actions */}
      <HStack spacing={4} mt={{ base: 4, md: 0 }}>
        <VStack spacing={3}> {/* Changed from HStack to VStack for vertical stacking */}
          <Button 
            onClick={handleStartScript} 
            colorScheme="orange" 
            size="md" 
            leftIcon={<Icon as={FaPhone} />}
            width="100%" // Makes button take full width
          >
            Take Lead to Call Script
          </Button>
          <Button
            onClick={() => {
              toast({
                title: "Coming Soon",
                description: "Automation assignment feature will be available soon!",
                status: "info",
                duration: 3000,
                isClosable: true,
              });
            }}
            colorScheme="purple" // Different color to distinguish from call script button
            size="md"
            leftIcon={<Icon as={FaBolt} />} // Using bolt icon for automation
            width="100%" // Makes button take full width
            variant="outline" // Optional: gives it a different look
            _hover={{
              bg: "purple.800",
              transform: "translateY(-2px)",
              transition: "all 0.2s",
            }}
            _active={{
              transform: "translateY(0)",
            }}
          >
            Assign Automation
          </Button>
        </VStack>
      </HStack>
    </Flex>

            {/* Added my={6} for vertical margin/padding around the divider */}
            <Divider borderColor="gray.600" my={6} />
            
{/* MAW Scoring and Bucket section */}
<Grid templateColumns={{ base: "1fr", md: "850px 1fr" }} gap={6}> {/* Increased from 350px to 450px */}
  {/* MAW Scoring */}
  <Box height="100%" position="relative">
    <HStack spacing={3} width="100%" align="center"> {/* Changed to HStack and added align="center" */}
      {mawOptions.map(item => (
        <Tooltip key={item.key} label={item.tooltip} placement="top" hasArrow>
          <Button
            leftIcon={<span>{item.emoji}</span>}
            onClick={() => handleLeadUpdate(item.key)}
            colorScheme={lead?.[item.key] ? 'orange' : 'gray'}
            variant={lead?.[item.key] ? 'solid' : 'outline'}
            size="md"
            color="white"
            flex="1"
            px={4}
            minW="180px"
            maxW="500px"
            h="40px"
            fontSize="md"
            _hover={{
              color: lead?.[item.key] ? 'white' : 'orange.400',
              transform: 'translateY(-2px)',
              transition: 'transform 0.2s'
            }}
            _active={{
              transform: 'translateY(0)',
            }}
            transition="all 0.2s"
          >
            {item.label}
          </Button>
        </Tooltip>
      ))}
      
      {/* Simplified MAW Score */}
      <Badge
        ml={2}
        fontSize="lg"
        py={2}
        px={3}
        borderRadius="md"
        colorScheme={
          (lead?.motivation && lead?.ability && lead?.willingness) ? 'green' :
          (lead?.motivation || lead?.ability || lead?.willingness) ? 'orange' :
          'gray'
        }
      >
        Score: {[lead?.motivation, lead?.ability, lead?.willingness].filter(Boolean).length}/3
      </Badge>
    </HStack>
  </Box>
  
  {/* Bucket section - remains unchanged */}
  <Box height="100%" position="relative">
    <HStack spacing={3} justify="flex-end">
      {bucketOptions.map(bucket => (
        <Popover key={bucket.value} trigger="hover" placement="top">
          <PopoverTrigger>
            <Button
              role="group" // Added role="group" to enable group-hover
              onClick={() => handleLeadUpdate('bucket', bucket.value)}
              variant={lead?.bucket === bucket.value ? 'solid' : 'outline'}
              colorScheme={bucket.colorScheme}
              size="md"
              position="relative"
              p={4}
              bg={lead?.bucket === bucket.value ? `${bucket.colorScheme}.500` : 'transparent'}
              color={lead?.bucket === bucket.value ? 'white' : `${bucket.colorScheme}.500`}
              borderColor={`${bucket.colorScheme}.500`}
              _hover={{
                transform: 'translateY(-2px)',
                shadow: 'lg',
                bg: `${bucket.colorScheme}.600`,
                color: 'white', // Ensures overall text color is white on hover
              }}
              _active={{
                transform: 'translateY(0)',
                bg: `${bucket.colorScheme}.700`,
              }}
              transition="all 0.2s"
            >
              <VStack spacing={1} align="start">
                <HStack spacing={2}>
                  <Text
                    as="span"
                    color={lead?.bucket === bucket.value ? 'white' : `${bucket.colorScheme}.500`}
                    fontWeight="bold"
                    _groupHover={{ color: 'white' }} // Changes letter color to white on hover
                  >
                    {bucket.value}
                  </Text>
                  <Text
                    _groupHover={{ color: 'white' }} // Changes bucket option text to white on hover
                  >
                    {bucket.label.props.children[1]} {/* Extracting the label text */}
                  </Text>
                  <Badge colorScheme={bucket.colorScheme}>{bucket.label.props.children[2].props.children}</Badge>
                </HStack>
                {lead?.bucket === bucket.value && (
                  <Badge
                    position="absolute"
                    top="-2"
                    right="-2"
                    colorScheme={bucket.colorScheme}
                    variant="solid"
                    borderRadius="full"
                  >
                    ✓
                  </Badge>
                )}
              </VStack>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            bg="gray.800"
            borderColor={`${bucket.colorScheme}.500`}
            boxShadow="xl"
            p={4}
            maxW="sm"
          >
            <PopoverArrow bg="gray.800" />
            <PopoverBody>
              <VStack align="start" spacing={3}>
                <Text fontWeight="bold" color={`${bucket.colorScheme}.400`}>
                  {bucket.description}
                </Text>
                <Divider />
                <Text fontWeight="semibold" color="white">Criteria:</Text>
                <Text color="gray.300">{bucket.criteria}</Text>
                <Text fontWeight="semibold" color="white">Recommended Actions:</Text>
                <UnorderedList color="gray.300" pl={4}>
                  {bucket.actions.map((action, index) => (
                    <ListItem key={index}>{action}</ListItem>
                  ))}
                </UnorderedList>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ))}
    </HStack>
  </Box>
</Grid>

            <Divider borderColor="gray.600" my={6} />

            {/* After the Bucket section and before the Divider */}
            <Box mt={6}>
              <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={6}>
                {/* Buyer Criteria */}
                <Box
                  bg="black"
                  borderColor="blue.400"
                  borderWidth="1px"
                  borderRadius="md"
                  p={4}
                >
                  <VStack align="stretch" spacing={3}>
                    <HStack justify="space-between">
                      <Heading size="sm" color="blue.400">
                        <Icon as={FaHome} mr={2} />
                        Buyer Criteria
                      </Heading>
                      <Badge colorScheme="blue">
                        {lead?.buyerCriteria?.length || 0} Items
                      </Badge>
                    </HStack>
                    <VStack align="stretch" spacing={2}>
                      {lead?.buyerCriteria?.map((item, index) => (
                        <HStack key={index} justify="space-between">
                          <Text color="white">{item.description}</Text>
                          <Badge colorScheme="blue">{item.priority}</Badge>
                        </HStack>
                      )) || (
                        <Text color="gray.500" fontSize="sm">
                          No buyer criteria set
                        </Text>
                      )}
                    </VStack>
                    <Button
                      leftIcon={<MdAdd />}
                      colorScheme="blue"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        // TODO: Implement add buyer criteria functionality
                        toast({
                          title: "Coming Soon",
                          description: "Buyer criteria management will be available soon!",
                          status: "info",
                          duration: 3000,
                          isClosable: true,
                        });
                      }}
                    >
                      Add Criteria
                    </Button>
                  </VStack>
                </Box>

                {/* Seller Criteria */}
                <Box
                  bg="black"
                  borderColor="green.400"
                  borderWidth="1px"
                  borderRadius="md"
                  p={4}
                >
                  <VStack align="stretch" spacing={3}>
                    <HStack justify="space-between">
                      <Heading size="sm" color="green.400">
                        <Icon as={FaHome} mr={2} />
                        Seller Criteria
                      </Heading>
                      <Badge colorScheme="green">
                        {lead?.sellerCriteria?.length || 0} Items
                      </Badge>
                    </HStack>
                    <VStack align="stretch" spacing={2}>
                      {lead?.sellerCriteria?.map((item, index) => (
                        <HStack key={index} justify="space-between">
                          <Text color="white">{item.description}</Text>
                          <Badge colorScheme="green">{item.priority}</Badge>
                        </HStack>
                      )) || (
                        <Text color="gray.500" fontSize="sm">
                          No seller criteria set
                        </Text>
                      )}
                    </VStack>
                    <Button
                      leftIcon={<MdAdd />}
                      colorScheme="green"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        // TODO: Implement add seller criteria functionality
                        toast({
                          title: "Coming Soon",
                          description: "Seller criteria management will be available soon!",
                          status: "info",
                          duration: 3000,
                          isClosable: true,
                        });
                      }}
                    >
                      Add Criteria
                    </Button>
                  </VStack>
                </Box>

                {/* Renter Criteria */}
                <Box
                  bg="black"
                  borderColor="purple.400"
                  borderWidth="1px"
                  borderRadius="md"
                  p={4}
                >
                  <VStack align="stretch" spacing={3}>
                    <HStack justify="space-between">
                      <Heading size="sm" color="purple.400">
                        <Icon as={FaHome} mr={2} />
                        Renter Criteria
                      </Heading>
                      <Badge colorScheme="purple">
                        {lead?.renterCriteria?.length || 0} Items
                      </Badge>
                    </HStack>
                    <VStack align="stretch" spacing={2}>
                      {lead?.renterCriteria?.map((item, index) => (
                        <HStack key={index} justify="space-between">
                          <Text color="white">{item.description}</Text>
                          <Badge colorScheme="purple">{item.priority}</Badge>
                        </HStack>
                      )) || (
                        <Text color="gray.500" fontSize="sm">
                          No renter criteria set
                        </Text>
                      )}
                    </VStack>
                    <Button
                      leftIcon={<MdAdd />}
                      colorScheme="purple"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        // TODO: Implement add renter criteria functionality
                        toast({
                          title: "Coming Soon",
                          description: "Renter criteria management will be available soon!",
                          status: "info",
                          duration: 3000,
                          isClosable: true,
                        });
                      }}
                    >
                      Add Criteria
                    </Button>
                  </VStack>
                </Box>
              </Grid>
            </Box>

            <Divider borderColor="gray.600" my={6} />

            <LeadProfileSection
              lead={lead}
              onJobUpdate={handleJobUpdate}
              onStatusChange={handleStatusChange}
              onLeadSourceSelect={handleLeadSourceSelect}
              onCriteriaUpdate={handleCriteriaUpdate}
            />

          </Box>



          

          {/* Lead Details and Timeline */}
          <Grid templateColumns={{ base: '1fr', lg: '400px 1fr 400px' }} gap={6}>
            <GridItem>
              <VStack spacing={6} align="stretch">
                {/* Tags Box */}
                <Box bg="black" borderColor="orange.400" borderWidth="1px">
                  <Heading size="md" color="orange.400" p={4} borderBottomWidth="1px" borderBottomColor="orange.400">
                    Tags
                  </Heading>
                  <Box p={4} bg="black"> 
                    <TagsBox />
                  </Box>
                </Box>

                
                {/* Alerts Box */}
                <Box bg="black" borderColor="orange.400" borderWidth="1px"> 
                  <Heading size="md" color="orange.400" p={4} borderBottomWidth="1px" borderBottomColor="orange.400">
                    Alerts
                  </Heading>
                  <Box p={4}>
                    <AlertsBox alerts={alerts} leadId={id} />
                  </Box>
                </Box>

                {/* Showings Box */}
                <Box bg="black" borderColor="orange.400" borderWidth="1px"> 
                  <Heading size="md" color="orange.400" p={4} borderBottomWidth="1px" borderBottomColor="orange.400">
                    Showings
                  </Heading>
                  <Box p={4}>
                    <ShowingsBox showings={showings} leadId={id} />
                  </Box>
                </Box>

{/* Danger Zone */}
<Box bg="black" borderColor="red.400" borderWidth="1px"> 
                  <Heading size="md" color="red.400" p={4} borderBottomWidth="1px" borderBottomColor="red.400">
                    Danger Zone
                  </Heading>
                  <Box p={4}>
                    <VStack spacing={4} width="100%">
                      <Button
                        colorScheme={lead?.isArchived ? "green" : "orange"}
                        width="100%"
                        leftIcon={<Icon as={lead?.isArchived ? MdUnarchive : MdArchive} />}
                        onClick={handleToggleArchive}
                      >
                        {lead?.isArchived ? "Restore Lead" : "Archive Lead"}
                      </Button>
                      <Button
                        colorScheme="red"
                        width="100%"
                        leftIcon={<Icon as={MdDelete} />}
                        onClick={onDeleteAlertOpen}
                      >
                        Delete Lead Permanently
                      </Button>
                    </VStack>
                  </Box>
                </Box>
                

              </VStack>
            </GridItem>

            {/* Timeline Section */}
            <GridItem>
              <Box bg="black" p={4} borderColor="orange.400" borderWidth="1px"> 
                <Heading size="md" color="orange.400" mb={3}>
                  Activity Timeline
                </Heading>
                <Input
                  placeholder="Search timeline..."
                  mb={3}
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  bg="black" 
                  color="white"
                  borderColor="orange.400"
                  borderWidth="1px"
                  _hover={{ borderColor: 'orange.400' }}
                />
                <ButtonGroup size="sm" isAttached variant="outline" mb={3} width="100%">
                  {['All', 'Notes', 'Calls', 'SMS'].map(filter => (
                    <Button
                      key={filter}
                      onClick={() => handleFilterChange(filter)}
                      colorScheme={timelineFilter === filter ? 'orange' : 'gray'}
                      flex={1}
                      // Added custom hover styles to match color schemes and avoid using green
                      _hover={{
                        bg: timelineFilter === filter ? 'orange.600' : 'gray.500',
                      }}
                    >
                      {filter}
                    </Button>
                  ))}
                </ButtonGroup>
                <LeadTimeline
                  filter={timelineFilter}
                  searchQuery={searchQuery}
                  timelineItems={filteredTimelineItems}
                />
              </Box>
            </GridItem>

            {/* Right Column Boxes */}
            <GridItem>
              <VStack spacing={6} align="stretch">

                {/* Tasks Box */}
                <Box bg="black" borderColor="orange.400" borderWidth="1px"> 
                  <Heading size="md" color="orange.400" p={4} borderBottomWidth="1px" borderBottomColor="orange.400">
                    Tasks
                  </Heading>
                  <Box p={4}>
                    <TasksBox tasks={tasks} leadId={id} />
                  </Box>
                </Box>

                {/* Notes Box */}
                <Box bg="black" borderColor="orange.400" borderWidth="1px"> 
                  <Heading size="md" color="orange.400" p={4} borderBottomWidth="1px" borderBottomColor="orange.400">
                    Notes
                  </Heading>
                  <Box p={4}>
                  <NotesBox
      notes={notes}  // Use the notes state instead of lead.notes
      leadId={id}
      onAddNote={handleAddNote}
      onEditNote={handleEditNote}
      onDeleteNote={handleDeleteNote}
    />
              </Box>
                </Box>

              </VStack>
            </GridItem>
          </Grid>

          {/* Modals */}
          {/* Update Modal background colors */}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg="black" borderWidth="1px" borderColor="orange.400">
              <ModalHeader>Add Call Notes</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Textarea
                  value={noteContent}
                  onChange={(e) => setNoteContent(e.target.value)}
                  placeholder="Enter notes for this call..."
                />
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={async () => {
                  if (noteContent.trim()) {
                    await handleAddNote({
                      content: noteContent,
                      leadId: lead._id,
                      createdBy: 'System User',
                      createdAt: new Date().toISOString(),
                      type: 'note',
                      status: 'active'
                    });
                    setNoteContent('');
                    onClose();
                  }
                }}>
                  Save Notes
                </Button>
                <Button variant="ghost" onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Alert Dialog */}
          <AlertDialog isOpen={isDeleteAlertOpen} leastDestructiveRef={cancelRef} onClose={onDeleteAlertClose}>
            <AlertDialogOverlay>
              <AlertDialogContent bg="black" borderColor="red.500" borderWidth="1px"> 
                <AlertDialogHeader fontSize="lg" fontWeight="bold" color="white">
                  Delete Lead Permanently
                </AlertDialogHeader>

                <AlertDialogBody color="gray.300">
                  Are you sure you want to permanently delete {lead?.name}'s profile? 
                  This action cannot be undone.
                  <Box mt={4} p={3} bg="gray.700" borderRadius="md">
                    <Text fontWeight="bold" color="orange.400">Recommendation:</Text>
                    <Text>Consider archiving the lead instead of deleting if you might need this information later.</Text>
                  </Box>
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onDeleteAlertClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handlePermanentDelete} ml={3}>
                    Delete Permanently
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          {/* Lead Model Details Section */}
          <Box mt={6}>
            <LeadModelDetails lead={lead} />
          </Box>

        </VStack>
      </Box>
  );
};

// Make sure to add the LeadModelDetails component definition at the top of the file with other component definitions
const LeadModelDetails = ({ lead }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleString() : 'N/A';
  };

  if (!lead) return null;

  return (
    <Box 
      bg="black" 
      borderColor="orange.400" 
      borderWidth="1px" 
      borderRadius="md"
      p={4}
      mt={6}
    >
      <Flex justify="space-between" align="center" mb={4}>
        <Heading size="md" color="orange.400">
          Lead Model Details
        </Heading>
        <Button
          size="sm"
          colorScheme="orange"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </Button>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {/* Basic Information */}
        <Box>
          <Text fontWeight="bold" color="orange.400" mb={2}>Basic Information</Text>
          <VStack align="start" spacing={2}>
            <Text><strong>ID:</strong> {lead._id}</Text>
            <Text><strong>Created:</strong> {formatDate(lead.createdAt)}</Text>
            <Text><strong>Updated:</strong> {formatDate(lead.updatedAt)}</Text>
            <Text><strong>Last Activity:</strong> {formatDate(lead.lastActivity)}</Text>
          </VStack>
        </Box>

        {/* Contact Information */}
        <Box>
          <Text fontWeight="bold" color="orange.400" mb={2}>Contact Details</Text>
          <VStack align="start" spacing={2}>
            <Text><strong>Name Note:</strong> {lead.nameNote || 'N/A'}</Text>
            <Text><strong>Call Option:</strong> {lead.callOption}</Text>
            <Text><strong>Lead Source:</strong> {lead.leadSource || 'N/A'}</Text>
            <Text><strong>Lead Type:</strong> {lead.leadType || 'N/A'}</Text>
          </VStack>
        </Box>

        {/* Status Information */}
        <Box>
          <Text fontWeight="bold" color="orange.400" mb={2}>Status Information</Text>
          <VStack align="start" spacing={2}>
            <Text><strong>Status:</strong> {lead.status}</Text>
            <Text><strong>Lead Score:</strong> {lead.leadScore}</Text>
            <Text><strong>Current Stage:</strong> {lead.currentStage}</Text>
            <Text><strong>Client Type:</strong> {lead.clientType || 'N/A'}</Text>
          </VStack>
        </Box>

        {isExpanded && (
          <>
            {/* Messaging Preferences */}
            <Box>
              <Text fontWeight="bold" color="orange.400" mb={2}>Messaging Preferences</Text>
              <VStack align="start" spacing={2}>
                <Text><strong>Opted In:</strong> {lead.messagingPreferences?.optedIn ? 'Yes' : 'No'}</Text>
                <Text><strong>Preferred Time:</strong> {`${lead.messagingPreferences?.preferredTime?.start || 'N/A'} - ${lead.messagingPreferences?.preferredTime?.end || 'N/A'}`}</Text>
                <Text><strong>Do Not Disturb:</strong> {lead.messagingPreferences?.doNotDisturb ? 'Yes' : 'No'}</Text>
                <Text><strong>Preferred Method:</strong> {lead.messagingPreferences?.preferredMethod || 'N/A'}</Text>
              </VStack>
            </Box>

            {/* Criteria Information */}
            <Box>
              <Text fontWeight="bold" color="orange.400" mb={2}>Property Criteria</Text>
              <VStack align="start" spacing={2}>
                <Text><strong>Areas Serviced:</strong> {lead.criteria?.areasServiced || 'N/A'}</Text>
                <Text><strong>Property Types:</strong> {lead.criteria?.propertyTypes?.join(', ') || 'N/A'}</Text>
                <Text><strong>Bedrooms:</strong> {lead.criteria?.bedrooms || 'N/A'}</Text>
                <Text><strong>Bathrooms:</strong> {lead.criteria?.bathrooms || 'N/A'}</Text>
              </VStack>
            </Box>

            {/* Financial Information */}
            <Box>
              <Text fontWeight="bold" color="orange.400" mb={2}>Financial Details</Text>
              <VStack align="start" spacing={2}>
                <Text><strong>Price Range:</strong> {lead.criteria?.priceRange || 'N/A'}</Text>
                <Text><strong>Pre-Approval:</strong> {lead.criteria?.preApproval || 'N/A'}</Text>
                <Text><strong>Credit Score:</strong> {lead.criteria?.creditScore || 'N/A'}</Text>
                <Text><strong>Lawyer:</strong> {lead.criteria?.lawyer || 'N/A'}</Text>
                <Text><strong>Mortgage Agent:</strong> {lead.criteria?.mortgageAgent || 'N/A'}</Text>
              </VStack>
            </Box>
          </>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default LeadDetails;