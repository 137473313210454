import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  List,
  ListItem,
  ListIcon,
  Switch,
  useColorModeValue,
  Badge,
  Tooltip,
  Icon,
  SimpleGrid,
} from '@chakra-ui/react';
import { CheckCircleIcon, InfoIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { BsLightningChargeFill } from 'react-icons/bs';

const PricingPage = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const highlightColor = useColorModeValue('orange.50', 'gray.700');

  const MotionBox = motion(Box);

  const plans = [
    {
      name: 'Free',
      price: { 
        monthly: 0,
        annual: 0 
      },
      description: 'Perfect for trying out our CRM',
      features: [
        'Full CRM Access',
        '1 Email Account Integration',
        'Basic Lead Management',
        'Email Capabilities',
        'Basic Support',
      ],
      addOns: [], // Free plan has no add-ons
      buttonText: 'Get Started',
      isPopular: false,
    },
    {
      name: 'Pro',
      price: { 
        monthly: 69,
        annual: 828 // $69 * 12
      },
      annualDiscount: 59 * 12, // Annual price with discount
      description: 'Everything you need to grow your business',
      features: [
        'All Free Features',
        'Integrated Calling & SMS',
        '2 Email Account Syncs',
        '5GB Storage',
        'Call Scripts & Recording',
        'Basic AI Analysis',
        'Enhanced Lead Management',
        'Task Management',
      ],
      addOns: [
        { name: 'Additional Email Account', price: 3, unit: 'per account/month' },
        { name: 'Extra Storage 10GB', price: 10, unit: 'per month' },
        { name: 'Premium Call Scripts', price: 10, unit: 'per month' },
      ],
      buttonText: 'Start Pro Trial',
      isPopular: true,
    },
    {
      name: 'Advanced',
      price: { 
        monthly: 349,
        annual: 4188 // $349 * 12
      },
      annualDiscount: 299 * 12, // Annual price with discount
      description: 'Advanced features for power users',
      features: [
        'All Pro Features',
        'Weekly Coaching Calls',
        'Premium Support',
        'Advanced AI Analysis',
        'Unlimited Storage',
        'Advanced Customization',
        'Third-party Integrations',
      ],
      addOns: [
        { name: 'Custom Integration', price: 50, unit: 'per month', setupFee: 200 },
        { name: 'Premium Training', price: null, unit: 'Contact Us' },
      ],
      buttonText: 'Contact Sales',
      isPopular: false,
    },
  ];

  return (
    <Container maxW="7xl" py={20}>
      {/* Header Section */}
      <VStack spacing={4} textAlign="center" mb={16}>
        <Heading
          as="h1"
          fontSize={{ base: '4xl', md: '5xl' }}
          bgGradient="linear(to-r, orange.400, orange.600)"
          bgClip="text"
        >
          Simple, Transparent Pricing
        </Heading>
        <Text fontSize="xl" color="gray.500">
          Choose the plan that's right for you
        </Text>
        
        {/* Billing Toggle */}
        <HStack spacing={4} mt={8}>
          <Text color={isAnnual ? 'gray.500' : 'gray.900'}>Monthly</Text>
          <Switch
            size="lg"
            isChecked={isAnnual}
            onChange={() => setIsAnnual(!isAnnual)}
            colorScheme="orange"
          />
          <Text color={isAnnual ? 'gray.900' : 'gray.500'}>
            Annual
            <Badge ml={2} colorScheme="green" variant="solid">
              Save up to 60%
            </Badge>
          </Text>
        </HStack>
      </VStack>

      {/* Pricing Cards */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
        {plans.map((plan) => (
          <MotionBox
            key={plan.name}
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
          >
            <Box
              bg={plan.isPopular ? highlightColor : bgColor}
              border="1px solid"
              borderColor={borderColor}
              borderRadius="xl"
              p={8}
              position="relative"
              overflow="hidden"
            >
              {plan.isPopular && (
                <Badge
                  colorScheme="orange"
                  position="absolute"
                  top={4}
                  right={4}
                  display="flex"
                  alignItems="center"
                >
                  <Icon as={BsLightningChargeFill} mr={1} />
                  Most Popular
                </Badge>
              )}

              <VStack spacing={4} align="stretch">
                <Heading size="lg">{plan.name}</Heading>
                <Box>
                  <Text fontSize="6xl" fontWeight="bold">
                    ${isAnnual ? (plan.annualDiscount / 12).toFixed(0) : plan.price.monthly}
                  </Text>
                  <Text color="gray.500">
                    per month
                    {isAnnual && (
                      <Text as="span" fontSize="sm">
                        {' '}(billed annually at ${plan.annualDiscount}/year)
                      </Text>
                    )}
                  </Text>
                  {isAnnual && plan.annualDiscount && (
                    <Text color="green.500" fontSize="sm">
                      Save ${(plan.price.annual - plan.annualDiscount).toFixed(0)} annually
                    </Text>
                  )}
                </Box>
                <Text color="gray.500">{plan.description}</Text>

                {/* Add-ons Section */}
                {plan.addOns && plan.addOns.length > 0 && (
                  <Box mt={6}>
                    <Text fontWeight="bold" mb={2}>Available Add-ons:</Text>
                    <List spacing={2}>
                      {plan.addOns.map((addon, index) => (
                        <ListItem key={index} fontSize="sm" color="gray.500">
                          <HStack justify="space-between">
                            <Text>{addon.name}</Text>
                            <Text>
                              {addon.price ? `$${addon.price} ${addon.unit}` : addon.unit}
                              {addon.setupFee && ` + $${addon.setupFee} setup`}
                            </Text>
                          </HStack>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}

                <Button
                  colorScheme={plan.isPopular ? 'orange' : 'gray'}
                  size="lg"
                  w="full"
                  mt={4}
                >
                  {plan.buttonText}
                </Button>

                <List spacing={3} mt={6}>
                  {plan.features.map((feature) => (
                    <ListItem key={feature} display="flex" alignItems="center">
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      <Text flex="1">{feature}</Text>
                      <Tooltip label="Learn more about this feature">
                        <InfoIcon color="gray.400" ml={2} cursor="pointer" />
                      </Tooltip>
                    </ListItem>
                  ))}
                </List>
              </VStack>
            </Box>
          </MotionBox>
        ))}
      </SimpleGrid>

      {/* FAQ or Additional Info Section */}
      <Box mt={20} textAlign="center">
        <Heading size="lg" mb={4}>
          Need Something Different?
        </Heading>
        <Text color="gray.500" mb={8}>
          Contact us for custom enterprise solutions and special requirements
        </Text>
        <Button
          size="lg"
          colorScheme="orange"
          variant="outline"
          rightIcon={<InfoIcon />}
        >
          Contact Sales
        </Button>
      </Box>
    </Container>
  );
};

export default PricingPage; 