import { io } from 'socket.io-client';

// Update the SOCKET_SERVER_URL to use the main domain
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL || 'https://www.realtalkpro.com';

// Initialize socket as null
let socket = null;

// Add connection status tracking
let isConnecting = false;

// Function to connect socket
export const connectSocket = () => {
  if (!socket && !isConnecting) {
    isConnecting = true;
    
    socket = io(SOCKET_SERVER_URL, {
      transports: ['websocket', 'polling'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 3000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
      autoConnect: true,
      withCredentials: true,
      path: '/socket.io',
      forceNew: true,
      auth: {
        token: localStorage.getItem('authToken')
      }
    });

    // Enhanced error handling
    socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      isConnecting = false;
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      isConnecting = false;
    });

    socket.on('reconnect_failed', () => {
      console.error('Reconnection failed');
      socket = null;
      isConnecting = false;
    });
  }
  return socket;
};

// Function to get the socket instance
export const getSocket = () => {
  if (!socket) {
    return connectSocket();
  }
  return socket;
};

// Function to disconnect socket
export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
    isConnecting = false;
    console.log('Socket disconnected and cleaned up');
  }
};

// Export the getSocket function instead of the socket instance
export default getSocket;
