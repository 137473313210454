import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSearch, faLightbulb, faQuestionCircle, 
  faBriefcase, faMoneyBillWave, faTasks, 
  faCalendar, faChartBar, faBox, faBuilding,
  faCog, faSyncAlt, faListUl, faChartPie,
  faUser, faEllipsisV, faEdit, faPlus
} from '@fortawesome/free-solid-svg-icons';

function FPDDash() {
    const [isHovered, setIsHovered] = React.useState(false);

    const containerStyle = {
        display: 'flex',
        height: '100vh',
        backgroundColor: '#f5f7f9',
    };

    const mainContentStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    };

    const topNavStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 25px',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid #e0e0e0',
        boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
    };

    const searchBarStyle = {
        flex: 1,
        maxWidth: '600px',
        margin: '0 20px',
        position: 'relative',
    };

    const defaultTextStyle = {
        color: '#333',
    };

    const searchInputStyle = {
        ...defaultTextStyle,
        width: '100%',
        padding: '10px 12px 10px 40px',
        borderRadius: '20px',
        border: '1px solid #e0e0e0',
        fontSize: '14px',
        backgroundColor: '#f8f9fa',
        transition: 'all 0.2s ease',
        ':focus': {
            backgroundColor: '#ffffff',
            borderColor: '#1a73e8',
            outline: 'none',
            boxShadow: '0 0 0 2px rgba(26,115,232,0.2)',
        },
    };

    const searchIconStyle = {
        position: 'absolute',
        left: '12px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#666',
    };

    const userProfileStyle = {
        ...defaultTextStyle,
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        fontSize: '14px',
    };

    const userAvatarStyle = {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        overflow: 'hidden',
    };

    const secondaryNavStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 25px',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid #e0e0e0',
        boxShadow: '0 1px 2px rgba(0,0,0,0.03)',
    };

    const viewToggleStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    };

    const toggleButtonStyle = {
        padding: '6px 12px',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        backgroundColor: '#fff',
        cursor: 'pointer',
    };

    const activeToggleStyle = {
        ...toggleButtonStyle,
        backgroundColor: '#e8f0fe',
        borderColor: '#1a73e8',
        color: '#1a73e8',
    };

    const addDealButtonStyle = {
        padding: '6px 16px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        fontSize: '14px',
    };

    const pipelineInfoStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        fontSize: '14px',
        color: '#444',
    };

    const columnStyle = {
        flex: '0 0 300px',
        marginRight: '20px',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        padding: '15px',
        minHeight: '200px',
        border: '2px solid #e0e0e0',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    };

    const dealCardStyle = {
        backgroundColor: '#ffffff',
        marginBottom: '10px',
        padding: '12px',
        borderRadius: '6px',
        position: 'relative',
        border: '1px solid #e0e0e0',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    };

    const wonDealStyle = {
        ...dealCardStyle,
        borderLeft: '4px solid #28a745',
    };

    const sidebarStyle = {
        width: isHovered ? '240px' : '64px',
        backgroundColor: '#211D52',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 0',
        zIndex: 1000,
        height: '100vh',
        transition: 'width 0.3s ease',
        boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
        position: 'sticky',
        top: 0,
        alignItems: 'flex-start',
        justifyContent: 'center',
    };

    const sidebarLogoStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        marginBottom: '30px',
        color: '#ffffff',
        overflow: 'hidden',
        position: 'absolute',
        top: '20px',
    };

    const sidebarIconStyle = {
        color: '#ffffff',
        marginBottom: '15px',
        cursor: 'pointer',
        opacity: 0.9,
        padding: '12px 20px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        transition: 'background-color 0.2s',
        ':hover': {
            opacity: 1,
            backgroundColor: '#0d1257',
        },
    };

    const iconLabelStyle = {
        marginLeft: '12px',
        fontSize: '14px',
        color: '#ffffff',
        whiteSpace: 'nowrap',
        opacity: isHovered ? 1 : 0,
        transition: 'opacity 0.2s ease',
    };

    const kanbanStyle = {
        flex: 1,
        display: 'flex',
        padding: '20px',
        paddingLeft: '20px',
        overflowX: 'auto',
        backgroundColor: '#f5f7f9',
    };

    const columnHeaderStyle = {
        ...defaultTextStyle,
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    const dealNameStyle = {
        ...defaultTextStyle,
        fontSize: '14px',
        fontWeight: '500',
        marginBottom: '4px',
    };

    const companyNameStyle = {
        ...defaultTextStyle,
        fontSize: '13px',
        marginBottom: '8px',
    };

    const dealValueStyle = {
        ...defaultTextStyle,
        fontSize: '13px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    };

    const footerActionStyle = {
        color: '#333',
        cursor: 'pointer',
    };

    const lostActionStyle = {
        ...footerActionStyle,
        color: 'red',
    };

    const wonActionStyle = {
        ...footerActionStyle,
        color: 'green',
    };

    return (
        <div style={containerStyle}>
            <div 
                style={sidebarStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div style={sidebarLogoStyle}>
                    <span style={{
                        fontSize: '24px',
                        color: '#ffffff',
                        fontWeight: 'bold',
                    }}>
                        R
                    </span>
                    <span style={iconLabelStyle}>RealTalkPro</span>
                </div>
                <div style={sidebarIconStyle}>
                    <FontAwesomeIcon icon={faBriefcase} />
                    <span style={iconLabelStyle}>Deals</span>
                </div>
                <div style={sidebarIconStyle}>
                    <FontAwesomeIcon icon={faMoneyBillWave} />
                    <span style={iconLabelStyle}>Pipeline</span>
                </div>
                <div style={sidebarIconStyle}>
                    <FontAwesomeIcon icon={faTasks} />
                    <span style={iconLabelStyle}>Activities</span>
                </div>
                <div style={sidebarIconStyle}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <span style={iconLabelStyle}>Calendar</span>
                </div>
                <div style={sidebarIconStyle}>
                    <FontAwesomeIcon icon={faChartBar} />
                    <span style={iconLabelStyle}>Statistics</span>
                </div>
                <div style={sidebarIconStyle}>
                    <FontAwesomeIcon icon={faBox} />
                    <span style={iconLabelStyle}>Products</span>
                </div>
                <div style={sidebarIconStyle}>
                    <FontAwesomeIcon icon={faBuilding} />
                    <span style={iconLabelStyle}>Contacts</span>
                </div>
                <div style={sidebarIconStyle}>
                    <FontAwesomeIcon icon={faCog} />
                    <span style={iconLabelStyle}>Settings</span>
                </div>
            </div>

            <div style={mainContentStyle}>
                <div style={topNavStyle}>
                    <div style={searchBarStyle}>
                        <span style={searchIconStyle}>
                            <FontAwesomeIcon icon={faSearch} style={{ color: '#666' }} />
                        </span>
                        <input
                            type="text"
                            placeholder="Search deals, contacts, or activities..."
                            style={searchInputStyle}
                        />
                    </div>
                    <div style={userProfileStyle}>
                        <FontAwesomeIcon icon={faLightbulb} style={{ color: '#666', cursor: 'pointer' }} />
                        <FontAwesomeIcon icon={faQuestionCircle} style={{ color: '#666', cursor: 'pointer' }} />
                        <div style={{ height: '20px', width: '1px', backgroundColor: '#e0e0e0' }} />
                        <img 
                            src="/api/placeholder/32/32" 
                            alt="User"
                            style={userAvatarStyle}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                            <span style={{ fontWeight: '500' }}>Phyllis Yang</span>
                            <span style={{ color: '#666', fontSize: '12px' }}>Silicon Links Inc</span>
                        </div>
                    </div>
                </div>

                <div style={secondaryNavStyle}>
                    <div style={viewToggleStyle}>
                        <button style={activeToggleStyle}>
                            <FontAwesomeIcon icon={faChartPie} /> Pipeline
                        </button>
                        <button style={toggleButtonStyle}>
                            <FontAwesomeIcon icon={faListUl} /> List
                        </button>
                        <div style={{ width: '1px', height: '20px', backgroundColor: '#e0e0e0', margin: '0 10px' }} />
                        <span style={{ cursor: 'pointer', color: '#666' }}>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </span>
                        <button style={addDealButtonStyle}>
                            <FontAwesomeIcon icon={faPlus} /> Add Deal
                        </button>
                    </div>
                    <div style={pipelineInfoStyle}>
                        <span style={{ fontWeight: '500' }}>21 260€ · 13 deals</span>
                        <select style={{
                            padding: '8px 12px',
                            borderRadius: '4px',
                            border: '1px solid #e0e0e0',
                            backgroundColor: '#fff',
                            cursor: 'pointer',
                        }}>
                            <option>Sales pipeline</option>
                        </select>
                        <FontAwesomeIcon icon={faEdit} style={{ color: '#666', cursor: 'pointer' }} />
                        <FontAwesomeIcon icon={faEllipsisV} style={{ color: '#666', cursor: 'pointer' }} />
                    </div>
                </div>

                <div style={kanbanStyle}>
                    {['Qualified', 'Contact Made', 'Demo Scheduled', 'Proposal Made', 'Negotiations Started'].map((stage, index) => (
                        <div key={stage} style={columnStyle}>
                            <div style={columnHeaderStyle}>
                                <span>{stage}</span>
                                <span style={{fontSize: '13px', color: '#666'}}>
                                    {index === 0 && '10 700€ · 4 deals'}
                                    {index === 1 && '5 350€ · 3 deals'}
                                    {index === 2 && '2 800€ · 2 deals'}
                                    {index === 3 && '2 700€ · 1 deal'}
                                    {index === 4 && '6 110€ · 2 deals'}
                                </span>
                            </div>
                            {index === 0 && (
                                <>
                                    <div style={wonDealStyle}>
                                        <div style={dealNameStyle}>Willamette Co deal</div>
                                        <div style={companyNameStyle}>Willamette Co</div>
                                        <div style={dealValueStyle}>
                                            <FontAwesomeIcon icon={faUser} />
                                            <span>1 500€</span>
                                        </div>
                                    </div>
                                    <div style={dealCardStyle}>
                                        <div style={dealNameStyle}>Park Place deal</div>
                                        <div style={companyNameStyle}>Park Place</div>
                                        <div style={dealValueStyle}>
                                            <FontAwesomeIcon icon={faUser} />
                                            <span>4 500€</span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    padding: '15px',
                    backgroundColor: '#fff',
                    borderTop: '1px solid #e0e0e0',
                }}>
                    <span style={footerActionStyle}>DELETE</span>
                    <span>|</span>
                    <span style={lostActionStyle}>LOST</span>
                    <span>|</span>
                    <span style={wonActionStyle}>WON</span>
                    <span>|</span>
                    <span style={footerActionStyle}>MOVE/CONVERT</span>
                </div>
            </div>
        </div>
    );
}

export default FPDDash;