// frontend/src/firebase.js

// ==========================================
// 🔥 FRONTEND FIREBASE (The Front Door)
// ==========================================
//
// 👉 WHAT THIS FILE DOES:
// - Handles login buttons (Google, Email, etc.)
// - Lives in your browser
// - Gets authentication tokens when users log in
//
// 🔄 REAL-WORLD EXAMPLE:
// Like a bouncer at a club:
// 1. Checks if you have ID
// 2. Lets you choose how to identify yourself (Google, Email, etc.)
// 3. Gives you a wristband (token) if you're allowed in
//
// 🔗 WORKS WITH:
// - auth.js (security guard)
// - authRoutes.js (receptionist)
// - firebaseAdmin.js (security office)
//
// 💡 COMMON USES:
// - Login buttons
// - Sign up forms
// - "Login with Google" popups

// ==========================================
// 🔥 FRONTEND FIREBASE CONFIGURATION (Client Side)
// ==========================================
// 
// 🎯 PURPOSE:
// - Initialize Firebase in the browser
// - Handle user authentication (Google, Email, Phone)
// - Set up analytics tracking
//
// 🔌 CONNECTIONS:
// - Connects to Firebase services
// - Used by React components
// - Provides auth tokens for API calls
//
// 🔑 KEY FEATURES:
// - User sign-in/sign-up
// - Token generation
// - Analytics setup
//
// ⚠️ SECURITY NOTES:
// - Only contains public API keys
// - No sensitive credentials
// - Client-side operations only
//
// 📝 USAGE:
// import { auth, provider } from '../firebase';
// const result = await signInWithPopup(auth, provider);


/*
This file is like setting up your connection to Firebase (Google's backend service).
Think of it as:
- Getting your keys to enter the Firebase house 🔑
- Setting up different ways users can log in (Google, email, phone)
- Making sure everything is properly connected
- If something goes wrong, it'll tell us what happened

// ===== OVERVIEW =====
/*
This file handles all Firebase setup and configuration for our app.
It's like the control center for all Firebase-related services.
We set up authentication, analytics, and make them available to other parts of the app.
*/

// ===== IMPORTS EXPLANATION =====
/*
We import different Firebase tools:
- initializeApp: The main Firebase starter
- getAnalytics: For tracking how people use our app
- Authentication tools:
  - getAuth: Main authentication manager
  - GoogleAuthProvider: Lets users sign in with Google
  - fetchSignInMethodsForEmail: Checks how a user can sign in
  - signInWithEmailAndPassword: For email/password login
  - signInWithPhoneNumber: For phone number login
  - RecaptchaVerifier: Prevents spam/bots in phone sign-in
*/

// ===== FIREBASE CONFIG EXPLANATION =====
/*
firebaseConfig is like your app's ID card for Firebase:
- apiKey: Your app's personal key
- authDomain: Where authentication happens
- projectId: Your project's unique name
- storageBucket: Where files are stored
- messagingSenderId: For sending notifications
- appId: Your app's unique ID
- measurementId: For tracking app usage
*/

// ===== VARIABLES EXPLANATION =====
/*
We create these containers to hold Firebase tools:
- app: Main Firebase instance
- auth: Handles all user login/signup
- analytics: Tracks app usage
- provider: Handles Google sign-in
*/

// ===== INITIALIZATION EXPLANATION =====
/*
The try-catch block:
1. Starts up Firebase with your config
2. Sets up authentication
3. Sets up analytics
4. Prepares Google sign-in
5. If anything goes wrong, logs the error (safely)
*/

// ===== EXPORTS EXPLANATION =====
/*
We make these tools available to other files:
- auth: For checking if users are logged in
- app: The main Firebase instance
- analytics: For usage tracking
- provider: For Google sign-in
- Various authentication methods for different sign-in types
*/

// ===== USAGE EXAMPLES =====
/*
1. Google Sign-In:
   const signInWithGoogle = async () => {
     const result = await signInWithPopup(auth, provider);
   };

2. Email Sign-In:
   const signInWithEmail = async (email, password) => {
     const result = await signInWithEmailAndPassword(auth, email, password);
   };

3. Phone Sign-In:
   const signInWithPhone = async (phoneNumber) => {
     const verifier = new RecaptchaVerifier('recaptcha-container');
     const result = await signInWithPhoneNumber(auth, phoneNumber, verifier);
   };
*/

// ===== COMMON ERRORS =====
/*
1. "Firebase: Error (auth/configuration-not-found)"
   → Check your firebaseConfig values

2. "Firebase: Error (auth/invalid-api-key)"
   → Your API key is wrong or restricted

3. "Firebase: Error (auth/unauthorized-domain)"
   → Your domain isn't allowed for authentication

4. "Firebase: Error (auth/operation-not-allowed)"
   → The sign-in method isn't enabled in Firebase Console
*/

// ===== SECURITY NOTES =====
/*
1. Never commit API keys to public repositories
2. Use environment variables when possible
3. Enable only the authentication methods you need
4. Set up proper Firebase security rules
*/

// ===== MAINTENANCE TIPS =====
/*
1. Regularly check Firebase Console for usage/errors
2. Keep Firebase dependencies updated
3. Monitor authentication success rates
4. Review analytics data for user behavior
*/

// Verification Points:
// - Firebase is initialized with the correct configuration.
// - GoogleAuthProvider is set up for authentication.
// - Proper error handling is in place for initialization failures.




import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  getAuth, 
  GoogleAuthProvider,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  RecaptchaVerifier
} from "firebase/auth";
import axios from 'axios';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC6A2gfwhx-6RwGwU_X1CjbdbCWmjo5kNI",
  authDomain: "realtalkpro-bc30b.firebaseapp.com",
  projectId: "realtalkpro-bc30b",
  storageBucket: "realtalkpro-bc30b.appspot.com",
  messagingSenderId: "32674183996",
  appId: "1:32674183996:web:4072e7e8f6d8fcc27c7c39",
  measurementId: "G-5F8C47QFW3"
};

// Initialize Firebase
let app;
let auth;
let analytics;
let provider;

try {
  // Initialize the Firebase app
  app = initializeApp(firebaseConfig);
  
  // Initialize Firebase Auth and Analytics
  auth = getAuth(app);
  analytics = getAnalytics(app);
  
  // Initialize Google Auth Provider
  provider = new GoogleAuthProvider();
  
  console.log("Firebase initialized successfully");
} catch (error) {
  console.error('🔥 Firebase Initialization Error:', {
    message: error.message,
    stack: error.stack,
    timestamp: new Date().toISOString(),
    config: {
      ...firebaseConfig,
      apiKey: '***REDACTED***' // Security: Don't log the actual API key
    }
  });
}

// Add this function to handle token refresh
export const refreshAuthToken = async () => {
  try {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const newToken = await currentUser.getIdToken(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      return newToken;
    }
    return null;
  } catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
  }
};

export { 
  auth, 
  app, 
  analytics, 
  provider,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  RecaptchaVerifier
};