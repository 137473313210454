import React, { useState, useCallback } from 'react';
import { Box, Heading, SimpleGrid, FormControl, FormLabel, Input, Button, Flex, Icon, useToast } from '@chakra-ui/react';
import { FaGlobe, FaClipboardList, FaHome, FaHistory, FaUserFriends, FaRedoAlt } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Avatar, HStack, VStack, Text, Badge } from '@chakra-ui/react';

const logError = (error, context) => {
  console.error(`🔴 Error ${context}:`, {
    message: error.message,
    response: error.response?.data,
    status: error.response?.status,
    stack: error.stack
  });
};

const AddNewLeadSection = () => {
  const [newLead, setNewLead] = useState({ name: '', email: '', phone: '', leadSource: '' });
  const [suggestedDuplicates, setSuggestedDuplicates] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  // Handle lead source selection
  const handleLeadSourceSelect = (selectedSource) => {
    setNewLead({ ...newLead, leadSource: selectedSource });
  };

  // Handle input change
  const handleNewLeadChange = (e) => {
    const updatedLead = { ...newLead, [e.target.name]: e.target.value };
    setNewLead(updatedLead);

    // Trigger duplicate search if name, email, or phone is being updated
    if (['name', 'email', 'phone'].includes(e.target.name)) {
      const searchParams = {
        name: updatedLead.name,
        email: updatedLead.email,
        phone: updatedLead.phone,
      };
      // Only search if at least one field is filled
      if (
        (searchParams.name && searchParams.name.trim()) ||
        (searchParams.email && searchParams.email.trim()) ||
        (searchParams.phone && searchParams.phone.trim())
      ) {
        fetchSuggestedDuplicates(searchParams);
      } else {
        setSuggestedDuplicates([]);
        setShowSuggestions(false);
      }
    }
  };

  // Fetch suggested duplicates
  const fetchSuggestedDuplicates = useCallback(
    async (leadData) => {
      try {
        console.log('🔍 Checking for duplicates:', leadData);
        const response = await axios.post('/api/leads/search-duplicates', leadData);
        console.log('✅ Suggested Duplicates:', response.data.duplicates);
        setSuggestedDuplicates(response.data.duplicates);
        setShowSuggestions(response.data.duplicates.length > 0);
      } catch (error) {
        logError(error, 'fetching duplicates');
        setSuggestedDuplicates([]);
        setShowSuggestions(false);
      }
    },
    [setSuggestedDuplicates, setShowSuggestions]
  );

  // Handle form submission
  const handleNewLeadSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting new lead for duplicate check:', newLead);
  
      const response = await axios.post('/api/leads/check-duplicates', newLead);
      console.log('Duplicate Check Response:', response.data);
      const { duplicates } = response.data;  
  
      if (duplicates && duplicates.length > 0) {
        console.log('Duplicates found:', duplicates);
        setSuggestedDuplicates(duplicates);
        setShowSuggestions(true);
      } else {
        console.log('No duplicates found, creating new lead');
        await createLead();
      }
    } catch (error) {
      console.error('Error checking for duplicates:', error.response ? error.response.data : error);
      toast({
        title: 'Error',
        description: 'An error occurred while checking for duplicates.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Create new lead
  const createLead = async (forceProceed = false) => {
    try {
      console.log('📝 Creating new lead:', newLead);
      const response = await axios.post('/api/leads', { ...newLead, forceProceed });
      console.log('✅ New lead created:', response.data);
      
      toast({
        title: '✅ Lead created',
        description: '🎯 The new lead has been successfully added. Redirecting to Call Script.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
  
      navigate('/call-script', { 
        state: { 
          leadId: response.data._id,
          leadData: response.data,
          leadSource: newLead.leadSource,
          currentStageIndex: { 
            section: 0, 
            stage: 0
          } 
        } 
      });
  
      // Reset the form and suggestions
      setNewLead({
        name: '',
        email: '',
        phone: '',
        leadSource: '',
      });
      setSuggestedDuplicates([]);
      setShowSuggestions(false);
    } catch (error) {
      logError(error, 'creating lead');
      let errorMessage = '❌ Failed to create lead. Please try again.';
      if (error.response) {
        console.error('🔴 Error response:', error.response.data);
        errorMessage = error.response.data.error || errorMessage;
      }
      toast({
        title: '❌ Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle create duplicate anyway
  const handleCreateDuplicateAnyway = async () => {
    await createLead(true);
  };

  // Add new function to handle using existing lead
  const handleUseExistingLead = (existingLead) => {
    navigate('/call-script', { 
      state: { 
        leadId: existingLead._id,
        leadData: existingLead,
        leadSource: existingLead.leadSource,
        currentStageIndex: { 
          section: 0, 
          stage: 0
        },
        isExistingLead: true
      } 
    });
  };

  return (
    <Box
      mb={10}
      bg="surfaceBg"
      p={5}
      borderRadius="md"
      className="gold-border-glow"
    >
      <VStack spacing={4} align="stretch">
        <Heading size="md" color="orange.400">Add New Lead</Heading>
        <form onSubmit={handleNewLeadSubmit}>
          {/* Form fields */}
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={4}>
            {/* Name input */}
            <FormControl id="name">
              <FormLabel color="gray.300">Name</FormLabel>
              <Input 
                type="text" 
                name="name"
                value={newLead.name}
                onChange={handleNewLeadChange}
                required
                bg="black"
                borderColor="yellow.500"
                _hover={{ borderColor: "orange.400" }}
                _focus={{ borderColor: "yellow.300", boxShadow: "0 0 0 1px #ECC94B" }}
              />
            </FormControl>
            {/* Email input */}
            <FormControl id="email">
              <FormLabel color="gray.300">Email</FormLabel>
              <Input 
                type="email" 
                name="email"
                value={newLead.email}
                onChange={handleNewLeadChange}
                required
                bg="black"
                borderColor="yellow.500"
                _hover={{ borderColor: "orange.400" }}
                _focus={{ borderColor: "yellow.300", boxShadow: "0 0 0 1px #ECC94B" }}
              />
            </FormControl>
            {/* Phone input */}
            <FormControl id="phone">
              <FormLabel color="gray.300">Phone</FormLabel>
              <Input 
                type="tel" 
                name="phone"
                value={newLead.phone}
                onChange={handleNewLeadChange}
                required
                bg="black"
                borderColor="yellow.500"
                _hover={{ borderColor: "orange.400" }}
                _focus={{ borderColor: "yellow.300", boxShadow: "0 0 0 1px #ECC94B" }}
              />
            </FormControl>
          </SimpleGrid>

          {/* Lead Source Buttons */}
          <SimpleGrid columns={{ base: 2, sm: 3, md: 6 }} spacing={2} mb={4}>
            {[
              { type: 'website_signup', icon: FaGlobe, label: 'Website' },
              { type: 'Downloaded_Lead_Magnet', icon: FaClipboardList, label: 'Lead Magnet' },
              { type: 'open_house', icon: FaHome, label: 'Open House' },
              { type: 'older_lead', icon: FaHistory, label: 'Older Lead' },
              { type: 'referral', icon: FaUserFriends, label: 'Referral' },
              { type: 'followup', icon: FaRedoAlt, label: 'Follow-up' },
            ].map((lead) => (
              <Button
                key={lead.type}
                onClick={() => handleLeadSourceSelect(lead.type)}
                colorScheme="orange"
                variant={newLead.leadSource === lead.type ? "solid" : "outline"}
                height={{
                  base: "60px",
                  md: "50px"
                }}
                width="100%"
                _hover={{ bg: 'orange.200' }}
                borderWidth="2px"
                borderColor="yellow.500"
                leftIcon={<Icon as={lead.icon} boxSize="18px" color={newLead.leadSource === lead.type ? "black" : "yellow.500"} />}
                color={newLead.leadSource === lead.type ? "black" : "yellow.500"}
                justifyContent="flex-start"
                fontSize={{
                  base: "xs",
                  sm: "sm"
                }}
                fontWeight="bold"
                px={2}
                whiteSpace="normal"
                textAlign="left"
              >
                {lead.label}
              </Button>
            ))}
          </SimpleGrid>

          {/* Action Buttons */}
          <Flex direction={{ base: 'column', sm: 'row' }} gap={4}>
            <Button 
              colorScheme="orange" 
              type="submit"
              flex={1}
              height="60px"
              fontSize="xl"
              fontWeight="bold"
              _hover={{ bg: 'orange.300' }}
            >
              {suggestedDuplicates.length > 0 ? "Proceed to Call Script" : "Add New Lead"}
            </Button>

            {suggestedDuplicates.length > 0 && (
              <Button 
                colorScheme="orange" 
                onClick={handleCreateDuplicateAnyway}
                flex={1}
                height="60px"
                fontSize="xl"
                fontWeight="bold"
                _hover={{ bg: 'orange.300' }}
              >
                Create Duplicate Anyway
              </Button>
            )}
          </Flex>
        </form>

        {/* Duplicate Suggestions */}
        {showSuggestions && (
          <Box mt={6}>
            <Heading fontSize="lg" mb={3} color="orange.400">Potential Duplicates</Heading>
            {suggestedDuplicates.length > 0 ? (
              <SimpleGrid columns={{ base: 1, md: suggestedDuplicates.length === 1 ? 1 : 2 }} spacing={4}>
                {suggestedDuplicates.map(lead => (
                  <Box 
                    key={lead._id} 
                    p={3} 
                    bg="gray.900" 
                    borderRadius="md" 
                    borderColor="yellow.500"
                    borderWidth="1px"
                    _hover={{ bg: 'gray.800', borderColor: "orange.400" }}
                    transition="all 0.2s"
                  >
                    <HStack spacing={4}>
                      <Avatar size="md" name={lead.name} />
                      <VStack align="start" spacing={1} flex={1}>
                        <Text fontWeight="bold" fontSize="md" color="white">
                          {lead.name}
                        </Text>
                        <Text fontSize="sm" color="gray.300">{lead.email}</Text>
                        <Text fontSize="sm" color="gray.300">{lead.phone}</Text>
                      </VStack>
                      <HStack>
                        <Button
                                                  variant="outline"
                          colorScheme="blue"

                          size="sm"
                          onClick={() => handleUseExistingLead(lead)}
                        >
                          Call Script This Lead
                        </Button>
                        <Button
                          colorScheme="yellow"
                          size="sm"
                          onClick={() => navigate(`/leads/${lead._id}`)}
                        >
                          View Lead Details
                        </Button>
                      </HStack>
                    </HStack>
                  </Box>
                ))}
              </SimpleGrid>
            ) : (
              <Text color="gray.400">No potential duplicates found.</Text>
            )}
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default AddNewLeadSection;
