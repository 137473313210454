import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Text,
  useToast,
  Avatar,
  HStack,
  IconButton,
  Box,
  Select,
  InputGroup,
  InputRightElement,
  Spinner,
  FormHelperText,
  SimpleGrid
} from '@chakra-ui/react';
import { FaUpload, FaCheck, FaTimes, FaEye, FaEyeSlash } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { auth } from '../firebase';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { signOut } from 'firebase/auth';
import { isValidPhoneNumber, validateAndFormatPhoneNumber } from '../utils/phoneUtils';

const ModalOverlayMotion = motion(ModalOverlay);
const ModalContentMotion = motion(ModalContent);

const AnimatedInput = styled(Input)`
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(255, 165, 0, 0.2);
  }

  &:focus {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(255, 165, 0, 0.3);
  }
`;

const validateImageType = (file) => {
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
  return allowedTypes.includes(file.type);
};

const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&+\-_])[A-Za-z\d@$!%*?&+\-_]{8,}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ProfileConfirmationModal = ({ 
  isOpen, 
  onClose, 
  initialData, 
  onConfirm 
}) => {
  const toast = useToast();
  const fileInputRef = useRef(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: initialData.firstName || '',
    lastName: initialData.lastName || '',
    email: initialData.email || '',
    phoneNumber: initialData.phoneNumber || '',
    organizationName: `${initialData.firstName || ''}'s Organization`,
    profilePicture: initialData.photoURL || null,
    useGooglePhoto: !!initialData.photoURL,
    role: 'brand_ambassador',
    password: '',
    confirmPassword: ''
  });

  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [formValidation, setFormValidation] = useState({
    phoneNumber: { isValid: false, message: '' },
    password: { isValid: false, message: '' },
    confirmPassword: { isValid: false, message: '' }
  });

  const [authState, setAuthState] = useState({
    isReady: false,
    user: null
  });

  const isGoogleAuth = initialData.authMethod === 'google';

  // Add new state for required auth fields
  const [requiredAuth, setRequiredAuth] = useState({
    needsEmail: !initialData.email,
    needsPhone: !initialData.phoneNumber,
    needsPassword: initialData.authMethod !== 'password'
  });

  // Update the validateForm function
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&+\-_])[A-Za-z\d@$!%*?&+\-_]{8,}$/;

    console.log('🔍 Validating form with required auth:', {
      needsPassword: requiredAuth.needsPassword,
      password: formData.password,
      confirmPassword: formData.confirmPassword
    });

    const validations = {
      firstName: {
        isValid: !!formData.firstName.trim(),
        message: 'First name is required'
      },
      lastName: {
        isValid: !!formData.lastName.trim(),
        message: 'Last name is required'
      },
      email: {
        isValid: !requiredAuth.needsEmail || emailRegex.test(formData.email),
        message: 'Valid email is required'
      },
      phoneNumber: {
        isValid: isValidPhoneNumber(formData.phoneNumber),
        message: 'Please enter a valid phone number starting with + and country code'
      },
      password: {
        isValid: !requiredAuth.needsPassword || passwordRegex.test(formData.password),
        message: 'Password must be at least 8 characters with letters, numbers, and special characters'
      },
      confirmPassword: {
        isValid: !requiredAuth.needsPassword || formData.password === formData.confirmPassword,
        message: 'Passwords must match'
      },
      organizationName: {
        isValid: !!formData.organizationName.trim(),
        message: 'Organization name is required'
      }
    };

    // Debug log validation results
    console.log('✅ Validation results:', {
      validations,
      requiredAuth,
      passwordLength: formData.password?.length,
      hasPassword: !!formData.password
    });

    setFormValidation(validations);
    
    return Object.values(validations).every(v => v.isValid);
  };

  // Update the generatePassword function
  const generatePassword = () => {
    const length = 12;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@$!%*?&+-_';
    let password = '';
    
    // Ensure at least one of each required character type
    password += 'abcdefghijklmnopqrstuvwxyz'[Math.floor(Math.random() * 26)]; // lowercase
    password += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[Math.floor(Math.random() * 26)]; // uppercase
    password += '0123456789'[Math.floor(Math.random() * 10)]; // number
    password += '@$!%*?&+-_'[Math.floor(Math.random() * 10)]; // special char
    
    // Fill the rest randomly
    for (let i = password.length; i < length; i++) {
      password += charset[Math.floor(Math.random() * charset.length)];
    }

    // Shuffle the password
    password = password.split('').sort(() => Math.random() - 0.5).join('');
    
    setFormData(prev => ({
      ...prev,
      password: password,
      confirmPassword: password
    }));

    // Update validation state
    setFormValidation(prev => ({
      ...prev,
      password: { isValid: true, message: '' },
      confirmPassword: { isValid: true, message: '' }
    }));
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log('🔐 Auth state changed:', user?.email);
      if (user) {
        try {
          const token = await user.getIdToken(true);
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          
          setAuthState({
            isReady: true,
            user
          });
        } catch (error) {
          console.error('Token refresh failed:', error);
          setAuthState({
            isReady: true,
            user: null
          });
          toast({
            title: "Authentication Error",
            description: "Please sign in again to continue",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        setAuthState({
          isReady: true,
          user: null
        });
      }
    });

    return () => unsubscribe();
  }, [toast]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'phoneNumber') {
      console.log('📱 Phone number input change:', {
        raw: value,
        sanitized: value.replace(/[^\d+]/g, '')
      });

      // Remove any non-digit characters except +
      const sanitizedValue = value.replace(/[^\d+]/g, '');
      // Ensure only one + at the start
      const formattedNumber = sanitizedValue.startsWith('+') 
        ? sanitizedValue 
        : `+${sanitizedValue}`;
      
      console.log('📱 Phone number formatting:', {
        sanitized: sanitizedValue,
        formatted: formattedNumber,
        validation: isValidPhoneNumber(formattedNumber)
      });

      setFormData(prev => ({
        ...prev,
        [name]: formattedNumber
      }));

      // Update validation immediately
      setFormValidation(prev => ({
        ...prev,
        phoneNumber: {
          isValid: isValidPhoneNumber(formattedNumber),
          message: isValidPhoneNumber(formattedNumber) 
            ? '' 
            : 'Please enter a valid phone number starting with + and country code (e.g., +16478356364)'
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setIsImageLoading(true);

        if (!validateImageType(file)) {
          throw new Error('Invalid file type. Please upload a JPEG, PNG, or GIF image.');
        }

        const maxSize = 5 * 1024 * 1024;
        if (file.size > maxSize) {
          throw new Error('File too large. Please upload an image smaller than 5MB.');
        }

        const reader = new FileReader();
        const imageData = await new Promise((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });

        setNewProfilePicture(imageData);
        setFormData(prev => ({
          ...prev,
          profilePicture: imageData,
          useGooglePhoto: false
        }));

      } catch (error) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsImageLoading(false);
      }
    }
  };

  const handleGooglePhotoToggle = () => {
    setFormData(prev => ({
      ...prev,
      profilePicture: prev.useGooglePhoto ? null : initialData.photoURL,
      useGooglePhoto: !prev.useGooglePhoto
    }));
    setNewProfilePicture(null);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      console.log('🚀 Starting profile submission...', {
        formData,
        formValidation
      });

      // Get fresh token before starting submission
      let token;
      try {
        // Force token refresh at the start
        token = await auth.currentUser?.getIdToken(true);
        if (!token) {
          throw new Error('Authentication required');
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } catch (tokenError) {
        console.error('Initial token refresh failed:', tokenError);
        await signOut(auth);
        navigate('/login', { 
          state: { 
            returnTo: '/dashboard',
            profileData: formData // Save form data
          }
        });
        return;
      }

      // Validate phone number first
      const phoneValidationResult = isValidPhoneNumber(formData.phoneNumber);
      console.log('📱 Phone Validation Check:', {
        input: formData.phoneNumber,
        isValid: phoneValidationResult,
        format: validateAndFormatPhoneNumber(formData.phoneNumber)
      });

      // Update formValidation state with the actual validation result
      setFormValidation(prev => ({
        ...prev,
        phoneNumber: {
          isValid: phoneValidationResult,
          message: phoneValidationResult ? '' : 'Please enter a valid phone number in international format'
        }
      }));

      if (!phoneValidationResult) {
        toast({
          title: 'Invalid Phone Number',
          description: 'Please enter a valid phone number in international format',
          status: 'error',
          duration: 5000,
        });
        return;
      }

      // Validate all fields
      if (!validateForm()) {
        const invalidFields = Object.entries(formValidation)
          .filter(([_, validation]) => !validation.isValid)
          .map(([field]) => field)
          .join(', ');
        
        throw new Error(`Please complete all required fields: ${invalidFields}`);
      }

      // Format phone number for submission
      const formattedPhoneNumber = validateAndFormatPhoneNumber(formData.phoneNumber);
      
      // Send data to backend with retry logic
      let response;
      try {
        const payload = {
          firstName: formData.firstName.trim(),
          lastName: formData.lastName.trim(),
          email: formData.email.trim(),
          phoneNumber: formattedPhoneNumber,
          organizationName: formData.organizationName.trim(),
          role: formData.role,
          password: formData.password,
          authMethods: [
            initialData.authMethod,
            formData.email ? 'password' : null,
            formData.phoneNumber ? 'phone' : null
          ].filter(Boolean)
        };

        // First attempt
        try {
          response = await axios.post('/api/users/onboard', payload);
        } catch (firstError) {
          if (firstError.response?.status === 401) {
            // Get fresh token for retry
            token = await auth.currentUser?.getIdToken(true);
            if (!token) throw new Error('Authentication failed');
            
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            // Retry with fresh token
            response = await axios.post('/api/users/onboard', payload);
          } else {
            throw firstError;
          }
        }

        console.log('✅ Profile created successfully:', response.data);

        // Get fresh token after profile creation
        await auth.currentUser?.getIdToken(true);

        toast({
          title: 'Welcome!',
          description: 'Your profile has been created successfully.',
          status: 'success',
          duration: 5000,
        });

        await onConfirm(response.data);
        onClose();

        // Add small delay before navigation
        setTimeout(() => {
          navigate('/dashboard');
        }, 500);

      } catch (error) {
        console.error('❌ Profile creation error:', error);
        
        const errorMessage = error.response?.data?.details || error.response?.data?.message || error.message;
        
        // Handle token expiration specifically
        if (error.code === 'auth/user-token-expired' || error.response?.status === 401) {
          toast({
            title: 'Session Expired',
            description: 'Please sign in again to complete your profile',
            status: 'warning',
            duration: 5000,
          });

          // Save form data before redirecting
          await signOut(auth);
          navigate('/login', { 
            state: { 
              returnTo: '/dashboard',
              profileData: formData
            }
          });
          return;
        }

        toast({
          title: 'Error Creating Profile',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('❌ Profile creation error:', error);
      
      const errorMessage = error.response?.data?.details || error.response?.data?.message || error.message;
      
      toast({
        title: 'Error Creating Profile',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });

      if (error.response?.status === 401 || error.code === 'auth/user-token-expired') {
        await signOut(auth);
        navigate('/login', { 
          state: { 
            returnTo: '/dashboard',
            profileData: formData // Save form data
          }
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const modalVariants = {
    hidden: { 
      y: 50,
      opacity: 0,
      scale: 0.95
    },
    visible: { 
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 300
      }
    },
    exit: {
      y: 50,
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 0.2
      }
    }
  };

  useEffect(() => {
    if (formData.password || formData.confirmPassword) {
      setFormValidation(prev => ({
        ...prev,
        password: {
          isValid: passwordRegex.test(formData.password),
          message: passwordRegex.test(formData.password) ? '' : 
            'Password must be at least 8 characters with letters, numbers, and special characters'
        },
        confirmPassword: {
          isValid: formData.password === formData.confirmPassword,
          message: formData.password === formData.confirmPassword ? '' : 'Passwords must match'
        }
      }));
    }
  }, [formData.password, formData.confirmPassword]);

  if (!authState.isReady) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalBody>
            <VStack spacing={4} py={8}>
              <Spinner size="xl" color="orange.500" />
              <Text>Preparing your profile...</Text>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <Modal 
          isOpen={isOpen} 
          onClose={onClose} 
          size="6xl"
          isCentered
          motionPreset="slideInBottom"
        >
          <ModalOverlayMotion
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
            backdropFilter="blur(10px)"
            style={{ 
              padding: "40px", 
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          />
          <ModalContentMotion
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
            bg="black"
            border="1px solid #FFA500"
            color="#FFA500"
            maxW="1200px"
            minH="auto"
            maxH="90vh"
            p={8}
            my={8}
            mx="auto"
            position="relative"
            style={{
              margin: "60px auto",
              maxHeight: "calc(100vh - 120px)",
              overflowY: "auto",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none"
              }
            }}
          >
            <ModalHeader textAlign="center" fontSize="2xl">Complete Your Profile</ModalHeader>
            
            <ModalBody>
              <HStack align="start" spacing={8} w="100%">
                {/* Left Column - Profile Picture */}
                <VStack w="40%" spacing={6}>
                  {initialData.photoURL && (
                    <Box>
                      <Text fontSize="xl" fontWeight="bold" mb={4}>Profile Picture</Text>
                      <VStack spacing={6}>
                        {/* Google Photo Option */}
                        <VStack 
                          p={4}
                          borderRadius="lg"
                          bg={formData.useGooglePhoto ? "rgba(255, 165, 0, 0.1)" : "transparent"}
                          border="1px solid"
                          borderColor={formData.useGooglePhoto ? "orange.500" : "transparent"}
                          w="100%"
                        >
                          <Avatar 
                            size="xl" 
                            src={initialData.photoURL}
                            border={formData.useGooglePhoto ? "4px solid #FFA500" : "1px solid gray"}
                          />
                          <Button
                            size="sm"
                            leftIcon={formData.useGooglePhoto ? <FaCheck /> : undefined}
                            onClick={handleGooglePhotoToggle}
                            colorScheme={formData.useGooglePhoto ? "green" : "gray"}
                          >
                            {formData.useGooglePhoto ? "Using Google Photo" : "Use Google Photo"}
                          </Button>
                        </VStack>

                        {/* Custom Upload Option */}
                        <VStack 
                          p={4}
                          borderRadius="lg"
                          bg={!formData.useGooglePhoto ? "rgba(255, 165, 0, 0.1)" : "transparent"}
                          w="100%"
                        >
                          <Avatar 
                            size="xl"
                            src={newProfilePicture}
                            icon={isImageLoading ? <Spinner /> : undefined}
                          />
                          <Button
                            size="sm"
                            leftIcon={<FaUpload />}
                            onClick={() => fileInputRef.current?.click()}
                            colorScheme={!formData.useGooglePhoto ? "orange" : "gray"}
                          >
                            {newProfilePicture ? "Change Photo" : "Upload Photo"}
                          </Button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileSelect}
                            accept="image/jpeg,image/png,image/gif"
                            style={{ display: 'none' }}
                          />
                        </VStack>
                      </VStack>
                    </Box>
                  )}
                </VStack>

                {/* Right Column - Form Fields */}
                <VStack w="60%" spacing={4}>
                  <SimpleGrid columns={2} spacing={4} w="100%">
                    <FormControl>
                      <FormLabel>First Name</FormLabel>
                      <AnimatedInput name="firstName" value={formData.firstName} onChange={handleChange} />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Last Name</FormLabel>
                      <AnimatedInput name="lastName" value={formData.lastName} onChange={handleChange} />
                    </FormControl>
                  </SimpleGrid>

                  {/* Email Field - Always show but maybe readonly */}
                  <FormControl isRequired={requiredAuth.needsEmail}>
                    <FormLabel>Email Address</FormLabel>
                    <AnimatedInput
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isReadOnly={!requiredAuth.needsEmail}
                      placeholder="Enter your email address"
                    />
                    <FormHelperText>
                      {requiredAuth.needsEmail ? 
                        'Required for account recovery and notifications' : 
                        'Email provided by authentication method'}
                    </FormHelperText>
                  </FormControl>

                  {/* Phone Number Field - Always required */}
                  <FormControl isRequired isInvalid={formValidation.phoneNumber?.isValid === false}>
                    <FormLabel>Phone Number</FormLabel>
                    <AnimatedInput
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      placeholder="+16478356364"
                      isReadOnly={!requiredAuth.needsPhone}
                    />
                    <FormHelperText>
                      Enter your phone number starting with + and country code (e.g., +16473711555)
                    </FormHelperText>
                    {formValidation.phoneNumber?.isValid === false && (
                      <Text color="red.500" fontSize="sm">
                        {formValidation.phoneNumber.message}
                      </Text>
                    )}
                  </FormControl>

                  {/* Password Section - Show if needed */}
                  {requiredAuth.needsPassword && (
                    <VStack w="100%" spacing={4}>
                      <FormControl isRequired>
                        <FormLabel>Set Password</FormLabel>
                        <InputGroup>
                          <AnimatedInput
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Enter a strong password"
                          />
                          <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={() => generatePassword()}>
                              Generate
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <FormHelperText>
                          This will allow you to sign in with email and password
                        </FormHelperText>
                      </FormControl>

                      <FormControl isRequired>
                        <FormLabel>Confirm Password</FormLabel>
                        <AnimatedInput
                          name="confirmPassword"
                          type={showPassword ? "text" : "password"}
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          placeholder="Confirm your password"
                        />
                      </FormControl>
                    </VStack>
                  )}

                  <SimpleGrid columns={2} spacing={4} w="100%">
                    <FormControl>
                      <FormLabel>Organization Name</FormLabel>
                      <AnimatedInput name="organizationName" value={formData.organizationName} onChange={handleChange} />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Role</FormLabel>
                      <Select
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                      >
                        <option value="brand_ambassador">Brand Ambassador</option>
                        <option value="user">User</option>
                      </Select>
                    </FormControl>
                  </SimpleGrid>
                </VStack>
              </HStack>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="outline"
                mr={3}
                onClick={onClose}
                borderColor="#FFA500"
                color="#FFA500"
                _hover={{ bg: 'rgba(255, 165, 0, 0.2)' }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                bg="#FFA500"
                color="black"
                _hover={{ bg: '#FFD700' }}
                isLoading={isSubmitting}
                loadingText="Creating Profile..."
              >
                Confirm & Continue
              </Button>
            </ModalFooter>
          </ModalContentMotion>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default ProfileConfirmationModal; 