import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Box, Flex, Link, Stack, Text, Badge, Popover, PopoverTrigger, PopoverContent, PopoverBody, VStack, IconButton, Button, useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, useBreakpointValue, HStack } from '@chakra-ui/react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { BsBell, BsTelephone, BsChatDots, BsEnvelope, BsSun, BsMoon, BsTrash, BsBoxArrowRight, BsSpeedometer2, BsPeople, BsFileText, BsGraphUp, BsGear, BsHouseDoorFill, BsBug } from 'react-icons/bs';
import { GiHamburgerMenu } from 'react-icons/gi';
// Update this line to import the socket functions
import socket, { connectSocket, disconnectSocket } from '../socket';
import { TwilioContext } from '../context/TwilioContext';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';

const NavMenu = ({ label, children, navTextColor, navBgColor, navHoverBgColor }) => {
  return (
    <Popover trigger="hover" placement="bottom-start">
      <PopoverTrigger>
        <Button
          variant="ghost"
          color={navTextColor}
          _hover={{ bg: navHoverBgColor }}
        >
          {label} ▼
        </Button>
      </PopoverTrigger>
      <PopoverContent
        border="1px solid"
        borderColor="orange.400"
        bg={navBgColor}
        p={2}
        width="200px"
      >
        <PopoverBody>
          <VStack align="stretch" spacing={2}>
            {children}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const FallingEmoji = ({ emoji, x }) => (
  <motion.div
    style={{
      position: 'absolute',
      top: -20,
      left: x,
      fontSize: '20px',
      pointerEvents: 'none',
    }}
    animate={{
      y: [0, 100],
      opacity: [1, 0],
      rotate: [-20, 20]
    }}
    transition={{
      duration: 2,
      ease: "easeOut",
      opacity: { duration: 1.8 }
    }}
  >
    {emoji}
  </motion.div>
);

function NavBar() {
  const [notifications, setNotifications] = useState(() => {
    // Only load notifications from localStorage if they haven't been cleared
    const shouldLoad = localStorage.getItem('notificationsCleared') !== 'true';
    const savedNotifications = localStorage.getItem('notifications');
    return shouldLoad && savedNotifications ? JSON.parse(savedNotifications) : [];
  });
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [audioInitialized, setAudioInitialized] = useState(false);
  const { isDeviceReady, device } = useContext(TwilioContext);
  const { currentUser } = useAuth();
  const { isDark, toggleTheme, currentTheme } = useTheme();

  // Modify this line to include the top padding for mobile
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Add this for the mobile drawer
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  // Add this state
  const [isRendered, setIsRendered] = useState(false);
  const [emojis, setEmojis] = useState([]);

  // Add this effect
  useEffect(() => {
    setIsRendered(true);
  }, []);

  const initializeAudio = useCallback(() => {
    if (!audioInitialized) {
      console.log('User interacted, initializing audio');
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContext.resume().then(() => {
        console.log('AudioContext started');
        setAudioInitialized(true);
        if (device && !isDeviceReady) {
          device.register();
        }
      });
    }
  }, [audioInitialized, device, isDeviceReady]);

  useEffect(() => {
    const handleInteraction = () => {
      initializeAudio();
      // Remove event listeners after initialization
      if (!audioInitialized) {
        document.removeEventListener('click', handleInteraction);
        document.removeEventListener('keydown', handleInteraction);
        document.removeEventListener('touchstart', handleInteraction);
      }
    };

    if (!audioInitialized) {
      document.addEventListener('click', handleInteraction);
      document.addEventListener('keydown', handleInteraction);
      document.addEventListener('touchstart', handleInteraction);
    }

    return () => {
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('keydown', handleInteraction);
      document.removeEventListener('touchstart', handleInteraction);
    };
  }, [audioInitialized, initializeAudio]);

  // Update localStorage whenever notifications change
  useEffect(() => {
    if (notifications.length > 0) {
      localStorage.setItem('notifications', JSON.stringify(notifications));
      localStorage.setItem('notificationsCleared', 'false');
    } else {
      localStorage.removeItem('notifications');
      localStorage.setItem('notificationsCleared', 'true');
    }
  }, [notifications]);

  // Socket connection effect
  useEffect(() => {
    let socketInstance = null;
    
    const initializeSocket = async () => {
      try {
        socketInstance = connectSocket();
        
        if (socketInstance) {
          console.log('🔌 Navbar socket connected');

          const handleNewMessage = async (message) => {
            console.log('📨 Received new message in Navbar:', message);
            
            if (message.direction === 'incoming') {
              try {
                // Fetch lead details from the server
                const response = await fetch(`/api/leads/phone/${message.from}`);
                const leadData = await response.json();
                
                const displayName = leadData.name && leadData.name !== 'Unknown Contact'
                  ? leadData.name
                  : formatPhoneNumber(message.from);

                const newNotification = {
                  leadId: leadData._id || message.leadId,
                  leadName: displayName,
                  message: message.body,
                  timestamp: message.timestamp || new Date().toISOString(),
                  direction: message.direction,
                  type: 'message',
                  from: message.from,
                  isNewLead: !leadData._id
                };

                setNotifications(prev => {
                  const updatedNotifications = [...prev, newNotification];
                  localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
                  localStorage.setItem('notificationsCleared', 'false');
                  return updatedNotifications;
                });
              } catch (error) {
                console.error('Error fetching lead details:', error);
                // Fallback to phone number if lead fetch fails
                const newNotification = {
                  leadId: message.leadId,
                  leadName: formatPhoneNumber(message.from),
                  message: message.body,
                  timestamp: message.timestamp || new Date().toISOString(),
                  direction: message.direction,
                  type: 'message',
                  from: message.from,
                  isNewLead: true
                };

                setNotifications(prev => {
                  const updatedNotifications = [...prev, newNotification];
                  localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
                  localStorage.setItem('notificationsCleared', 'false');
                  return updatedNotifications;
                });
              }
            }
          };

          socketInstance.on('new_message', handleNewMessage);
        }
      } catch (error) {
        console.error('Socket initialization error:', error);
      }
    };

    initializeSocket();

    return () => {
      if (socketInstance) {
        socketInstance.off('new_message');
        disconnectSocket();
      }
    };
  }, []);

  const navTextColor = isDark ? "white" : "gray.800";
  const navBgColor = isDark ? "black" : "white";
  const navHoverBgColor = isDark ? "gray.900" : "gray.100";
  const navIconColor = isDark ? "white" : "gray.800";
  const navIconHoverColor = isDark ? "yellow.500" : "orange.400";

  const NavLink = ({ to, children, isCallScript = false }) => {
    const isActive = location.pathname === to;
    const handleClick = (e) => {
      if (isCallScript) {
        e.preventDefault();
        navigate('/call-script', { state: { startPage: true } });
      }
    };

    return (
      <Link
        as={RouterLink}
        to={to}
        position="relative"
        px={4}
        py={2}
        rounded={isActive ? "none" : "md"}
        fontWeight="medium"
        color={navTextColor}
        _hover={{ bg: navHoverBgColor }}
        transition="all 0.3s ease"
        onClick={handleClick}
        borderWidth={isActive ? "1px" : "0"}
        borderColor={isActive ? "orange.400" : "transparent"}
        borderStyle="solid"
      >
        {children}
      </Link>
    );
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const toggleDarkMode = () => {
    toggleTheme();
  };

  const handleNotificationClick = (notification) => {
    if (notification.type === 'message') {
      navigate('/communications', { state: { leadId: notification.leadId } });
    }
    // Close the popover
    setIsOpen(false);
  };

  const clearNotifications = () => {
    // Clear notifications from state
    setNotifications([]);
    // Clear notifications from localStorage
    localStorage.removeItem('notifications');
    // Close the popover
    setIsOpen(false);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to login page after sign out
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    onDrawerClose(); // Close the drawer after navigation
  };

  const NotificationBell = () => (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      <PopoverTrigger>
        <Box 
          position="relative" 
          cursor="pointer"
          bg={isOpen ? (isDark ? "black" : "gray.100") : "transparent"}
          p={1}
          borderRadius="md"
        >
          <IconButton
            icon={<BsBell />}
            aria-label="Notifications"
            variant="ghost"
            color={navIconColor}
            _hover={{ color: navIconHoverColor }}
            boxSize={8}
          />
          {notifications.length > 0 && (
            <Badge
              position="absolute"
              top="-1px"
              right="-1px"
              bg="red.500"
              borderRadius="full"
              boxSize="4"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              fontSize="xs"
            >
              {notifications.length}
            </Badge>
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent
        border="1px solid"
        borderColor="orange.400"
        bg={navBgColor}
        color={navTextColor}
      >
        <PopoverBody>
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Text fontWeight="bold">Notifications</Text>
            {notifications.length > 0 && (
              <IconButton
                icon={<BsTrash />}
                aria-label="Clear notifications"
                size="sm"
                variant="ghost"
                color="white"
                _hover={{ color: "yellow.500" }}
                onClick={clearNotifications}
              />
            )}
          </Flex>
          <VStack align="stretch" spacing={2}>
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <Box 
                  key={index} 
                  p={2} 
                  bg="gray.800" 
                  borderRadius="md" 
                  onClick={() => handleNotificationClick(notification)}
                  cursor="pointer"
                >
                  <Flex alignItems="center">
                    <BsChatDots style={{ marginRight: '8px' }} />
                    <Text fontWeight="bold">
                      {notification.leadName && notification.leadName !== 'Unknown Contact'
                        ? notification.leadName
                        : formatPhoneNumber(notification.from)}
                    </Text>
                  </Flex>
                  <Text fontSize="sm">{notification.message}</Text>
                  <Text fontSize="xs" color="gray.400">
                    {new Date(notification.timestamp).toLocaleString()}
                  </Text>
                </Box>
              ))
            ) : (
              <Text>No new notifications</Text>
            )}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );

  // Update the DesktopNav component
  const DesktopNav = () => (
    <Flex alignItems="center">
      <HStack spacing={4} mr={4}>
        {/* Add RTPDash2 alongside other dashboard links */}
        <NavLink to="/ffub-dash">FUB Dashboard</NavLink>
        <NavLink to="/fpd-dash">Pipe Dashboard</NavLink>
        <NavLink to="/attio">Attio Dashboard</NavLink>
        <NavLink to="/rtp-dash2">RTP Dashboard 2</NavLink>

        {/* User Links Menu */}
        <NavMenu 
          label="User Links"
          navTextColor={navTextColor}
          navBgColor={navBgColor}
          navHoverBgColor={navHoverBgColor}
        >
          <NavLink to="/dashboard">Dashboard</NavLink>
          <NavLink to="/newhome">Homepage</NavLink>
          <NavLink to="/leads">Leads</NavLink>
          <NavLink to="/call-script" isCallScript={true}>Call Script</NavLink>
          <NavLink to="/calls-log">Calls Log</NavLink>
          <NavLink to="/communications">SMS</NavLink>
          <NavLink to="/landing">Landing</NavLink>
          <NavLink to="/settings">Settings</NavLink>
          <NavLink to="/call-reports">Call Reports</NavLink>
          <NavLink to="/pricing">Pricing</NavLink>
        </NavMenu>

        {/* Admin Links Menu */}
        <NavMenu 
          label="Admin Links"
          navTextColor={navTextColor}
          navBgColor={navBgColor}
          navHoverBgColor={navHoverBgColor}
        >
          <NavLink to="/debugging">Debugging</NavLink>
        </NavMenu>

        {/* Keep the lead button outside the menus */}
        <Button 
          as={RouterLink} 
          to="/leads/671fb01b84ba07345feb5ede" 
          colorScheme="teal" 
          variant="outline" 
          ml={4}
          borderRadius="0" 
          borderWidth="1px"
        >
          Check out this lead
        </Button>
      </HStack>

      {/* Keep the existing action buttons */}
      <HStack spacing={2}>
        <IconButton
          icon={isDark ? <BsSun /> : <BsMoon />}
          aria-label="Toggle dark mode"
          variant="ghost"
          color={navIconColor}
          _hover={{ color: navIconHoverColor }}
          onClick={toggleDarkMode}
        />
        <IconButton
          icon={<BsTelephone />}
          aria-label="Calls Log"
          variant="ghost"
          color={navIconColor}
          _hover={{ color: navIconHoverColor }}
          onClick={() => navigate('/calls-log')}
        />
        <IconButton
          icon={<BsChatDots />}
          aria-label="Communications"
          variant="ghost"
          color={navIconColor}
          _hover={{ color: navIconHoverColor }}
          onClick={() => navigate('/communications')}
        />
        <IconButton
          icon={<BsEnvelope />}
          aria-label="Email"
          variant="ghost"
          color={navIconColor}
          _hover={{ color: navIconHoverColor }}
        />
        <NotificationBell />
        <Button
          leftIcon={<BsBoxArrowRight />}
          onClick={handleSignOut}
          variant="ghost"
          color={navIconColor}
          _hover={{ bg: "orange.400", color: "white" }}
        >
          Sign Out
        </Button>
      </HStack>
    </Flex>
  );

  // Update the NavItems for mobile
  const MobileNavItems = () => (
    <VStack spacing={4} align="stretch" width="100%" px={0}>
      {/* Main Navigation */}
      <VStack spacing={2} align="stretch" borderBottom="1px solid" borderColor="orange.400" pb={4} mx={-4}>
        <Text fontSize="sm" color="gray.400" px={7}>Main Navigation</Text>
        <Button 
          onClick={() => handleNavigation("/ffub-dash")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsSpeedometer2 />}
        >
          FFUB Dashboard
        </Button>
        <Button 
          onClick={() => handleNavigation("/fpd-dash")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsSpeedometer2 />}
        >
          FPD Dashboard
        </Button>
        <Button 
          onClick={() => handleNavigation("/attio")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsSpeedometer2 />}
        >
          Attio Dashboard
        </Button>
        <Button 
          onClick={() => handleNavigation("/rtp-dash2")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsSpeedometer2 />}
        >
          RTP Dashboard 2
        </Button>
        <Button 
          onClick={() => handleNavigation("/dashboard")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsSpeedometer2 />}
        >
          Dashboard
        </Button>
        <Button 
          onClick={() => handleNavigation("/newhome")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsHouseDoorFill />}
        >
          Homepage
        </Button>
        <Button 
          onClick={() => handleNavigation("/leads")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsPeople />}
        >
          Leads
        </Button>
        <Button 
          onClick={() => handleNavigation("/reports")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsGraphUp />}
        >
          General Reports
        </Button>
        <Button 
          onClick={() => handleNavigation("/call-reports")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsFileText />}
        >
          Call Script Reports
        </Button>
      </VStack>

      {/* Communication Tools */}
      <VStack spacing={2} align="stretch" borderBottom="1px solid" borderColor="orange.400" pb={4} mx={-4}>
        <Text fontSize="sm" color="gray.400" px={7}>Communication Tools</Text>
        <Button 
          onClick={() => handleNavigation("/communications")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsChatDots />}
        >
          Communications
        </Button>
        <Button 
          onClick={() => handleNavigation("/calls-log")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsTelephone />}
        >
          Calls Log
        </Button>
        <Button 
          onClick={() => handleNavigation("/call-script")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsEnvelope />}
        >
          Call Script
        </Button>
      </VStack>

      {/* Settings & Account */}
      <VStack spacing={2} align="stretch" mx={-4}>
        <Text fontSize="sm" color="gray.400" px={7}>Settings & Account</Text>
        <Button 
          onClick={() => handleNavigation("/settings")} 
          variant="ghost" 
          color="white" 
          justifyContent="flex-start"
          leftIcon={<BsGear />}
        >
          Settings
        </Button>
        <Button
          leftIcon={<BsBoxArrowRight />}
          onClick={handleSignOut}
          variant="ghost"
          color="white"
          _hover={{ bg: "orange.400" }}
          justifyContent="flex-start"
        >
          Sign Out
        </Button>
      </VStack>

      {/* Add the lead link here for mobile */}
      <Box textAlign="center" mb={4}>
        <Text fontSize="lg" fontWeight="bold">
          Check out this lead:
        </Text>
        <Button 
          as={RouterLink} 
          to="/leads/671fb01b84ba07345feb5ede" 
          colorScheme="teal" 
          mt={2}
          borderRadius="0" 
          borderWidth="1px"
        >
          View Lead
        </Button>
      </Box>
    </VStack>
  );

  // Add these helper functions
  const formatPhoneNumber = (phone) => {
    if (!phone) return 'Unknown Number';
    // Remove any non-digit characters
    const cleaned = phone.replace(/\D/g, '');
    // Format as (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phone; // Return original if can't format
  };

  const addNotificationWithFormattedPhone = (data) => {
    const { leadId, from, body, timestamp, direction } = data;
    setNotifications(prev => {
      const newNotifications = [
        ...prev, 
        { 
          leadId,
          leadName: formatPhoneNumber(from),
          message: body || 'No Content',
          timestamp: timestamp || new Date().toISOString(),
          direction: direction || 'unknown',
          type: 'message',
          from,
        }
      ];
      localStorage.setItem('notifications', JSON.stringify(newNotifications));
      return newNotifications;
    });
  };

  const customizationLink = (
    <NavLink to="/customize">Customize</NavLink>
  );

  const themeToggleButton = (
    <IconButton
      icon={isDark ? <BsSun /> : <BsMoon />}
      aria-label="Toggle theme"
      variant="ghost"
      color={isDark ? "white" : "black"}
      onClick={toggleDarkMode}
    />
  );

  const handleLogoHover = useCallback(() => {
    // Create new emojis
    const newEmojis = Array.from({ length: 5 }, (_, i) => ({
      id: Date.now() + i,
      x: Math.random() * 150
    }));
    
    // Clear any existing timeout
    if (window.emojiTimeout) {
      clearTimeout(window.emojiTimeout);
    }
    
    setEmojis(newEmojis);
    
    // Set new timeout
    window.emojiTimeout = setTimeout(() => {
      setEmojis([]);
    }, 2100); // Slightly longer than animation duration
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (window.emojiTimeout) {
        clearTimeout(window.emojiTimeout);
      }
    };
  }, []);

  return (
    <Box 
      bg={isDark ? 'primaryBg' : 'white'}
      px={4}
      width="100%"
      height={{ base: "65px", md: "64px" }}
      position="fixed"
      top={{ base: "1px", md: "0" }}
      left="0"
      right="0"
      zIndex="1000"
      boxSizing="border-box"
      borderColor={isDark ? "orange.400" : "gray.200"}
      borderWidth="1px"
      transition="background-color 0.2s ease-in-out"
      boxShadow={isDark ? "none" : "0 2px 4px rgba(0,0,0,0.1)"}
    >
      <Flex 
        h="100%" 
        alignItems="center" 
        justifyContent="space-between" 
        px={2}
      >
        <Box position="relative" style={{ pointerEvents: "none" }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            onClick={handleLogoClick}
            onMouseEnter={handleLogoHover}
            style={{ 
              cursor: 'pointer',
              position: 'relative',
              pointerEvents: "auto" // Enable pointer events only for the logo text
            }}
          >
            <Box 
              position="absolute" 
              top={0} 
              left={0} 
              right={0} 
              bottom={0}
              style={{ pointerEvents: "none" }} // Disable pointer events for emoji container
            >
              {emojis.map((emoji) => (
                <FallingEmoji 
                  key={emoji.id} 
                  emoji="💰" 
                  x={emoji.x} 
                />
              ))}
            </Box>
            <Text
              fontSize="xl"
              fontWeight="extrabold"
              bgGradient="linear(to-r, orange.400, orange.600)"
              bgClip="text"
              zIndex={2}
              position="relative"
            >
              🔓 RealTalk Pro
            </Text>
          </motion.div>
        </Box>
        {currentUser && (
          <>
            {isMobile ? (
              <IconButton
                icon={<GiHamburgerMenu />}
                aria-label="Open Menu"
                onClick={onDrawerOpen}
                variant="ghost"
                color="white"
                mr={2}
                _hover={{
                  bg: "orange.400",
                  transform: "scale(1.1)",
                  transition: "all 0.2s",
                }}
              />
            ) : (
              <DesktopNav />
            )}
          </>
        )}
      </Flex>

      {/* Mobile Drawer */}
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay />
        <DrawerContent 
          bg="primaryBg"
          color={isDark ? "white" : "textColor"}
          borderLeft="1px solid"
          borderTop="1px solid"
          borderBottom="1px solid"
          borderRight="1px solid"
          borderColor={isDark ? "orange.400" : "primaryAccent"}
          height="100%"
          mt={{ base: "1px", md: "0" }}
        >
          <DrawerHeader 
            borderBottomWidth="1px" 
            borderColor="orange.400"
            height={{ base: "65px", md: "64px" }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={4}
          >
            <Text 
              bgGradient="linear(to-r, orange.400, yellow.400)"
              bgClip="text"
              fontSize="xl"
              fontWeight="extrabold"
            >
              Menu
            </Text>
            <DrawerCloseButton 
              position="static"
              _hover={{
                bg: "orange.400",
                transform: "rotate(90deg)",
                transition: "all 0.3s",
              }}
            />
          </DrawerHeader>
          <DrawerBody>
            <MobileNavItems />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default NavBar;
