import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
  },
  sidebar: {
    width: '240px',
    backgroundColor: '#ffffff',
    padding: '0',
    borderRight: '1px solid #e0e0e0',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    transition: 'width 0.3s ease, opacity 0.3s ease',
    position: 'relative',
  },
  toggleButton: {
    position: 'absolute',
    right: '-12px',
    top: 'calc(50% + 32px)',
    transform: 'translateY(-50%)',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    color: '#666',
    padding: 0,
  },
  sidebarItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px',
    color: '#333',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  mainContent: {
    flex: 1,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    height: '64px',
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: '#ffffff',
    fontSize: '16px',
    width: '100%',
    boxSizing: 'border-box',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    marginLeft: '16px',
    marginRight: '16px',
  },
  searchInput: {
    flex: '0 0 auto',
    padding: '6px 10px',
    borderRadius: '20px',
    border: '1px solid #ccc',
    fontSize: '14px',
    backgroundColor: '#ffffff',
    color: '#333333',
    width: '300px',
    maxWidth: '300px',
    textAlign: 'center',
  },
  addButton: {
    marginLeft: '8px',
    padding: '6px 12px',
    backgroundColor: '#ffffff',
    color: '#666',
    border: '1px solid #ccc',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sidebarLogo: {
    width: '100%',
    height: '64px',
    borderBottom: '1px solid #e0e0e0',
    fontSize: '24px',
    fontWeight: 'extrabold',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    margin: '0',
    background: 'linear-gradient(to right, #ED8936, #DD6B20)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    userSelect: 'none',
    boxSizing: 'border-box',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: 'justify-content 0.3s ease, padding 0.3s ease, opacity 0.3s ease',
  },
  sidebarLogoCollapsed: {
    justifyContent: 'center',
    padding: '0',
  },
  sidebarLogoExpanded: {
    justifyContent: 'flex-start',
  },
  sidebarIcon: {
    width: '24px',
    display: 'inline-block',
    textAlign: 'center',
    marginRight: '16px',
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  avatarCircle: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: '#ED8936',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: '500',
    marginRight: '8px',
  },
};

const RTPDash2 = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  return (
    <div style={styles.container}>
      <div 
        style={{
          ...styles.sidebar,
          width: isCollapsed ? '64px' : '240px'
        }}
      >
        <button 
          style={styles.toggleButton} 
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? <FaChevronRight size={14} /> : <FaChevronLeft size={14} />}
        </button>

        <div 
          style={{
            ...styles.sidebarLogo,
            ...(isCollapsed ? styles.sidebarLogoCollapsed : styles.sidebarLogoExpanded)
          }} 
          title="RealTalkPro"
        >
          {isCollapsed ? 'R' : 'RealTalkPro'}
        </div>

        <div>
          <div style={styles.sidebarItem}>
            <span style={styles.sidebarIcon}>🏢</span>
            <span style={{ opacity: isCollapsed ? 0 : 1, transition: 'opacity 0.3s ease' }}>
              Companies
            </span>
          </div>
          <div style={styles.sidebarItem}>
            <span style={styles.sidebarIcon}>👤</span>
            <span style={{ opacity: isCollapsed ? 0 : 1, transition: 'opacity 0.3s ease' }}>
              People
            </span>
          </div>
          <div style={styles.sidebarItem}>
            <span style={styles.sidebarIcon}>📊</span>
            <span style={{ opacity: isCollapsed ? 0 : 1, transition: 'opacity 0.3s ease' }}>
              Pipelines
            </span>
          </div>
          <div style={styles.sidebarItem}>
            <span style={styles.sidebarIcon}>💵</span>
            <span style={{ opacity: isCollapsed ? 0 : 1, transition: 'opacity 0.3s ease' }}>
              Deals
            </span>
          </div>
        </div>

        <div style={{ flex: 1 }}></div>

        <div>
          <div style={styles.sidebarItem}>
            <span style={styles.sidebarIcon}>🧭</span>
            <span style={{ opacity: isCollapsed ? 0 : 1, transition: 'opacity 0.3s ease' }}>
              Call Scripts
            </span>
          </div>
          <div style={styles.sidebarItem}>
            <span style={styles.sidebarIcon}>⚡</span>
            <span style={{ opacity: isCollapsed ? 0 : 1, transition: 'opacity 0.3s ease' }}>
              Automations
            </span>
          </div>
        </div>

        <div style={{ flex: 1 }}></div>

        <div>
          <div style={styles.sidebarItem}>
            <span style={styles.sidebarIcon}>📞</span>
            <span style={{ opacity: isCollapsed ? 0 : 1, transition: 'opacity 0.3s ease' }}>
              Phone
            </span>
          </div>
          <div style={styles.sidebarItem}>
            <span style={styles.sidebarIcon}>💭</span>
            <span style={{ opacity: isCollapsed ? 0 : 1, transition: 'opacity 0.3s ease' }}>
              Messages
            </span>
          </div>
          <div style={styles.sidebarItem}>
            <span style={styles.sidebarIcon}>✉️</span>
            <span style={{ opacity: isCollapsed ? 0 : 1, transition: 'opacity 0.3s ease' }}>
              Email
            </span>
          </div>
        </div>
      </div>

      <div style={styles.mainContent}>
        <div style={styles.topBar}>
          <div style={{ color: '#555', fontWeight: 'normal' }}>Dashboard</div>
          <div style={styles.searchContainer}>
            <input 
              type="text" 
              placeholder="Search companies, contacts, or deals..." 
              style={styles.searchInput}
              className="search-input"
            />
            <button style={styles.addButton}>
              <span style={{ marginTop: '-2px' }}>+</span>
            </button>
          </div>
          <div style={styles.profileContainer}>
            <span>🔔</span>
            <div style={styles.profileInfo}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={styles.avatarCircle}>SB</div>
              </div>
            </div>
          </div>
        </div>

        <div style={{padding: '24px'}}>
          {/* Add your content here */}
        </div>
      </div>
    </div>
  );
};

export default RTPDash2; 