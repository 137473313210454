// frontend/src/context/AuthContext.js

// 🌟 MASTER AUTH CONTEXT FILE
// This is the brain of authentication in your React app! 

// 🎯 PURPOSE:
// - Creates a central place to manage user authentication state
// - Shares auth info with all components that need it
// - Handles Firebase auth lifecycle and token management

// 🔑 KEY FEATURES:
// 1. Manages login state (hasUser, isNewUser, etc)
// 2. Auto-refreshes Firebase tokens every 10 mins
// 3. Syncs with backend through /api/auth/verify
// 4. Provides auth data to child components

// 🏗️ STATE STRUCTURE:
// {
//   hasUser: boolean,        // Is someone logged in?
//   isNewUser: boolean,      // First time user?
//   loading: boolean,        // Still checking auth?
//   currentUser: object,     // Firebase user object
//   lastError: string,       // Last auth error
//   profileComplete: boolean // Has completed profile?
// }

// 🎨 USAGE EXAMPLE:
// import { useAuth } from '../context/AuthContext';
// 
// function MyComponent() {
//   const { currentUser, isNewUser } = useAuth();
//   // Now you can use auth info!
// }

// 💡 DEBUGGING TIPS:
// - Check console for " Auth state updated" logs
// - Watch "🔄 AuthContext State Updated" for state changes
// - Token refresh happens every 10 minutes

import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { 
  getAuth,
  onAuthStateChanged as firebaseAuthStateChanged,
  signOut,
  browserLocalPersistence,
  browserSessionPersistence,
  getIdToken 
} from 'firebase/auth';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Create the AuthContext
const AuthContext = createContext(null);

// Custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [state, setState] = useState({
    hasUser: false,
    isNewUser: false,
    loading: true,
    currentUser: null,
    lastError: null,
    profileComplete: false
  });

  // Add session warning state
  const [sessionWarningShown, setSessionWarningShown] = useState(false);
  
  // Add error state and handler
  const [authError, setAuthError] = useState(null);

  const handleError = (error, context) => {
    console.error(`Auth Error (${context}):`, error);
    setAuthError(error);
    logAuthEvent('auth_error', { 
      context, 
      error: error.message 
    });
    
    // Show user-friendly error message
    toast.error(
      error.code === 'auth/network-request-failed'
        ? 'Network error. Please check your connection.'
        : 'Authentication error. Please try again.'
    );
  };

  // Enhanced token refresh with pre-emptive warning
  const handleTokenRefresh = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const decodedToken = await user.getIdTokenResult();
        const expirationTime = new Date(decodedToken.expirationTime).getTime();
        const timeToExpire = expirationTime - Date.now();
        
        // Show warning 5 minutes before expiration
        if (timeToExpire < 300000 && !sessionWarningShown) {
          toast.warning(
            <div className="session-warning">
              <p>Your session will expire in 5 minutes.</p>
              <div className="button-group">
                <button 
                  onClick={handleTokenRefresh}
                  className="stay-logged-in"
                >
                  Stay Logged In
                </button>
                <button 
                  onClick={() => handleSignOut()}
                  className="dismiss"
                >
                  Sign Out
                </button>
              </div>
            </div>,
            {
              position: "top-right",
              autoClose: 300000, // 5 minutes
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            }
          );
          setSessionWarningShown(true);
        }

        // Refresh token if less than 2 minutes remaining
        if (timeToExpire < 120000) {
          const newToken = await user.getIdToken(true);
          axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          setSessionWarningShown(false);
          
          // Log refresh event
          logAuthEvent('token_refresh', { userId: user.uid });
        }
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      logAuthEvent('token_refresh_failed', { error: error.message });
      toast.error('Session expired. Please log in again.');
      await signOut(auth);
    }
  };

  // Add auth event logging
  const logAuthEvent = async (eventType, details = {}) => {
    try {
      await axios.post('/api/analytics/auth-events', {
        eventType,
        timestamp: new Date().toISOString(),
        ...details
      });
    } catch (error) {
      console.error('Failed to log auth event:', error);
    }
  };

  // Enhanced sign out with logging
  const handleSignOut = async () => {
    try {
      const userId = auth.currentUser?.uid;
      await signOut(auth);
      logAuthEvent('user_signout', { userId });
      toast.info('You have been signed out successfully');
    } catch (error) {
      console.error('Sign out error:', error);
      toast.error('Error signing out');
    }
  };

  // Update auth state change listener with persistence
  useEffect(() => {
    const persistedAuth = localStorage.getItem('authState');
    if (persistedAuth) {
      const authData = JSON.parse(persistedAuth);
      setState(prev => ({
        ...prev,
        hasUser: true,
        currentUser: authData,
        loading: false,
        profileComplete: true
      }));
    }

    const unsubscribe = firebaseAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idToken = await user.getIdToken(true);
          axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
          
          const verifyResponse = await axios.post('/api/auth/verify');
          
          // Store auth state in localStorage
          localStorage.setItem('authState', JSON.stringify(user));
          
          setState(prev => ({
            ...prev,
            hasUser: true,
            isNewUser: !verifyResponse.data.user.exists,
            profileComplete: verifyResponse.data.user.exists,
            loading: false,
            currentUser: user
          }));

          logAuthEvent('user_signin', { 
            userId: user.uid,
            isNewUser: !verifyResponse.data.user.exists 
          });
          
        } catch (error) {
          console.error('Auth state change error:', error);
          logAuthEvent('auth_error', { error: error.message });
          
          if (error.response?.status === 401) {
            toast.error('Session expired. Please log in again.');
            await handleSignOut();
          }
        }
      } else {
        localStorage.removeItem('authState');
        delete axios.defaults.headers.common['Authorization'];
        setState(prev => ({
          ...prev,
          hasUser: false,
          isNewUser: false,
          loading: false,
          currentUser: null,
          profileComplete: false
        }));
      }
    });

    return () => unsubscribe();
  }, []);

  // Add remember me toggle function
  const toggleRememberMe = (value) => {
    localStorage.setItem('rememberMe', value);
  };

  // Add debug logging for state changes
  useEffect(() => {
    console.log('🔄 AuthContext State Updated:', {
      hasUser: state.hasUser,
      isNewUser: state.isNewUser,
      loading: state.loading,
      currentUser: state.currentUser ? {
        uid: state.currentUser.uid,
        email: state.currentUser.email
      } : null,
      lastError: state.lastError
    });
  }, [state]);

  // Set up token refresh interval (every 10 minutes)
  useEffect(() => {
    const refreshInterval = setInterval(handleTokenRefresh, 600000);
    
    return () => clearInterval(refreshInterval);
  }, []);

  const value = {
    currentUser: state.currentUser,
    isNewUser: state.isNewUser,
    loading: state.loading,
    isAuthorized: state.hasUser,
    profileComplete: state.profileComplete,
    lastError: state.lastError,
    handleSignOut,
    toggleRememberMe
  };

  return (
    <AuthContext.Provider value={value}>
      {!state.loading && children}
    </AuthContext.Provider>
  );
};
