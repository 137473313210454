// frontend/src/App.js

import React, { useCallback, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useLocation } from 'react-router-dom';
import { ChakraProvider, Box, Flex, Spinner } from '@chakra-ui/react';
import Dashboard from './pages/Dashboard';
import LeadManagement from './pages/LeadManagement';
import CallScript from './pages/CallScript';
import NavBar from './components/Navbar';
import theme from './theme.js.old.delete';
import LeadDetails from './pages/LeadDetails';
import Communications from './pages/Communications';
import CallsLogPage from './pages/CallsLogPage';
import IncomingCallNotification from './components/IncomingCallNotification';
import IncomingSmsNotification from './components/IncomingSmsNotification';
import Settings from './pages/SettingsPage';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import { TwilioProvider } from './context/TwilioContext';
import { connectSocket, disconnectSocket } from './socket';
import { AuthProvider, useAuth } from './context/AuthContext';
import { StyleSheetManager } from 'styled-components';
import CallReports from './pages/CallReports';
import ErrorBoundary from './components/ErrorBoundary';
import LandingPage from './pages/LandingPage';
import LogRocket from 'logrocket';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import CustomizationPage from './pages/CustomizationPage';
import NewHomePage from './pages/NewHomePage';
import { AnimatePresence } from 'framer-motion';
import PricingPage from './pages/PricingPage';
import Debugging from './pages/Debugging';
import FFUBDash from './pages/FFUBDash';
import FPDDash from './pages/FPDDash';
import FAttio from './pages/FAttio';
import RTPDash2 from './pages/RTPDash2';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Update LogRocket initialization with error handling
try {
  LogRocket.init('9r0rwk/real-talk-pro');
} catch (error) {
  console.error('LogRocket initialization failed:', error);
}

// Create a PrivateRoute component that handles new users
const PrivateRoute = () => {
  const { currentUser, loading, isNewUser, profileComplete } = useAuth();
  const location = useLocation();
  
  if (loading) {
    return <div>Loading...</div>;
  }

  // If user is logged in but profile is not complete, redirect to login
  if (currentUser && !profileComplete && location.pathname !== '/login') {
    console.log('🔄 Redirecting to login for profile completion');
    return <Navigate to="/login" state={{ from: location }} />;
  }
  
  // If user is logged in and profile is complete, allow access to protected routes
  if (currentUser && profileComplete) {
    return <Outlet />;
  }

  // If no user, redirect to login
  return <Navigate to="/login" state={{ from: location }} />;
};

// Move the App component inside AppWithProviders
const AppWithProviders = () => {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>
          <AppContent />
        </ThemeProvider>
      </AuthProvider>
      <ToastContainer />
    </Router>
  );
};

// Rename App to AppContent and keep its implementation
const AppContent = () => {
  const { currentTheme, showBorders, isDark } = useTheme();
  const { currentUser, loading, isNewUser, profileComplete } = useAuth();
  const location = useLocation();
  
  // Determine if the current route is FFUBDash
  const isSpecialDash = location.pathname === '/ffub-dash' || 
                        location.pathname === '/fpd-dash' || 
                        location.pathname === '/attio' ||
                        location.pathname === '/rtp-dash2';

  // Determine if the current route is RTPDash2
  const isRTPDash2 = location.pathname === '/rtp-dash2';

  const handleCallStatusChange = useCallback((status) => {
    console.log('Call status changed:', status);
  }, []);

  React.useEffect(() => {
    connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <StyleSheetManager shouldForwardProp={(prop) => prop !== 'theme'}>
      <ChakraProvider theme={isRTPDash2 ? undefined : currentTheme}>
        <AnimatePresence mode="wait">
          <TwilioProvider>
            <Flex 
              direction="column" 
              height="100vh"
              className="theme-transition"
              overflow="hidden"
              position="relative"
              border={showBorders && !isRTPDash2 ? "1px solid" : "none"}
              borderColor={currentTheme.colors.primaryAccent}
            >
              {!isSpecialDash && (
                <>
                  <Box
                    position="fixed"
                    top={0}
                    left={0}
                    right={0}
                    height="1px"
                    bg="orange.400"
                    zIndex={1001}
                    sx={{
                      '@supports (padding-top: env(safe-area-inset-top))': {
                        paddingTop: 'env(safe-area-inset-top)',
                      }
                    }}
                  />
                  <Box
                    position="fixed"
                    bottom={0}
                    left={0}
                    right={0}
                    height="1px"
                    bg="orange.400"
                    zIndex={1001}
                  />
                  <Box
                    position="fixed"
                    top={0}
                    left={0}
                    width="1px"
                    height="100vh"
                    bg="orange.400"
                    zIndex={1001}
                  />
                  <Box
                    position="fixed"
                    top={0}
                    right={0}
                    width="1px"
                    height="100vh"
                    bg="orange.400"
                    zIndex={1001}
                  />
                </>
              )}

              {/* Conditionally render NavBar */}
              {currentUser && !isSpecialDash && <NavBar />}
              {currentUser && <IncomingCallNotification onCallStatusChange={handleCallStatusChange} />}
              {currentUser && <IncomingSmsNotification />}
              <Box 
                flex="1" 
                mt={currentUser && !isSpecialDash ? "64px" : "0"}
                overflow="auto"
                display="flex"
                flexDirection="column"
                position="relative"
                className="font-body"
              >
                <Routes location={location} key={location.pathname}>
                  {/* Landing page route */}
                  <Route 
                    path="/" 
                    element={
                      currentUser && profileComplete ? (
                        <Navigate to="/dashboard" />
                      ) : currentUser && !profileComplete ? (
                        <Navigate to="/login" />
                      ) : (
                        <LandingPage />
                      )
                    } 
                  />
                  
                  {/* Login route with special handling for new users */}
                  <Route 
                    path="/login" 
                    element={
                      !currentUser ? (
                        <Login />
                      ) : !profileComplete ? (
                        <Login />
                      ) : (
                        <Navigate to="/dashboard" />
                      )
                    } 
                  />
                  
                  {/* Private routes */}
                  <Route element={<PrivateRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/ffub-dash" element={<FFUBDash />} />
                    <Route path="/fpd-dash" element={<FPDDash />} />
                    <Route path="/attio" element={<FAttio />} />
                    <Route path="/newhome" element={<NewHomePage />} />
                    <Route path="/leads" element={<LeadManagement />} />
                    <Route path="/leads/:id" element={<LeadDetails />} />
                    <Route path="/call-script" element={<CallScript />} />
                    <Route path="/communications" element={<Communications />} />
                    <Route path="/lead/:id" element={<LeadDetails />} />
                    <Route path="/calls-log" element={<CallsLogPage />} />
                    <Route path="/call-reports" element={<CallReports />} />
                    <Route 
                      path="/settings" 
                      element={
                        <ErrorBoundary>
                          <Settings />
                        </ErrorBoundary>
                      } 
                    />
                    <Route path="/rtp-dash2" element={<RTPDash2 />} />
                  </Route>

                  <Route path="/customize" element={<CustomizationPage />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route path="/debugging" element={<Debugging />} />

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Box>
            </Flex>
          </TwilioProvider>
        </AnimatePresence>
      </ChakraProvider>
    </StyleSheetManager>
  );
}

export default AppWithProviders;
