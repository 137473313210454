import React, { useState, useEffect } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Select } from '@chakra-ui/react';
import { websiteSignupVariants } from '../components/callScriptComponents/CallFlowData';
import axios from 'axios';

const CallReports = () => {
  const [reports, setReports] = useState({
    totalCalls: 0,
    variantStats: {},
    timeRange: '7days'
  });

  useEffect(() => {
    fetchCallStats(reports.timeRange);
  }, [reports.timeRange]);

  const fetchCallStats = async (timeRange) => {
    try {
      const response = await axios.get(`/api/call-reports?timeRange=${timeRange}`);
      setReports(response.data);
    } catch (error) {
      console.error('Error fetching call statistics:', error);
    }
  };

  return (
    <Box p={4}>
      <Heading mb={4}>Call Script Performance Reports</Heading>
      
      <Select
        value={reports.timeRange}
        onChange={(e) => setReports(prev => ({ ...prev, timeRange: e.target.value }))}
        mb={4}
      >
        <option value="7days">Last 7 Days</option>
        <option value="30days">Last 30 Days</option>
        <option value="90days">Last 90 Days</option>
      </Select>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Script Variant</Th>
            <Th>Total Calls</Th>
            <Th>Success Rate</Th>
            <Th>Avg. Call Duration</Th>
            <Th>Conversion Rate</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(websiteSignupVariants).map(([key, variant]) => (
            <Tr key={variant.id}>
              <Td>{variant.description}</Td>
              <Td>{reports.variantStats[variant.id]?.totalCalls || 0}</Td>
              <Td>{reports.variantStats[variant.id]?.successRate || '0%'}</Td>
              <Td>{reports.variantStats[variant.id]?.avgDuration || '0:00'}</Td>
              <Td>{reports.variantStats[variant.id]?.conversionRate || '0%'}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CallReports; 