// src/pages/LeadManagement.js
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Flex,
  VStack,
  HStack,
  Heading,
  Button,
  Input,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Textarea,
  Checkbox,
  Grid,
  GridItem,
  Text,
  List,
  ListItem,
  Stack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
  FaPlus,
  FaPen,
  FaEye,
  FaTrash,
  FaTimes,
  FaPhone,
  FaUsers,
  FaUserAlt,
  FaSms,
} from 'react-icons/fa';
import { AddIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Device } from '@twilio/voice-sdk';
import { API_BASE_URL } from '../config';
import io from 'socket.io-client';

const MotionBox = motion(Box);

/**
 * ViewLeadModal Component
 * Handles viewing and editing of a lead.
 */
const ViewLeadModal = ({ lead, isOpen, onClose, onSave }) => {
  const [editedLead, setEditedLead] = useState(lead);
  const toast = useToast();
  const [currentNotes, setCurrentNotes] = useState([]);

  useEffect(() => {
    if (lead) {
      setEditedLead(lead);
      fetchNotes(lead._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  const fetchNotes = async (leadId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/notes/lead/${leadId}`);
      setCurrentNotes(response.data);
    } catch (error) {
      console.error('Error fetching notes:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch notes.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddNote = async () => {
    const noteContent = prompt('Enter note content:');
    if (!noteContent) return;

    try {
      const response = await axios.post(`${API_BASE_URL}/api/notes`, {
        content: noteContent,
        createdBy: 'User', // Replace with actual user info if available
        leadIds: [lead._id],
      });

      setCurrentNotes([...currentNotes, response.data]);
      toast({
        title: 'Note Added',
        description: 'A new note has been added.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding note:', error);
      toast({
        title: 'Error',
        description: 'Failed to add note.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedLead((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (name) => {
    setEditedLead((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const glowingBorderStyle = {
    transition: 'all 0.3s ease-in-out',
    _hover: {
      borderColor: 'yellow.300',
      boxShadow: '0 0 10px #ffd700',
    },
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent bg="gray.800" color="white" borderColor="orange.400" borderWidth="1px">
        <ModalHeader>View/Edit Lead: {lead.name}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            <GridItem colSpan={1}>
              <VStack align="stretch" spacing={4}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <MotionBox {...glowingBorderStyle}>
                    <Input
                      name="name"
                      value={editedLead.name}
                      onChange={handleInputChange}
                      borderColor="orange.400"
                    />
                  </MotionBox>
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <MotionBox {...glowingBorderStyle}>
                    <Input
                      name="email"
                      value={editedLead.email}
                      onChange={handleInputChange}
                      borderColor="orange.400"
                    />
                  </MotionBox>
                </FormControl>
                <FormControl>
                  <FormLabel>Phone</FormLabel>
                  <MotionBox {...glowingBorderStyle}>
                    <Input
                      name="phone"
                      value={editedLead.phone}
                      onChange={handleInputChange}
                      borderColor="orange.400"
                    />
                  </MotionBox>
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <MotionBox {...glowingBorderStyle}>
                    <Select
                      name="status"
                      value={editedLead.status}
                      onChange={handleInputChange}
                      borderColor="orange.400"
                    >
                      <option value="New">New</option>
                      <option value="Follow Up">Follow Up</option>
                      <option value="Qualified">Qualified</option>
                      <option value="Lost">Lost</option>
                    </Select>
                  </MotionBox>
                </FormControl>
              </VStack>
            </GridItem>
            <GridItem colSpan={1}>
              <VStack align="stretch" spacing={4}>
                <FormControl>
                  <FormLabel>Location</FormLabel>
                  <Input
                    name="location"
                    value={editedLead.location}
                    onChange={handleInputChange}
                    borderColor="orange.400"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Budget</FormLabel>
                  <Input
                    name="budget"
                    type="number"
                    value={editedLead.budget}
                    onChange={handleInputChange}
                    borderColor="orange.400"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Timeline</FormLabel>
                  <Input
                    name="timeline"
                    value={editedLead.timeline}
                    onChange={handleInputChange}
                    borderColor="orange.400"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Call Booked</FormLabel>
                  <Input
                    name="callBooked"
                    value={editedLead.callBooked}
                    onChange={handleInputChange}
                    borderColor="orange.400"
                  />
                </FormControl>
              </VStack>
            </GridItem>
            <GridItem colSpan={1}>
              <VStack align="stretch" spacing={4}>
                <FormControl>
                  <FormLabel>Bucket</FormLabel>
                  <Select
                    name="bucket"
                    value={editedLead.bucket}
                    onChange={handleInputChange}
                    borderColor="orange.400"
                  >
                    <option value="A">Bucket A 🔥 Hot Lead (60 days)</option>
                    <option value="B">Bucket B 🪴 Nurture (60-120 days)</option>
                    <option value="C">Bucket C 🔭 Watch Lead (120+ days)</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Lead Type</FormLabel>
                  <Select
                    name="leadType"
                    value={editedLead.leadType}
                    onChange={handleInputChange}
                    borderColor="orange.400"
                  >
                    <option value="buyer">Buyer</option>
                    <option value="seller">Seller</option>
                    <option value="renter">Renter</option>
                    <option value="landlord">Landlord</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Added Date</FormLabel>
                  <Input
                    name="addedDate"
                    type="date"
                    value={editedLead.addedDate ? editedLead.addedDate.split('T')[0] : ''}
                    onChange={handleInputChange}
                    borderColor="orange.400"
                  />
                </FormControl>
              </VStack>
            </GridItem>
            <GridItem colSpan={3}>
              <FormControl>
                <FormLabel>Notes</FormLabel>
                <Textarea
                  name="notes"
                  value={editedLead.notes}
                  onChange={handleInputChange}
                  placeholder="Enter any relevant information about the lead, including property details..."
                  rows={6}
                  borderColor="orange.400"
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={3}>
              <HStack spacing={6}>
                <Checkbox
                  name="motivation"
                  isChecked={editedLead.motivation}
                  onChange={() => handleCheckboxChange('motivation')}
                >
                  Motivation
                </Checkbox>
                <Checkbox
                  name="ability"
                  isChecked={editedLead.ability}
                  onChange={() => handleCheckboxChange('ability')}
                >
                  Ability
                </Checkbox>
                <Checkbox
                  name="willingness"
                  isChecked={editedLead.willingness}
                  onChange={() => handleCheckboxChange('willingness')}
                >
                  Willingness
                </Checkbox>
              </HStack>
            </GridItem>
          </Grid>

          {/* Display Existing Notes */}
          <Box mt={4}>
            <Heading size="md">Notes</Heading>
            <List spacing={3}>
              {currentNotes.map((note) => (
                <ListItem key={note._id}>
                  <Box p={3} bg="gray.700" borderRadius="md">
                    <Text>{note.content}</Text>
                    <Text fontSize="sm" color="gray.400">
                      {new Date(note.createdAt).toLocaleString()} {note.createdBy && `by ${note.createdBy}`}
                    </Text>
                  </Box>
                </ListItem>
              ))}
            </List>
            <Button mt={4} onClick={handleAddNote} colorScheme="green" leftIcon={<AddIcon />}>
              Add Note
            </Button>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => onSave(editedLead)}>
            Save Changes
          </Button>
          <Button colorScheme="orange" variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

/**
 * LeadManagement Component
 * Handles the overall lead management functionalities.
 */
function LeadManagement() {
  const navigate = useNavigate();
  const [deletedLead, setDeletedLead] = useState(null);
  const [showUndoButton, setShowUndoButton] = useState(false);
  const toast = useToast();
  const [leads, setLeads] = useState([]);
  const [newLeadAdded, setNewLeadAdded] = useState(false);
  const [isNewLeadModalOpen, setIsNewLeadModalOpen] = useState(false);
  const [newLeadData, setNewLeadData] = useState({
    name: '',
    email: '',
    phone: '',
    status: 'New', // Changed from 'new' to 'New'
    motivation: false,
    ability: false,
    willingness: false,
    location: '',
    notes: '',
    budget: '',
    timeline: '',
    callBooked: '',
    bucket: '',
    addedDate: new Date().toISOString().split('T')[0],
    leadType: '',
    clientType: '',
    leadSource: '',
    job: '',
    callOption: 'direct',
    notes: [], // Initialize as empty array
    calls: [], // Initialize as empty array
    smsLogs: [], // Initialize as empty array
    activities: [], // Initialize as empty array
    addedDate: new Date().toISOString().split('T')[0]
  });
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filterConfig, setFilterConfig] = useState({
    name: '',
    email: '',
    status: '',
    addedDateStart: '',
    addedDateEnd: '',
    motivation: '',
    ability: '',
    willingness: '',
    leadType: '',
    clientType: '',
    leadSource: '',
  });

  // State to manage active call
  const [isCallActive, setIsCallActive] = useState(false);
  const [activeCall, setActiveCall] = useState(null); // { callSid, leadId, leadName }
  const [device, setDevice] = useState(null);
  const [call, setCall] = useState(null);
  const [isMuted, setIsMuted] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [viewingLead, setViewingLead] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState([]);

  // Add this state if not already present
  const [editingLead, setEditingLead] = useState(null);

  /**
   * Fetch Leads from the API
   */
  const fetchLeads = useCallback(async () => {
    const logPrefix = '[LeadManagement/fetchLeads]';
    console.time(`${logPrefix} Execution Time`);
    
    try {
      console.group(`${logPrefix} Fetching Leads`);
      console.log('🚀 Initiating API request...');
      
      const response = await axios.get(`${API_BASE_URL}/api/leads`);
      console.log('✅ API Response received:', {
        status: response.status,
        statusText: response.statusText,
        dataType: typeof response.data,
        hasData: Boolean(response.data)
      });

      let leadsData = [];
      
      // Data structure validation with detailed logging
      console.group('Data Structure Validation');
      if (Array.isArray(response.data)) {
        console.log('📊 Direct array structure detected');
        leadsData = response.data;
      } else if (response.data?.leads && Array.isArray(response.data.leads)) {
        console.log('📊 Nested leads array structure detected');
        leadsData = response.data.leads;
      } else if (response.data?.data?.leadsList && Array.isArray(response.data.data.leadsList)) {
        console.log('📊 Deep nested leadsList structure detected');
        leadsData = response.data.data.leadsList;
      } else {
        console.warn('⚠️ Unexpected data structure:', {
          hasData: Boolean(response.data),
          dataType: typeof response.data,
          keys: response.data ? Object.keys(response.data) : null
        });
        throw new Error('Unexpected response format');
      }
      console.groupEnd();

      // Data validation
      const validationResults = leadsData.map((lead, index) => ({
        index,
        id: lead._id,
        isValid: typeof lead === 'object' && lead !== null,
        hasRequiredFields: Boolean(lead._id && lead.name),
        fields: Object.keys(lead)
      }));

      const invalidLeads = validationResults.filter(result => !result.isValid || !result.hasRequiredFields);
      if (invalidLeads.length > 0) {
        console.warn('⚠️ Invalid leads detected:', invalidLeads);
        throw new Error(`${invalidLeads.length} invalid leads found in response`);
      }

      // Sort and set leads
      leadsData.sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate));
      setLeads(leadsData);

      // Lead type analysis
      const leadTypeAnalysis = leadsData.reduce((acc, lead) => {
        acc.leadTypes[lead.leadType || 'unspecified'] = (acc.leadTypes[lead.leadType || 'unspecified'] || 0) + 1;
        acc.clientTypes[lead.clientType || 'unspecified'] = (acc.clientTypes[lead.clientType || 'unspecified'] || 0) + 1;
        return acc;
      }, { leadTypes: {}, clientTypes: {} });

      console.log('📈 Lead Analysis:', {
        totalLeads: leadsData.length,
        leadTypes: leadTypeAnalysis.leadTypes,
        clientTypes: leadTypeAnalysis.clientTypes
      });

      console.groupEnd();

    } catch (error) {
      console.group(`${logPrefix} Error Handler`);
      console.error('🔥 Error Details:', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        response: error.response ? {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data
        } : 'No response',
        request: error.request ? {
          method: error.request.method,
          url: error.request.url,
          headers: error.request.headers
        } : 'No request'
      });
      console.groupEnd();

      setLeads([]);

      toast({
        title: 'Error Fetching Leads',
        description: `${error.message}. Please try again or contact support if the issue persists.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      console.timeEnd(`${logPrefix} Execution Time`);
    }
  }, [toast]);

  useEffect(() => {
    console.log('Calling fetchLeads...');
    fetchLeads();
  }, [fetchLeads]);

  /**
   * Apply Filters and Sorting to Leads
   */
  const applyFiltersAndSort = useCallback(() => {
    console.log('Applying filters and sort. Current leads:', leads);
    
    // Ensure leads is an array
    if (!Array.isArray(leads)) {
      console.warn('Leads is not an array:', leads);
      return;
    }

    let result = [...leads];

    // Apply filters
    result = result.filter(
      (lead) =>
        lead.name.toLowerCase().includes(filterConfig.name.toLowerCase()) &&
        lead.email.toLowerCase().includes(filterConfig.email.toLowerCase()) &&
        (filterConfig.status === '' || lead.status === filterConfig.status) &&
        (filterConfig.addedDateStart === '' ||
          new Date(lead.addedDate) >= new Date(filterConfig.addedDateStart)) &&
        (filterConfig.addedDateEnd === '' ||
          new Date(lead.addedDate) <= new Date(filterConfig.addedDateEnd)) &&
        (filterConfig.motivation === '' ||
          lead.motivation === (filterConfig.motivation === 'true')) &&
        (filterConfig.ability === '' || lead.ability === (filterConfig.ability === 'true')) &&
        (filterConfig.willingness === '' ||
          lead.willingness === (filterConfig.willingness === 'true')) &&
        (filterConfig.leadType === '' || lead.leadType === filterConfig.leadType) &&
        (filterConfig.clientType === '' || lead.clientType === filterConfig.clientType) &&
        (filterConfig.leadSource === '' || lead.leadSource === filterConfig.leadSource)
    );

    // Apply sorting
    if (sortConfig.key === 'addedDate') {
      result.sort((a, b) => {
        const dateA = new Date(a.addedDate);
        const dateB = new Date(b.addedDate);
        return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
      });
    } else if (sortConfig.key) {
      result.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    console.log('Filtered and sorted leads:', result);
    setFilteredLeads(result);
  }, [leads, filterConfig, sortConfig]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [applyFiltersAndSort]);

  /**
   * Handle Sorting
   */
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  /**
   * Handle Filter Changes
   */
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterConfig((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * Clear All Filters
   */
  const clearFilters = () => {
    setFilterConfig({
      name: '',
      email: '',
      status: '',
      addedDateStart: '',
      addedDateEnd: '',
      motivation: '',
      ability: '',
      willingness: '',
      leadType: '',
      clientType: '',
      leadSource: '',
    });
  };

  /**
   * Open and Close New Lead Modal
   */
  const openNewLeadModal = () => {
    setIsNewLeadModalOpen(true);
  };

  const closeNewLeadModal = () => {
    setIsNewLeadModalOpen(false);
    setNewLeadData({
      name: '',
      email: '',
      phone: '',
      status: 'New', // Changed from 'new' to 'New'
      motivation: false,
      ability: false,
      willingness: false,
      location: '',
      notes: '',
      budget: '',
      timeline: '',
      callBooked: '',
      bucket: '',
      addedDate: new Date().toISOString().split('T')[0],
      leadType: '',
      clientType: '',
      leadSource: '',
      job: '',
      callOption: 'direct',
      notes: [], // Initialize as empty array
      calls: [], // Initialize as empty array
      smsLogs: [], // Initialize as empty array
      activities: [], // Initialize as empty array
      addedDate: new Date().toISOString().split('T')[0]
    });
  };

  /**
   * Handle New Lead Input Changes
   */
  const handleNewLeadInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewLeadData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateForm = () => {
    if (!newLeadData.name?.trim()) {
      toast({
        title: 'Validation Error',
        description: 'Name is required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    if (newLeadData.email && !newLeadData.email.includes('@')) {
      toast({
        title: 'Validation Error',
        description: 'Please enter a valid email address',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    if (newLeadData.phone && !/^\+?1?\d{10}$/.test(newLeadData.phone.replace(/\D/g, ''))) {
      toast({
        title: 'Validation Error',
        description: 'Please enter a valid 10-digit phone number',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  /**
   * Add New Lead
   */
  const addNewLead = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      // Format the data to match the LeadModel schema
      const formattedData = {
        name: newLeadData.name.trim(),
        email: newLeadData.email?.trim() || `unknown_${Date.now()}@placeholder.com`,
        phone: newLeadData.phone?.replace(/\D/g, ''),
        status: newLeadData.status || 'TBD',
        leadSource: newLeadData.leadSource || '',
        leadType: newLeadData.leadType || '',
        clientType: newLeadData.clientType || '',
        
        // Add criteria object
        criteria: {
          motivation: Boolean(newLeadData.motivation),
          ability: Boolean(newLeadData.ability),
          willingness: Boolean(newLeadData.willingness),
          propertyTypes: [],
          bedrooms: null,
          bathrooms: null,
          priceRange: ''
        },

        // Add messaging preferences
        messagingPreferences: {
          optedIn: true,
          preferredTime: {
            start: '09:00',
            end: '17:00'
          },
          doNotDisturb: false,
          preferredMethod: 'both'
        },

        // Set default values
        callOption: 'direct',
        addedDate: new Date().toISOString()
      };

      console.log('Sending formatted data:', formattedData); // Debug log

      const response = await axios.post(`${API_BASE_URL}/api/leads`, formattedData);
      
      toast({
        title: 'Success',
        description: 'Lead added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      closeNewLeadModal();
      fetchLeads();
      
    } catch (error) {
      console.error('Failed to add lead:', error.response?.data || error.message);
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to add lead',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   * Open and Close View Modal
   */
  const openViewModal = (lead) => {
    setViewingLead(lead);
    onOpen();
  };

  const closeViewModal = () => {
    setViewingLead(null);
    onClose();
  };

  /**
   * Save Viewed Lead
   */
  const saveViewedLead = async (updatedLead) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/api/leads/${updatedLead._id}`, updatedLead);
      const updatedLeadData = response.data;
      setLeads(prevLeads => prevLeads.map(lead => lead._id === updatedLeadData._id ? updatedLeadData : lead));
      closeViewModal();
      toast({
        title: 'Lead updated',
        description: 'Lead has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchLeads(); // Refresh the entire leads list
    } catch (error) {
      console.error('Error updating lead:', error);
      toast({
        title: 'Error',
        description: 'Failed to update lead. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  /**
   * Toggle Lead Selection for Bulk Actions
   */
  const toggleLeadSelection = (leadId) => {
    setSelectedLeads((prev) =>
      prev.includes(leadId) ? prev.filter((id) => id !== leadId) : [...prev, leadId]
    );
  };

  const toggleAllLeads = () => {
    if (selectedLeads.length === filteredLeads.length) {
      setSelectedLeads([]);
    } else {
      setSelectedLeads(filteredLeads.map((lead) => lead._id));
    }
  };

  /**
   * Refresh Leads
   */
  const refreshLeads = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/leads`);
      setLeads(response.data);
    } catch (error) {
      console.error('Error refreshing leads:', error);
      toast({
        title: 'Error',
        description: 'Failed to refresh leads. Please reload the page.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   * Delete Selected Leads
   */
  const deleteSelectedLeads = async () => {
    try {
      console.log('Attempting to delete selected leads:', selectedLeads);

      const deletePromises = selectedLeads.map(async (id) => {
        const url = `${API_BASE_URL}/api/leads/${id}`;
        console.log(`Attempting to delete lead with URL: ${url}`);
        try {
          const deleteResponse = await axios.delete(url);
          return { id, success: true, response: deleteResponse };
        } catch (error) {
          console.error(`Failed to delete lead ${id}:`, error);
          return { id, success: false, error };
        }
      });

      const results = await Promise.all(deletePromises);
      console.log('Delete results:', results);

      const successfulDeletes = results.filter((result) => result.success);
      const failedDeletes = results.filter((result) => !result.success);

      // Update the leads state by filtering out the successfully deleted leads
      setLeads(prevLeads => {
        // Ensure prevLeads is an array
        const leadsArray = Array.isArray(prevLeads) ? prevLeads : [];
        return leadsArray.filter(lead => !successfulDeletes.map(r => r.id).includes(lead._id));
      });

      // Clear selected leads
      setSelectedLeads([]);

      if (failedDeletes.length > 0) {
        const errorMessages = failedDeletes
          .map(result => `Lead ${result.id}: ${result.error.response?.data?.message || result.error.message}`)
          .join('; ');

        toast({
          title: 'Partial deletion',
          description: `Successfully deleted ${successfulDeletes.length} lead(s). ${failedDeletes.length} lead(s) failed to delete. Errors: ${errorMessages}`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Leads deleted',
          description: `${successfulDeletes.length} lead(s) have been successfully deleted.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }

      // Refresh leads after deletion
      await fetchLeads();
    } catch (error) {
      console.error('Error in deleteSelectedLeads:', error);
      toast({
        title: 'Error',
        description: `Failed to delete selected leads: ${error.message}. Please try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   * Delete Single Lead
   */
  const deleteLead = async (leadId) => {
    try {
      const leadToDelete = leads.find((lead) => lead._id === leadId);
      await axios.delete(`${API_BASE_URL}/api/leads/${leadId}`);
      const updatedLeads = leads.filter((lead) => lead._id !== leadId);
      setLeads(updatedLeads);
      setDeletedLead(leadToDelete);
      setShowUndoButton(true);
      toast({
        title: 'Lead deleted',
        description: 'The lead has been successfully deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Hide undo button after 20 seconds
      setTimeout(() => {
        setShowUndoButton(false);
        setDeletedLead(null);
      }, 20000);
    } catch (error) {
      console.error('Error deleting lead:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete the lead. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  /**
   * Undo Delete
   */
  const undoDelete = async () => {
    if (deletedLead) {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/leads`, deletedLead);
        setLeads([...leads, response.data]);
        setShowUndoButton(false);
        setDeletedLead(null);
        toast({
          title: 'Deletion undone',
          description: 'The lead has been restored.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error undoing lead deletion:', error);
        toast({
          title: 'Error',
          description: 'Failed to restore the lead. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  /**
   * Setup Twilio Device
   */
  useEffect(() => {
    const setupDevice = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/twilio/generate-token`, {
          params: { identity: 'user' } // Add this line
        });
        const token = response.data.token;
        const newDevice = new Device(token, {
          codecPreferences: ['opus', 'pcmu'],
          fakeLocalDTMF: true,
          enableRingingState: true,
        });
        setDevice(newDevice);

        newDevice.on('error', (error) => {
          console.error('Twilio device error:', error);
        });

        newDevice.on('incoming', (call) => {
          console.log('Incoming call:', call);
          setCall(call);
          setIsCallActive(true);
          setActiveCall({
            callSid: call.parameters.CallSid,
            leadId: call.parameters.LeadId, // Assuming LeadId is passed as a parameter
            leadName: call.parameters.LeadName, // Assuming LeadName is passed as a parameter
          });

          call.accept();
        });
      } catch (error) {
        console.error('Error setting up Twilio device:', error);
      }
    };

    setupDevice();

    return () => {
      if (device) {
        device.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Initiate a Call
   */
  const initiateCall = async (lead) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/initiate-call`, {
        to: lead.phone,
        leadId: lead._id,
      });
      if (response.data.success) {
        toast({
          title: 'Call Initiated',
          description: `Call started with ${lead.name}.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setIsCallActive(true);
        setActiveCall({
          callSid: response.data.callSid,
          leadId: lead._id,
          leadName: lead.name,
        });
      }
    } catch (error) {
      console.error('Error initiating call:', error);
      toast({
        title: 'Call Error',
        description: `Failed to initiate call: ${
          error.response?.data?.message || error.message
        }`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   * Initiate a Conference Call
   */
  const initiateConferenceCall = async (lead) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/initiate-conference-call`, {
        leadId: lead._id,
      });
      if (response.data.success) {
        setIsCallActive(true);
        setActiveCall({
          callSid: response.data.callSid,
          leadId: lead._id,
          leadName: lead.name,
        });
        toast({
          title: 'Conference Call Initiated',
          description: `Conference call started with ${lead.name}.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error initiating conference call:', error);
      toast({
        title: 'Conference Call Error',
        description: `Failed to initiate conference call: ${
          error.response?.data?.message || error.message
        }`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   * Call Personal Number
   */
  const callPersonalNumber = async (lead) => {
    try {
      const personalNumber = prompt('Enter your personal number to connect the call:');
      if (!personalNumber) {
        toast({
          title: 'Personal Number Required',
          description: 'Please enter a personal number to proceed.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const response = await axios.post(`${API_BASE_URL}/api/call-personal-number`, {
        to: lead.phone,
        personalNumber: personalNumber,
        leadId: lead._id,
      });

      if (response.data.success) {
        setIsCallActive(true);
        setActiveCall({
          callSid: response.data.leadCallSid,
          leadId: lead._id,
          leadName: lead.name,
        });
        toast({
          title: 'Personal Call Initiated',
          description: `Personal call connected with ${lead.name}.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error calling personal number:', error);
      toast({
        title: 'Personal Call Error',
        description: `Failed to initiate personal call: ${
          error.response?.data?.message || error.message
        }`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   * End Call
   */
  const endCall = async () => {
    if (call) {
      call.disconnect();
    }
    if (activeCall) {
      try {
        await axios.post(`${API_BASE_URL}/api/end-call/${activeCall.callSid}`);
        setIsCallActive(false);
        setActiveCall(null);
        toast({
          title: 'Call Ended',
          description: 'The call has been successfully ended.',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });

        // Prompt user to add notes after call
        const noteContent = prompt('Enter notes for this call:');
        if (noteContent) {
          // Create a new note and associate it with the lead
          await axios.post(`${API_BASE_URL}/api/notes`, {
            content: noteContent,
            createdBy: 'User', // Replace with actual user info if available
            leadIds: [activeCall.leadId],
          });
          toast({
            title: 'Note Added',
            description: 'Call notes have been added to the lead.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }

        // Refresh leads to get updated data
        fetchLeads();
      } catch (error) {
        console.error('Error ending call:', error);
        toast({
          title: 'End Call Error',
          description: `Failed to end call: ${error.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  /**
   * Toggle Mute
   */
  const handleToggleMute = () => {
    if (call) {
      call.mute(!isMuted);
      setIsMuted(!isMuted);
    }
  };
  /**
   * Send SMS
   */
  const sendSms = async (lead) => {
    const message = prompt(`Enter the SMS message to send to ${lead.name}:`);
    if (!message) {
      toast({
        title: 'SMS Cancelled',
        description: 'No message was entered.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/twilio/send-sms`, {
        to: lead.phone,
        body: message,
        leadId: lead._id,
      });
      if (response.data.success) {
        toast({
          title: 'SMS Sent',
          description: `Message sent to ${lead.name}.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error sending SMS:', error);
      toast({
        title: 'SMS Error',
        description: `Failed to send SMS: ${
          error.response?.data?.message || error.message
        }`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   * View Lead Details (Navigate to Lead Detail Page)
   */
  const viewLead = (leadId) => {
    console.log('Viewing lead with ID:', leadId); // Add this line for debugging
    navigate(`/leads/${leadId}`);
  };

  // Add or update this function
  const openEditModal = (lead) => {
    setEditingLead(lead);
    onOpen(); // Assuming you're using Chakra UI's useDisclosure hook
  };

  const openQuickEditModal = (lead) => {
    setEditingLead({ ...lead });
    onOpen();
  };

  const handleQuickEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingLead(prev => ({ ...prev, [name]: value }));
  };

  const saveQuickEditLead = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/api/leads/${editingLead._id}`, editingLead);
      const updatedLead = response.data;
      setLeads(prevLeads => prevLeads.map(lead => lead._id === updatedLead._id ? updatedLead : lead));
      onClose();
      toast({
        title: 'Lead updated',
        description: 'Lead has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating lead:', error);
      toast({
        title: 'Error',
        description: 'Failed to update lead. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const socket = io(API_BASE_URL);
    
    socket.on('newLead', (newLead) => {
      setLeads(prevLeads => [newLead, ...prevLeads].slice(0, 10)); // Assuming you want to keep only the top 10 leads
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Box
      p={5}
      width="100%"
      height="calc(100vh - 60px)"
      overflowY="auto"
      mt="0"
      pt="20px"
      bg="primaryBg"
      color="textColor"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading size="xl">Lead Management</Heading>
        <Button leftIcon={<FaPlus />} colorScheme="orange" onClick={openNewLeadModal}>
          Add New Lead
        </Button>
      </Flex>

      <MotionBox initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
        {/* Filters Section */}
        <Box mb={6}>
          <VStack spacing={4} align="stretch">
            <HStack spacing={4}>
              <Input
                name="name"
                placeholder="Filter by name"
                value={filterConfig.name}
                onChange={handleFilterChange}
                borderColor="orange.400"
                _hover={{ borderColor: 'orange.500' }}
                _focus={{ borderColor: 'orange.500', boxShadow: '0 0 0 1px var(--chakra-colors-orange-500)' }}
              />
              <Input
                name="email"
                placeholder="Filter by email"
                value={filterConfig.email}
                onChange={handleFilterChange}
                borderColor="orange.400"
                _hover={{ borderColor: 'orange.500' }}
                _focus={{ borderColor: 'orange.500', boxShadow: '0 0 0 1px var(--chakra-colors-orange-500)' }}
              />
              <Select
                name="status"
                placeholder="Filter by status"
                value={filterConfig.status}
                onChange={handleFilterChange}
                borderColor="orange.400"
                _hover={{ borderColor: 'orange.500' }}
                _focus={{ borderColor: 'orange.500', boxShadow: '0 0 0 1px var(--chakra-colors-orange-500)' }}
              >
                <option value="New">New</option>
                <option value="Follow Up">Follow Up</option>
                <option value="Qualified">Qualified</option>
                <option value="Lost">Lost</option>
              </Select>
            </HStack>
            <HStack spacing={4}>
              <Input
                name="addedDateStart"
                placeholder="Added Date Start"
                type="date"
                value={filterConfig.addedDateStart}
                onChange={handleFilterChange}
                borderColor="orange.400"
                _hover={{ borderColor: 'orange.500' }}
                _focus={{ borderColor: 'orange.500', boxShadow: '0 0 0 1px var(--chakra-colors-orange-500)' }}
              />
              <Input
                name="addedDateEnd"
                placeholder="Added Date End"
                type="date"
                value={filterConfig.addedDateEnd}
                onChange={handleFilterChange}
                borderColor="orange.400"
                _hover={{ borderColor: 'orange.500' }}
                _focus={{ borderColor: 'orange.500', boxShadow: '0 0 0 1px var(--chakra-colors-orange-500)' }}
              />
            </HStack>
            <HStack spacing={4}>
              <Checkbox
                name="motivation"
                isChecked={filterConfig.motivation === 'true'}
                onChange={(e) =>
                  handleFilterChange({
                    target: { name: 'motivation', value: e.target.checked.toString() },
                  })
                }
              >
                Motivation
              </Checkbox>
              <Checkbox
                name="ability"
                isChecked={filterConfig.ability === 'true'}
                onChange={(e) =>
                  handleFilterChange({
                    target: { name: 'ability', value: e.target.checked.toString() },
                  })
                }
              >
                Ability
              </Checkbox>
              <Checkbox
                name="willingness"
                isChecked={filterConfig.willingness === 'true'}
                onChange={(e) =>
                  handleFilterChange({
                    target: { name: 'willingness', value: e.target.checked.toString() },
                  })
                }
              >
                Willingness
              </Checkbox>
              <Select
                name="leadType"
                placeholder="Filter by lead type"
                value={filterConfig.leadType}
                onChange={handleFilterChange}
                borderColor="orange.400"
              >
                <option value="buyer">Buyer</option>
                <option value="seller">Seller</option>
                <option value="renter">Renter</option>
                <option value="landlord">Landlord</option>
              </Select>
              <Select
                name="clientType"
                placeholder="Filter by client type"
                value={filterConfig.clientType}
                onChange={handleFilterChange}
                borderColor="orange.400"
              >
                <option value="end_user">End User</option>
                <option value="investor">Investor</option>
              </Select>
              <Select
                name="leadSource"
                placeholder="Filter by lead source"
                value={filterConfig.leadSource}
                onChange={handleFilterChange}
                borderColor="orange.400"
              >
                <option value="website_signup">Website Signup</option>
                <option value="Downloaded_Lead_Magnet">Lead Magnet</option>
                <option value="open_house">Open House</option>
                <option value="older_lead">Older Lead</option>
                <option value="referral">Referral</option>
                <option value="followup">Follow-up</option>
              </Select>
            </HStack>
            <Button
              onClick={clearFilters}
              variant="outline"
              size="md"
              width="auto"
              px={4}
              fontWeight="normal"
              leftIcon={<FaTimes />}
            >
              Clear Filters
            </Button>
          </VStack>
        </Box>

        {/* Bulk Actions */}
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Checkbox
            isChecked={selectedLeads.length === filteredLeads.length}
            isIndeterminate={selectedLeads.length > 0 && selectedLeads.length < filteredLeads.length}
            onChange={toggleAllLeads}
          >
            Select All
          </Checkbox>
          <Button
            colorScheme="red"
            leftIcon={<FaTrash />}
            onClick={deleteSelectedLeads}
            isDisabled={selectedLeads.length === 0}
          >
            Delete Selected ({selectedLeads.length})
          </Button>
        </Flex>

        {/* Leads Table */}
        <Box overflowX="auto">
          <Table variant="simple" colorScheme="orange" style={{ borderCollapse: 'separate', borderSpacing: 0 }}>
            <Thead>
              <Tr>
                <Th borderColor="orange.400" borderTopWidth="1px" borderLeftWidth="1px">
                  Select
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('name')} cursor="pointer">
                  Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('email')} cursor="pointer">
                  Email {sortConfig.key === 'email' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('phone')} cursor="pointer">
                  Phone {sortConfig.key === 'phone' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('status')} cursor="pointer">
                  Status {sortConfig.key === 'status' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('motivation')} cursor="pointer">
                  Motivation {sortConfig.key === 'motivation' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('ability')} cursor="pointer">
                  Ability {sortConfig.key === 'ability' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('willingness')} cursor="pointer">
                  Willingness {sortConfig.key === 'willingness' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('leadType')} cursor="pointer">
                  Lead Type {sortConfig.key === 'leadType' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('clientType')} cursor="pointer">
                  Client Type {sortConfig.key === 'clientType' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('leadSource')} cursor="pointer">
                  Lead Source {sortConfig.key === 'leadSource' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" onClick={() => handleSort('addedDate')} cursor="pointer">
                  Added Date {sortConfig.key === 'addedDate' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </Th>
                <Th borderColor="orange.400" borderTopWidth="1px" borderRightWidth="1px">
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredLeads.map((lead, index) => (
                <Tr key={lead._id}>
                  <Td borderColor="orange.400" borderLeftWidth="1px" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    <Checkbox
                      isChecked={selectedLeads.includes(lead._id)}
                      onChange={() => toggleLeadSelection(lead._id)}
                    />
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {lead.name}
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {lead.email}
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {lead.phone}
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {lead.status}
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {lead.motivation ? 'Yes' : 'No'}
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {lead.ability ? 'Yes' : 'No'}
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {lead.willingness ? 'Yes' : 'No'}
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {lead.leadType || 'N/A'}
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {lead.clientType || 'N/A'}
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {lead.leadSource || 'N/A'}
                  </Td>
                  <Td borderColor="orange.400" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    {new Date(lead.addedDate).toLocaleString()}
                  </Td>
                  <Td borderColor="orange.400" borderRightWidth="1px" borderBottomWidth={index === filteredLeads.length - 1 ? '1px' : '0px'}>
                    <HStack spacing={2}>
                      <Button
                        size="sm"
                        colorScheme="green"
                        leftIcon={<FaEye />}
                        onClick={() => viewLead(lead._id)}
                      >
                        View
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaPen />}
                        onClick={() => openQuickEditModal(lead)}
                      >
                        Quick Edit
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="red"
                        leftIcon={<FaTrash />}
                        onClick={() => deleteLead(lead._id)}
                      >
                        Delete
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="teal"
                        leftIcon={<FaPhone />}
                        onClick={() => initiateCall(lead)}
                      >
                        Call
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="orange"
                        leftIcon={<FaUserAlt />}
                        onClick={() => callPersonalNumber(lead)}
                      >
                        On Cell
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaSms />}
                        onClick={() => sendSms(lead)}
                      >
                        SMS
                      </Button>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </MotionBox>

      {/* New Lead Modal */}
      <Modal
        isOpen={isNewLeadModalOpen}
        onClose={closeNewLeadModal}
        size={{ base: "full", md: "6xl" }}
      >
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>Add New Lead</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid
              templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
              gap={6}
            >
              {/* Basic Information */}
              <GridItem colSpan={1}>
                <VStack align="stretch" spacing={4}>
                  <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      name="name"
                      value={newLeadData.name || ""}
                      onChange={handleNewLeadInputChange}
                      placeholder="Enter name"
                      borderColor="orange.400"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      name="email"
                      value={newLeadData.email || ""}
                      onChange={handleNewLeadInputChange}
                      placeholder="Enter email"
                      borderColor="orange.400"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input
                      name="phone"
                      value={newLeadData.phone || ""}
                      onChange={handleNewLeadInputChange}
                      placeholder="Enter phone"
                      borderColor="orange.400"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Select
                      name="status"
                      value={newLeadData.status || ""}
                      onChange={handleNewLeadInputChange}
                      borderColor="orange.400"
                    >
                      <option value="">Select Status</option>
                      <option value="TBD">TBD</option>
                      <option value="New">New</option>
                      <option value="Hot">Hot</option>
                      <option value="Warm">Warm</option>
                      <option value="Cold">Cold</option>
                      <option value="Lost">Lost</option>
                    </Select>
                  </FormControl>
                </VStack>
              </GridItem>

              {/* Lead Details */}
              <GridItem colSpan={1}>
                <VStack align="stretch" spacing={4}>
                  <FormControl>
                    <FormLabel>Lead Source</FormLabel>
                    <Input
                      name="leadSource"
                      value={newLeadData.leadSource || ""}
                      onChange={handleNewLeadInputChange}
                      placeholder="Enter lead source"
                      borderColor="orange.400"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Lead Type</FormLabel>
                    <Select
                      name="leadType"
                      value={newLeadData.leadType || ""}
                      onChange={handleNewLeadInputChange}
                      borderColor="orange.400"
                    >
                      <option value="">Select Type</option>
                      <option value="buyer">Buyer</option>
                      <option value="seller">Seller</option>
                      <option value="renter">Renter</option>
                      <option value="landlord">Landlord</option>
                    </Select>
                  </FormControl>
                </VStack>
              </GridItem>

              {/* Qualifications */}
              <GridItem colSpan={1}>
                <VStack align="stretch" spacing={4}>
                  <FormControl>
                    <FormLabel>Qualifications</FormLabel>
                    <Stack spacing={2}>
                      <Checkbox
                        name="motivation"
                        isChecked={newLeadData.motivation || false}
                        onChange={handleNewLeadInputChange}
                      >
                        Motivation
                      </Checkbox>
                      <Checkbox
                        name="ability"
                        isChecked={newLeadData.ability || false}
                        onChange={handleNewLeadInputChange}
                      >
                        Ability
                      </Checkbox>
                      <Checkbox
                        name="willingness"
                        isChecked={newLeadData.willingness || false}
                        onChange={handleNewLeadInputChange}
                      >
                        Willingness
                      </Checkbox>
                    </Stack>
                  </FormControl>
                </VStack>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={addNewLead}>
              Add Lead
            </Button>
            <Button variant="ghost" onClick={closeNewLeadModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* View/Edit Lead Modal */}
      {viewingLead && (
        <ViewLeadModal
          lead={viewingLead}
          isOpen={isOpen}
          onClose={closeViewModal}
          onSave={saveViewedLead}
        />
      )}

      {/* Active Call Controls */}
      {isCallActive && activeCall && (
        <Flex justifyContent="flex-end" mb={4}>
          <Box mr={4}>
            <Text fontWeight="bold">Active Call with {activeCall.leadName}</Text>
          </Box>
          <Button colorScheme="red" onClick={endCall} mr={2}>
            End Call
          </Button>
          <Button onClick={handleToggleMute}>{isMuted ? 'Unmute' : 'Mute'}</Button>
        </Flex>
      )}

      {/* Undo Delete Button */}
      {showUndoButton && deletedLead && (
        <Flex justifyContent="center" mb={4}>
          <Button colorScheme="yellow" onClick={undoDelete} leftIcon={<FaUsers />}>
            Undo Delete
          </Button>
        </Flex>
      )}

      {/* Quick Edit Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>Quick Edit Lead</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {editingLead && (
              <VStack spacing={4} align="stretch">
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    name="name"
                    value={editingLead.name}
                    onChange={handleQuickEditInputChange}
                    borderColor="orange.400"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    value={editingLead.email}
                    onChange={handleQuickEditInputChange}
                    borderColor="orange.400"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    name="phone"
                    value={editingLead.phone}
                    onChange={handleQuickEditInputChange}
                    borderColor="orange.400"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select
                    name="status"
                    value={editingLead.status}
                    onChange={handleQuickEditInputChange}
                    borderColor="orange.400"
                  >
                    <option value="New">New</option>
                    <option value="Follow Up">Follow Up</option>
                    <option value="Qualified">Qualified</option>
                    <option value="Lost">Lost</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Bucket</FormLabel>
                  <Select
                    name="bucket"
                    value={editingLead.bucket}
                    onChange={handleQuickEditInputChange}
                    borderColor="orange.400"
                  >
                    <option value="A">Bucket A 🔥 Hot Lead (60 days)</option>
                    <option value="B">Bucket B 🪴 Nurture (60-120 days)</option>
                    <option value="C">Bucket C 🔭 Watch Lead (120+ days)</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Lead Type</FormLabel>
                  <Select
                    name="leadType"
                    value={editingLead.leadType}
                    onChange={handleQuickEditInputChange}
                    borderColor="orange.400"
                  >
                    <option value="buyer">Buyer</option>
                    <option value="seller">Seller</option>
                    <option value="renter">Renter</option>
                    <option value="landlord">Landlord</option>
                  </Select>
                </FormControl>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={saveQuickEditLead}>
              Save Changes
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default LeadManagement;