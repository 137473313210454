import { parsePhoneNumber, AsYouType } from 'libphonenumber-js';

export const formatPhoneNumber = (input) => {
  console.log('🔄 Formatting phone number:', { input });
  const asYouType = new AsYouType();
  const formatted = asYouType.input(input);
  console.log('📞 Format result:', { input, formatted });
  return formatted;
};

export const validateAndFormatPhoneNumber = (input) => {
  console.log('🔍 Validating and formatting:', { input });
  try {
    // Try to parse the phone number without specifying a default country
    const phoneNumber = parsePhoneNumber(input);
    console.log('📱 Parse result:', {
      input,
      isValid: phoneNumber?.isValid(),
      formatted: phoneNumber?.format('E.164')
    });
    
    if (phoneNumber && phoneNumber.isValid()) {
      // Return the number in E.164 format for consistent storage
      return phoneNumber.format('E.164');
    }
  } catch (error) {
    console.error('❌ Phone validation error:', {
      input,
      error: error.message
    });
  }
  // If parsing fails or the number is invalid, return the original input
  return input;
};

export const isValidPhoneNumber = (input) => {
  console.log('✅ Checking phone validity:', { input });
  try {
    const phoneNumber = parsePhoneNumber(input);
    const isValid = phoneNumber && phoneNumber.isValid();
    console.log('📱 Validation result:', {
      input,
      isValid,
      parsed: phoneNumber ? {
        country: phoneNumber.country,
        format: phoneNumber.format('E.164')
      } : null
    });
    return isValid;
  } catch (error) {
    console.error('❌ Phone validation error:', {
      input,
      error: error.message
    });
    return false;
  }
};