import React from 'react';
import { ChevronDown, Settings, Mail, FileText, Bell, CheckSquare, FileQuestion, Zap, Search, Star, Building2, ListTodo, Filter, Download, Upload, MoreHorizontal } from 'lucide-react';

const Sidebar = () => {
  const [expandedSections, setExpandedSections] = React.useState({
    automations: false,
    favorites: false,
    records: false,
    lists: false
  });

  const styles = {
    sidebar: {
      width: '240px',
      height: '100vh',
      backgroundColor: '#ffffff',
      borderRight: '1px solid #e5e7eb',
      display: 'flex',
      flexDirection: 'column',
      color: '#111827'
    },
    header: {
      padding: '12px 14px',
      borderBottom: '1px solid #e5e7eb',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    headerText: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      fontWeight: '600',
      fontSize: '14px'
    },
    quickActions: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 12px',
      gap: '8px',
      margin: '8px 8px 0',
      borderRadius: '6px',
      cursor: 'pointer',
      fontSize: '13px'
    },
    nav: {
      flex: 1,
      overflowY: 'auto',
      padding: '4px 8px'
    },
    navItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      padding: '8px 12px',
      cursor: 'pointer',
      borderRadius: '6px',
      fontSize: '13px'
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 12px',
      cursor: 'pointer',
      borderRadius: '6px',
      fontSize: '13px'
    },
    sectionContent: {
      marginLeft: '28px'
    },
    nestedItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '6px 12px',
      cursor: 'pointer',
      borderRadius: '6px',
      fontSize: '13px'
    },
    footer: {
      padding: '8px',
      borderTop: '1px solid #e5e7eb'
    },
    icon: {
      width: '16px',
      height: '16px',
      color: '#6b7280'
    },
    shortcutKey: {
      padding: '2px 4px',
      backgroundColor: '#f3f4f6',
      borderRadius: '4px',
      fontSize: '12px',
      color: '#6b7280'
    }
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const NavItem = ({ icon: Icon, label }) => (
    <div 
      style={styles.navItem}
      onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f3f4f6'}
      onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
    >
      <Icon style={styles.icon} />
      <span>{label}</span>
    </div>
  );

  const CollapsibleSection = ({ label, icon: Icon, isExpanded, onToggle, items }) => (
    <div>
      <div 
        style={styles.sectionHeader}
        onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f3f4f6'}
        onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
        onClick={onToggle}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon style={styles.icon} />
          <span>{label}</span>
        </div>
        <ChevronDown 
          style={{
            ...styles.icon,
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)',
            transition: 'transform 0.2s ease'
          }}
        />
      </div>
      {isExpanded && (
        <div style={styles.sectionContent}>
          {items.map((item, index) => (
            <div
              key={index}
              style={styles.nestedItem}
              onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f3f4f6'}
              onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div style={{ backgroundColor: '#ffffff', height: '100vh' }}>
      <div style={styles.sidebar}>
        <div style={styles.header}>
          <div style={styles.headerText}>
            <span>Vaughan Office Workspace</span>
            <ChevronDown style={{ ...styles.icon, width: '14px', height: '14px' }} />
          </div>
        </div>

        <div>
          <div 
            style={styles.quickActions}
            onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f3f4f6'}
            onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
          >
            <Zap style={styles.icon} />
            <span>Quick actions</span>
            <span style={styles.shortcutKey}>⌘K</span>
            <Search style={styles.icon} />
            <span style={styles.shortcutKey}>/</span>
          </div>
        </div>

        <nav style={styles.nav}>
          <NavItem icon={Bell} label="Notifications" />
          <NavItem icon={CheckSquare} label="Tasks" />
          <NavItem icon={FileQuestion} label="Notes" />
          <NavItem icon={Mail} label="Emails" />
          <NavItem icon={FileText} label="Reports" />
          
          <CollapsibleSection
            label="Automations"
            icon={Settings}
            isExpanded={expandedSections.automations}
            onToggle={() => toggleSection('automations')}
            items={['Workflows', 'Sequences']}
          />

          <div style={{ height: '1px', backgroundColor: '#e5e7eb', margin: '12px 0' }} />

          <CollapsibleSection
            label="Records"
            icon={Building2}
            isExpanded={expandedSections.records}
            onToggle={() => toggleSection('records')}
            items={['Companies', 'People', 'Deals', 'Workspaces', 'Partnerships']}
          />

          <div style={{ height: '1px', backgroundColor: '#e5e7eb', margin: '12px 0' }} />

          <CollapsibleSection
            label="Favorites"
            icon={Star}
            isExpanded={expandedSections.favorites}
            onToggle={() => toggleSection('favorites')}
            items={['Onboarding pipeline', 'Top of funnel', 'RevOps workflows']}
          />

          <div style={{ height: '1px', backgroundColor: '#e5e7eb', margin: '12px 0' }} />

          <CollapsibleSection
            label="Lists"
            icon={ListTodo}
            isExpanded={expandedSections.lists}
            onToggle={() => toggleSection('lists')}
            items={['Strategic accounts']}
          />
        </nav>

        <div style={styles.footer}>
          <div 
            style={styles.navItem}
            onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f3f4f6'}
            onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
          >
            <FileQuestion style={styles.icon} />
            <span>Help and first steps</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const MainContent = () => {
  const styles = {
    container: {
      flex: 1,
      padding: '24px',
      backgroundColor: '#f9fafb'
    },
    header: {
      marginBottom: '24px'
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      marginBottom: '16px'
    },
    controls: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '16px'
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      padding: '8px 16px',
      borderRadius: '6px',
      border: '1px solid #e5e7eb',
      backgroundColor: '#ffffff',
      cursor: 'pointer'
    },
    table: {
      width: '100%',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
    },
    th: {
      padding: '12px 16px',
      textAlign: 'left',
      borderBottom: '1px solid #e5e7eb',
      fontSize: '13px',
      fontWeight: '600'
    },
    td: {
      padding: '12px 16px',
      borderBottom: '1px solid #e5e7eb',
      fontSize: '14px'
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Companies</h1>
        
        <div style={styles.controls}>
          <div style={{ display: 'flex', gap: '12px' }}>
            <button style={styles.button}>
              <Filter size={16} />
              Advanced filter
            </button>
            <button style={styles.button}>
              Last email interaction ▼
            </button>
          </div>
          
          <div style={{ display: 'flex', gap: '12px' }}>
            <button style={styles.button}>
              <Upload size={16} />
              Import
            </button>
            <button style={styles.button}>
              <Download size={16} />
              Export
            </button>
          </div>
        </div>
      </div>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>
              <input type="checkbox" />
            </th>
            <th style={styles.th}>Company</th>
            <th style={styles.th}>Domain</th>
            <th style={styles.th}>Deals</th>
            <th style={styles.th}>Est. ARR</th>
            <th style={styles.th}>Last interaction</th>
            <th style={styles.th}>Connection</th>
            <th style={styles.th}></th>
          </tr>
        </thead>
        <tbody>
          {/* Sample row */}
          <tr>
            <td style={styles.td}><input type="checkbox" /></td>
            <td style={styles.td}>Acme Corp</td>
            <td style={styles.td}>acme.com</td>
            <td style={styles.td}>2 active</td>
            <td style={styles.td}>$50k - $100k</td>
            <td style={styles.td}>2 hours ago</td>
            <td style={styles.td}>Strong</td>
            <td style={styles.td}>
              <MoreHorizontal size={16} style={{ cursor: 'pointer' }} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Update the main component to include both Sidebar and MainContent
const FAttio = () => {
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <MainContent />
    </div>
  );
};

export default FAttio;