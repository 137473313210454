import React, { useState } from 'react';
import { 
  Box, Button, Container, Heading, Text, Stack, SimpleGrid, 
  Icon, useColorModeValue, Flex, chakra, VStack,
  Stat, StatLabel, StatNumber, StatHelpText,
  Collapse
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { BsTelephone, BsPeople, BsGraphUp, BsChatDots } from 'react-icons/bs';
import { FaRobot, FaChartLine, FaTools, FaEnvelope, FaLock, FaGlobe, FaChartBar, FaRocket, FaHashtag, FaVideo, FaUsers, FaCode, FaPaintBrush, FaShieldAlt, FaBuilding, FaLightbulb, FaUserTie } from 'react-icons/fa';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useBreakpointValue } from '@chakra-ui/react';

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);

const Feature = ({ title, text, icon }) => {
  return (
    <Box
      p={6}
      bg="gray.800"
      borderRadius="lg"
      border="1px solid"
      borderColor="orange.400"
      cursor="pointer"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "xl",
        borderColor: "orange.500"
      }}
      transition="all 0.3s"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Heading size="md" color="white" mb={2}>
            {title}
          </Heading>
          <Text color="gray.400">{text}</Text>
        </Box>
        <Icon as={icon} w={8} h={8} color="orange.400" />
      </Flex>
    </Box>
  );
};

const StatCard = ({ title, stat, icon, helpText, onClick }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        p={6}
        bg="gray.800"
        borderRadius="lg"
        border="1px solid"
        borderColor="orange.400"
        cursor={onClick ? "pointer" : "default"}
        onClick={onClick}
        _hover={{
          transform: "translateY(-5px)",
          boxShadow: "xl",
          borderColor: "orange.500"
        }}
        transition="all 0.3s"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Stat>
            <StatLabel color="gray.400">{title}</StatLabel>
            <StatNumber fontSize="3xl" color="white">{stat}</StatNumber>
            {helpText && (
              <StatHelpText color="orange.400">{helpText}</StatHelpText>
            )}
          </Stat>
          <Icon as={icon} w={8} h={8} color="orange.400" />
        </Flex>
      </Box>
    </motion.div>
  );
};

const ProductCard = ({ title, price, features, icon, isPopular, description, toolsIncluded }) => {
  return (
    <Box
      p={8}
      bg="gray.800"
      borderRadius="lg"
      border="1px solid"
      borderColor={isPopular ? "orange.500" : "orange.400"}
      position="relative"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "xl",
        borderColor: "orange.500"
      }}
      transition="all 0.3s"
    >
      {isPopular && (
        <Box
          position="absolute"
          top="-12px"
          right="50%"
          transform="translateX(50%)"
          bg="orange.500"
          color="white"
          px={3}
          py={1}
          borderRadius="full"
          fontSize="sm"
        >
          Most Popular
        </Box>
      )}
      <VStack spacing={6}>
        <Icon as={icon} w={10} h={10} color="orange.400" />
        <Heading size="lg" color="white">
          {title}
        </Heading>
        <Text color="gray.400" textAlign="center">
          {description}
        </Text>
        <Text fontSize="4xl" color="white" fontWeight="bold">
          ${price}
          <Text as="span" fontSize="sm" color="gray.400">/mo</Text>
        </Text>
        
        <Box w="100%">
          <Text color="white" fontWeight="bold" mb={4}>Tools Included:</Text>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            {toolsIncluded.map((tool, index) => (
              <Flex key={index} align="center">
                <Icon as={tool.icon} color="orange.400" mr={2} />
                <Text color="gray.400">{tool.name}</Text>
              </Flex>
            ))}
          </SimpleGrid>
        </Box>

        <Box w="100%">
          <Text color="white" fontWeight="bold" mb={4}>Features:</Text>
          <VStack spacing={2} align="start">
            {features.map((feature, index) => (
              <Flex key={index} align="center">
                <Box color="orange.400" mr={2}>•</Box>
                <Text color="gray.400">{feature}</Text>
              </Flex>
            ))}
          </VStack>
        </Box>

        <Button
          w="100%"
          size="lg"
          colorScheme="orange"
          variant={isPopular ? "solid" : "outline"}
        >
          Start Free Trial
        </Button>
      </VStack>
    </Box>
  );
};

const ToolboxFeature = ({ title, description, features, icon }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <MotionBox
      p={6}
      bg="black"
      borderRadius="0"
      border="1px solid"
      borderColor="orange.400"
      mb={4}
      position="relative"
      overflow="hidden"
      cursor="pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      transition="all 0.3s ease"
      _hover={{
        transform: 'translateY(-2px)',
        borderColor: 'orange.500',
        boxShadow: '0 0 10px rgba(255, 140, 0, 0.3)',
      }}
    >
      <Flex align="center" mb={4}>
        <Icon 
          as={icon} 
          w={10}
          h={10}
          color="orange.400" 
          mr={3}
        />
        <Heading
          size="md"
          color="white"
          fontWeight="bold"
          className="clickbait-title"
          _hover={{ color: 'orange.400' }}
        >
          {title}
        </Heading>
      </Flex>
      <Collapse in={isHovered} animateOpacity>
        <Box mt={2}>
          <Text 
            color="white"
            mb={3}
            fontSize="sm"
          >
            {description}
          </Text>
          <VStack align="start" spacing={2}>
            {features.map((feature, index) => (
              <Flex key={index} align="center">
                <Box color="orange.400" mr={2}>•</Box>
                <Text 
                  color="white"
                  fontSize="sm"
                >
                  {feature}
                </Text>
              </Flex>
            ))}
          </VStack>
        </Box>
      </Collapse>
    </MotionBox>
  );
};

const TierFeatureCard = ({ title, description, features, icon, price }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <MotionBox
      p={6}
      bg="gray.900"
      borderRadius="lg"
      border="1px solid"
      borderColor="orange.400"
      mb={4}
      position="relative"
      overflow="hidden"
      cursor="pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      transition="all 0.3s ease"
      _hover={{
        transform: 'translateY(-2px)',
        borderColor: 'orange.500',
        boxShadow: '0 0 20px rgba(255, 140, 0, 0.2)',
      }}
    >
      <Flex align="center" justify="space-between" mb={4}>
        <Flex align="center">
          <Icon 
            as={icon} 
            w={8}
            h={8}
            color="orange.400" 
            mr={3}
          />
          <VStack align="start" spacing={0}>
            <Heading
              size="md"
              color="white"
              fontWeight="bold"
              _hover={{ color: 'orange.400' }}
            >
              {title}
            </Heading>
            {price && (
              <Text color="orange.400" fontSize="lg" fontWeight="bold">
                Starting at ${price}/mo
              </Text>
            )}
          </VStack>
        </Flex>
      </Flex>
      
      <Text color="gray.400" mb={3} fontSize="sm">
        {description}
      </Text>
      
      <Collapse in={isHovered} animateOpacity>
        <VStack align="start" spacing={2} mt={4}>
          {features.map((feature, index) => (
            <Flex key={index} align="center">
              <Box color="orange.400" mr={2}>•</Box>
              <Text color="gray.300" fontSize="sm">
                {feature}
              </Text>
            </Flex>
          ))}
        </VStack>
      </Collapse>
    </MotionBox>
  );
};

function NewHomePage() {
  const navigate = useNavigate();
  const bgGradient = useColorModeValue(
    'linear(to-r, orange.400, orange.600)',
    'linear(to-r, orange.200, orange.400)'
  );
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box bg="black" minH="100vh">
      <Container maxW="container.xl" pt={{ base: "80px", md: "8" }}>
        <VStack spacing={{ base: 4, md: 8 }} align="stretch" mb={{ base: 8, md: 12 }}>
          <MotionHeading
            mb={6}
            bgGradient={bgGradient}
            bgClip="text"
            fontSize={{ base: "2xl", md: "4xl" }}
            textAlign="center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Welcome to Real•Talk•Pro Dashboard
          </MotionHeading>
          <Heading
            fontSize={{ base: '2xl', md: '4xl' }}
            fontWeight="bold"
            textAlign="center"
            color="white"
          >
            Welcome to Real•Talk•Pro
          </Heading>
          <Text
            fontSize="xl"
            textAlign="center"
            color="gray.300"
          >
            Your Professional Communication Hub
          </Text>
        </VStack>

        <SimpleGrid 
          columns={{ base: 1, md: 2, lg: 3 }} 
          spacing={{ base: 6, md: 10 }}
          mt={{ base: 12, md: 20 }}
          px={{ base: 4, md: 0 }}
        >
          <Feature
            icon={BsTelephone}
            title="Smart Calling System"
            text="Advanced Twilio integration with dynamic call scripts and AI-powered call analysis."
          />
          <Feature
            icon={FaRobot}
            title="AI-Powered Analytics"
            text="Real-time analytics and insights to optimize your marketing strategies."
          />
          <Feature
            icon={FaChartLine}
            title="Lead Generation Tools"
            text="Advanced tools to generate more leads and improve conversion rates."
          />
        </SimpleGrid>

        <Box mt={20}>
          <Heading
            color="white"
            size="lg"
            textAlign="center"
            mb={12}
          >
            Choose Your Lead Generation Package
          </Heading>
          <SimpleGrid
            columns={{ base: 1, lg: 3 }}
            spacing={{ base: 6, md: 10 }}
            px={{ base: 4, md: 0 }}
          >
            <ProductCard
              title="Starter Kit"
              price={99}
              icon={FaTools}
              description="Perfect for new agents looking to establish their digital presence"
              toolsIncluded={[
                { name: "Email Marketing", icon: FaEnvelope },
                { name: "Landing Pages", icon: FaGlobe },
                { name: "Basic Analytics", icon: FaChartBar }
              ]}
              features={[
                "Up to 1,000 email contacts",
                "5 landing page templates",
                "Basic lead scoring",
                "Email support",
                "Basic CRM integration"
              ]}
            />
            
            <ProductCard
              title="Growth Suite"
              price={199}
              icon={FaRocket}
              isPopular={true}
              description="Comprehensive toolkit for growing real estate businesses"
              toolsIncluded={[
                { name: "Advanced Email", icon: FaEnvelope },
                { name: "AI Chatbot", icon: FaRobot },
                { name: "Social Media", icon: FaHashtag },
                { name: "Virtual Tours", icon: FaVideo }
              ]}
              features={[
                "Everything in Starter Kit",
                "Unlimited contacts",
                "AI-powered lead scoring",
                "Priority support",
                "Advanced automation",
                "Custom branding"
              ]}
            />
            
            <ProductCard
              title="Enterprise"
              price={399}
              icon={FaBuilding}
              description="Full-service solution for large teams and brokerages"
              toolsIncluded={[
                { name: "Team Dashboard", icon: FaUsers },
                { name: "Custom API", icon: FaCode },
                { name: "White Label", icon: FaPaintBrush },
                { name: "Advanced Security", icon: FaShieldAlt }
              ]}
              features={[
                "Everything in Growth Suite",
                "Dedicated account manager",
                "Custom development",
                "Team collaboration tools",
                "Enterprise API access",
                "99.9% uptime SLA"
              ]}
            />
          </SimpleGrid>
        </Box>

        <Box mt={20}>
          <Heading
            color="white"
            size="lg"
            textAlign="center"
            mb={12}
          >
            Comprehensive Lead Generation Toolbox
          </Heading>
          <SimpleGrid 
            columns={{ base: 1, md: 3 }}
            spacing={8}
            px={{ base: 4, md: 0 }}
            mb={16}
          >
            <ToolboxFeature
              icon={FaEnvelope}
              title="Email Marketing Automation"
              description="Transform your lead nurturing with AI-powered email campaigns that convert."
              features={[
                "Smart drip campaigns",
                "Personalized content",
                "Real-time analytics",
                "CRM integration"
              ]}
            />
            <ToolboxFeature
              icon={FaRobot}
              title="AI Lead Qualification"
              description="Let AI qualify your leads 24/7 with intelligent chatbot interactions."
              features={[
                "24/7 lead qualification",
                "Natural language processing",
                "Instant lead scoring",
                "Automated follow-ups"
              ]}
            />
            <ToolboxFeature
              icon={FaChartLine}
              title="Analytics Dashboard"
              description="Make data-driven decisions with comprehensive analytics and insights."
              features={[
                "Real-time metrics",
                "Conversion tracking",
                "Performance reports",
                "ROI analysis"
              ]}
            />
          </SimpleGrid>

          <SimpleGrid 
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={4}
            px={{ base: 4, md: 0 }}
          >
            <ToolboxFeature
              icon={FaLightbulb}
              title="Unlock Explosive Growth with Automated Email Marketing!"
              description="Develop a platform that allows realtors to create and manage email campaigns targeted at nurturing leads over time."
              features={[
                "Drip email campaigns with personalized content.",
                "Pre-designed templates tailored for real estate.",
                "Analytics dashboard showing open rates, click-throughs, and conversions.",
                "Integration with CRM systems for seamless data flow."
              ]}
            />
            <ToolboxFeature
              icon={FaTools}
              title="Build High-Converting Landing Pages in Minutes!"
              description="Create an easy-to-use tool for designing high-converting landing pages to capture leads."
              features={[
                "Drag-and-drop interface with customizable templates.",
                "A/B testing capabilities to optimize performance.",
                "Mobile-responsive design to cater to all devices.",
                "Integration with email marketing and CRM tools."
              ]}
            />
            <ToolboxFeature
              icon={FaRobot}
              title="Engage Visitors Instantly with AI-Powered Chatbots!"
              description="Implement chatbots on realtors' websites that engage visitors in real-time, answer common questions, and collect contact information."
              features={[
                "Natural language processing for more human-like interactions.",
                "24/7 availability to capture leads outside business hours.",
                "Pre-programmed responses for frequently asked questions.",
                "Data collection and direct input into CRM systems."
              ]}
            />
            <ToolboxFeature
              title="4. Property Valuation Tool"
              description="Offer an online tool where homeowners can receive an instant estimate of their property's value in exchange for their contact information."
              features={[
                "Integration with real estate databases for accurate valuations.",
                "Customizable branding for individual realtors or agencies.",
                "Automated follow-up emails with detailed reports.",
                "Option to schedule an in-person appraisal.",
              ]}
            />
            <ToolboxFeature
              title="5. Social Media Lead Generation Platform"
              description="Develop a tool that helps realtors create, schedule, and manage social media content and ads to attract leads."
              features={[
                "Social media scheduler supporting platforms like Facebook, Instagram, and LinkedIn.",
                "Ad creation wizard with targeting options.",
                "Analytics to track engagement and conversions.",
                "Lead capture forms integrated into social posts or ads.",
              ]}
            />
            <ToolboxFeature
              title="6. Open House Sign-In App"
              description="Create a digital sign-in app for open houses that collects visitor information and syncs it with the realtor's CRM."
              features={[
                "Customizable sign-in fields.",
                "Offline mode to function without internet access.",
                "Automated thank-you emails or text messages.",
                "Reporting on visitor traffic and interest levels.",
              ]}
            />
            <ToolboxFeature
              title="7. IDX Integration with Lead Capture"
              description="Provide tools that integrate MLS listings into a realtor's website, allowing visitors to search properties while capturing their information."
              features={[
                "Advanced search filters for property listings.",
                "Save search functionality requiring user sign-up.",
                "Automated alerts for new listings matching user criteria.",
                "Behavioral tracking to tailor follow-up communications.",
              ]}
            />
            <ToolboxFeature
              title="8. SEO Optimization Toolkit"
              description="Offer tools to help realtors optimize their websites and content for search engines to improve organic lead generation."
              features={[
                "Keyword research specific to real estate.",
                "On-page SEO audit and recommendations.",
                "Competitor analysis.",
                "Content optimization suggestions.",
              ]}
            />
            <ToolboxFeature
              title="9. Automated SMS Marketing"
              description="Implement a system for sending personalized text messages to leads for reminders, updates, or promotions."
              features={[
                "Bulk messaging with personalization tokens.",
                "Scheduling and automation workflows.",
                "Compliance with messaging regulations (e.g., opt-in/opt-out).",
                "Tracking of delivery rates and responses.",
              ]}
            />
            <ToolboxFeature
              title="10. Virtual Tour Creation Tool"
              description="Develop a platform that allows realtors to create and share virtual tours of properties to engage remote leads."
              features={[
                "360-degree panoramic stitching.",
                "Interactive hotspots with property details.",
                "Easy sharing via links or embedding on websites.",
                "VR compatibility for immersive experiences.",
              ]}
            />
            <ToolboxFeature
              title="11. Predictive Analytics for Lead Scoring"
              description="Use machine learning to analyze lead behavior and predict which leads are most likely to convert."
              features={[
                "Lead scoring based on engagement metrics.",
                "Segmentation of leads for targeted follow-ups.",
                "Integration with CRM for real-time updates.",
                "Visualization of lead pipeline health.",
              ]}
            />
            <ToolboxFeature
              title="12. Content Personalization Engine"
              description="Create a tool that personalizes website content based on visitor data to increase engagement."
              features={[
                "Dynamic content blocks that change based on user behavior.",
                "Personalized property recommendations.",
                "Greeting returning visitors with tailored messages.",
                "Analytics on content performance.",
              ]}
            />
            <ToolboxFeature
              title="13. Referral Management System"
              description="Develop a platform to track and manage client referrals, encouraging satisfied clients to refer new leads."
              features={[
                "Automated referral request emails.",
                "Tracking of referral sources and conversion rates.",
                "Reward system management for referrers.",
                "Reporting on referral program effectiveness.",
              ]}
            />
            <ToolboxFeature
              title="14. Event Marketing and Registration Tool"
              description="Offer tools to promote and manage events like webinars, seminars, or open houses aimed at lead generation."
              features={[
                "Event landing pages with registration forms.",
                "Email invitations and reminders.",
                "Attendance tracking.",
                "Post-event follow-up automation.",
              ]}
            />
            <ToolboxFeature
              title="15. Compliance and Data Privacy Tools"
              description="Provide features that help realtors comply with data protection regulations like GDPR and CAN-SPAM."
              features={[
                "Consent management for lead capture forms.",
                "Automated unsubscribe and data deletion processes.",
                "Audit logs for data handling activities.",
                "Educational resources on compliance best practices.",
              ]}
            />
          </SimpleGrid>
        </Box>

        <Box mt={20}>
          <Heading
            color="white"
            size="lg"
            textAlign="center"
            mb={12}
          >
            Choose Your Perfect CRM Solution
          </Heading>
          <SimpleGrid 
            columns={{ base: 1, md: 2 }}
            spacing={6}
            px={{ base: 4, md: 0 }}
          >
            <TierFeatureCard
              icon={FaUserTie}
              title="🔥 Solo Agent Success Starter Pack!"
              description="Perfect for ambitious solo agents ready to dominate their market"
              price={49}
              features={[
                "Smart contact management system",
                "Lead capture forms that convert",
                "Email integration with major providers",
                "Mobile-first task management",
                "Essential performance reports"
              ]}
            />
            <TierFeatureCard
              icon={FaUsers}
              title="⚡ Team Growth Accelerator Suite!"
              description="Supercharge your team's performance with advanced automation"
              price={99}
              features={[
                "Advanced contact segmentation",
                "Automated follow-up sequences",
                "Visual pipeline management",
                "MLS integration",
                "Team collaboration tools"
              ]}
            />
            <TierFeatureCard
              icon={FaBuilding}
              title="🚀 Premium Power Platform!"
              description="Enterprise-grade solutions for market-leading brokerages"
              price={199}
              features={[
                "Custom dashboards & workflows",
                "Advanced API access",
                "White-label options",
                "Dedicated account manager",
                "Premium integrations"
              ]}
            />
            <TierFeatureCard
              icon={FaGlobe}
              title="💎 Ultimate Enterprise Command Center!"
              description="Complete control and customization for industry leaders"
              features={[
                "Custom development options",
                "On-premises deployment",
                "24/7 priority support",
                "Advanced compliance features",
                "Comprehensive training program"
              ]}
            />
          </SimpleGrid>
        </Box>

        <VStack spacing={6} mt={20} textAlign="center">
          <Heading color="white" size="lg">
            Ready to Revolutionize Your Real Estate Business?
          </Heading>
          <Button
            leftIcon={<BsPeople />}
            colorScheme="orange"
            variant="outline"
            onClick={() => navigate('/leads/new')}
          >
            Get Started
          </Button>
        </VStack>

        <Text color="gray.400" textAlign="center" fontSize="sm">
          Last updated: {new Date().toLocaleDateString()}
        </Text>
      </Container>
    </Box>
  );
}

export default NewHomePage; 