// frontend/src/pages/Login.js

// This is the main login page component that handles:
// - Google sign-in
// - Email/password sign-in
// - Phone number sign-in
// - Cool matrix-style background animation
// - Form validation
// - Error handling
// - User onboarding for new users

// The page has a "hold to enter" feature and styled components for a cyberpunk look
// It communicates with Firebase for authentication and the backend for user data

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Button, Flex, Heading, Text, VStack, Box, Input, useToast } from '@chakra-ui/react';
import { auth, provider, fetchSignInMethodsForEmail, signInWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier } from '../firebase';
import { signInWithPopup, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useAuth } from '../context/AuthContext'; // Import useAuth hook
import ProfileConfirmationModal from '../components/ProfileConfirmationModal';
import { useTheme } from '../context/ThemeContext'; // Add this import
import { onAuthStateChanged } from 'firebase/auth';
import { getDeviceFingerprint } from '../utils/fingerprint';

// Add a custom font for a more unique look
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Matrix Code NFI';
    src: url('/path-to-your-font/matrix-code-nfi.ttf') format('truetype');
  }
`;

const matrixAnimation = keyframes`
  0% { 
    transform: translateY(-100%); 
    opacity: 0; 
  }
  10% { 
    opacity: 1; 
  }
  90% { 
    opacity: 1; 
    transform: translateY(85%); 
  }
  100% { 
    transform: translateY(100%); 
    opacity: 0; 
  }
`;

const MatrixBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
  background-color: #000;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const MatrixColumn = styled.div`
  position: absolute;
  top: 0;
  left: ${props => props.left}%;
  font-family: 'Courier New', monospace;
  font-size: ${props => props.size}px;
  line-height: 1;
  color: #FFA500; // Changed to orange
  text-shadow: 0 0 8px #FFA500; // Changed shadow to match
  white-space: pre;
  animation: ${matrixAnimation} ${props => props.duration}s linear infinite;
  animation-delay: ${props => props.delay}s;
  opacity: ${props => props.opacity};
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;

const StyledButton = styled(Button)`
  background: transparent;
  border: 2px solid #FFA500;
  color: #FFA500;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  height: 48px; // Fixed height for consistency
  width: 100%; // Full width
  font-size: 1rem; // Consistent font size

  // Override Chakra UI's default styles
  &:not(:disabled) {
    background: transparent;
    color: #FFA500;
  }

  &:hover, &:focus {
    background: rgba(255, 165, 0, 0.2);
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(255, 165, 0, 0.4);
  }

  // Glow effect on hover
  &:hover::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: none;
    border-radius: inherit;
    box-shadow: 0 0 10px 2px #FFA500;
    opacity: 0;
    animation: glowing 2s ease-in-out infinite;
  }

  @keyframes glowing {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

const InfoText = styled(Text)`
  color: #FFA500;
  font-size: 0.8rem;
  text-align: center;
  opacity: 0.8;
`;

const LoginContainer = styled(VStack)`
  transform: perspective(1000px) rotateX(5deg);
  transition: transform 0.3s ease;
  width: min(500px, 90vw);
  padding: 1rem;

  &:hover {
    transform: perspective(1000px) rotateX(0deg);
  }
`;

const FixedWidthHeading = styled(Heading)`
  width: min(500px, 90vw); // Use min() for responsive width
  height: auto; // Remove fixed height
  min-height: 60px; // Minimum height for smaller screens
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  white-space: normal; // Allow text wrapping on mobile
  font-size: clamp(1.2rem, 4vw, 1.8rem); // Responsive font size
`;

const PulsatingBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #000000, #1a1a1a);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  z-index: -1;

  @keyframes gradientBG {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const StyledInput = styled(Input)`
  width: 100% !important;
  color: #FFA500 !important;
  border-color: #FFA500 !important;
  background: rgba(0, 0, 0, 0.8) !important;
  margin-bottom: 0;
  
  &::placeholder {
    color: rgba(255, 165, 0, 0.6);
  }

  &:focus {
    border-color: #FFD700 !important;
    box-shadow: 0 0 0 1px #FFD700 !important;
  }
`;

const FormContainer = styled(VStack)`
  width: 100%;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  
  // Make all inputs consistent
  input {
    height: 48px !important;
    width: 100% !important;
    margin: 0 !important;
  }

  // Make all buttons consistent
  button {
    height: 48px !important;
    width: 100% !important;
    margin: 0 !important;
  }
`;

// Add new styled component for section separation
const LoginSection = styled(VStack)`
  width: 100%;
  padding: 0;
  border-top: 1px solid rgba(255, 165, 0, 0.3);
  margin-top: 0.5rem !important;
  gap: 0.5rem;
  
  &:first-of-type {
    border-top: none;
    margin-top: 0 !important;
  }

  // Make all buttons consistent
  button {
    height: 48px !important;
    width: 100% !important;
    margin: 0 !important;
  }
`;

const SectionTitle = styled(Text)`
  color: #FFA500;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
`;

// Add new styled component for the hold instruction
const HoldInstruction = styled(Text)`
  color: #FFA500;
  font-size: 1.2rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.3s ease;
`;

// Add new styled component for button groups
const AuthButtonGroup = styled(VStack)`
  width: 100%;
  gap: 1rem;
  position: relative;

  // Make all buttons consistent
  button {
    height: 48px !important;
    width: 100% !important;
    margin: 0 !important;
  }
`;

// Add new styled component for the background image layer
const BackgroundImageLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/path-to-your-image.jpg'); // Replace with your image path
  background-size: cover;
  background-position: center;
  opacity: ${props => props.progress};
  transition: opacity 0.3s ease;
  z-index: 0;
  pointer-events: none;
`;

// Add these validation functions at the top
const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) return "Email is required";
  if (!regex.test(email)) return "Invalid email format";
  return "";
};

const validatePassword = (password) => {
  if (!password) return "Password is required";
  if (password.length < 6) return "Password must be at least 6 characters";
  return "";
};

const validatePhone = (phone) => {
  const regex = /^\+[1-9]\d{1,14}$/;
  if (!phone) return "Phone number is required";
  if (!regex.test(phone)) return "Invalid phone format (e.g., +16473711555)";
  return "";
};

// Add this function to help debug
const debugCheckEmail = async (email) => {
  try {
    const response = await axios.post('/api/auth/check-email', { email });
    console.log('🔍 Email check results:', response.data);
    return response.data;
  } catch (error) {
    console.error(' Email check failed:', error);
    return null;
  }
};

// Add new styled components
const SignInOption = styled(StyledButton)`
  height: 48px; // Match Google button height
  width: 100% !important; // Force full width
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  gap: 12px;
  margin: 0 !important; // Remove any margin
  
  // Icon styling
  svg {
    font-size: 20px;
    width: 20px;
    flex-shrink: 0; // Prevent icon from shrinking
  }

  // Text styling
  span {
    flex-grow: 0;
    text-align: center;
  }
`;

const AnimatedSection = styled(LoginSection)`
  max-height: ${props => props.isOpen ? '400px' : '0'};
  opacity: ${props => props.isOpen ? '1' : '0'};
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: ${props => props.isOpen ? '0.5rem 0 0 0' : '0'};
  margin: 0 !important;
  border: none;
  
  // Ensure form elements are properly spaced
  form {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const Login = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { currentUser, isNewUser, profileComplete } = useAuth();
  const toast = useToast();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [pendingUserData, setPendingUserData] = useState(null);
  
  const { currentTheme } = useTheme(); // Add this hook

  const [mfaVerification, setMfaVerification] = useState({
    required: false,
    method: null,
    verificationId: null
  });

  // Add new state for hold interaction
  const [isHolding, setIsHolding] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const holdTimerRef = useRef(null);
  const [holdProgress, setHoldProgress] = useState(0);

  // Add new state variables at the start of the Login component
  const [showEmailFields, setShowEmailFields] = useState(false);
  const [showPhoneFields, setShowPhoneFields] = useState(false);

  // Add hold interaction handlers
  const handleHoldStart = (e) => {
    // Prevent default behavior for touch events
    e.preventDefault();
    
    setIsHolding(true);
    const startTime = Date.now();
    
    holdTimerRef.current = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / 1000, 1);
      setHoldProgress(progress);
      
      if (progress >= 1) {
        clearInterval(holdTimerRef.current);
        setShowContent(true);
      }
    }, 50);
  };

  const handleHoldEnd = () => {
    setIsHolding(false);
    clearInterval(holdTimerRef.current);
    if (holdProgress < 1) {
      setHoldProgress(0);
      setShowContent(false);
    }
  };

  // Clean up timer on unmount
  useEffect(() => {
    return () => {
      if (holdTimerRef.current) {
        clearInterval(holdTimerRef.current);
      }
    };
  }, []);

  // Effect to handle new user detection
  useEffect(() => {
    if (isNewUser && currentUser) {
      let firstName = '', lastName = '';
      if (currentUser.displayName) {
        const nameParts = currentUser.displayName.split(' ');
        firstName = nameParts[0] || '';
        lastName = nameParts.slice(1).join(' ') || '';
      } else {
        firstName = currentUser.email.split('@')[0];
      }

      const initialData = {
        firstName,
        lastName,
        email: currentUser.email,
        phoneNumber: currentUser.phoneNumber || '',
        photoURL: currentUser.photoURL,
        organizationName: `${firstName}'s Organization`
      };

      setPendingUserData({ initialData });
      setShowProfileModal(true);
    }
  }, [isNewUser, currentUser]);

  // Handle profile confirmation
  const handleProfileConfirm = async (profileData) => {
    try {
      setIsSubmitting(true);
      console.log('📝 Processing profile confirmation...', profileData);
      
      if (profileData.success) {
        console.log('✅ Profile created successfully');
        toast({
          title: 'Success!',
          description: 'Your profile has been created.',
          status: 'success',
          duration: 5000,
        });
        
        // Add a small delay before navigation
        setTimeout(() => {
          navigate('/dashboard');
        }, 500);
      }
    } catch (error) {
      console.error('❌ Profile Creation Error:', error);
      toast({
        title: 'Profile Creation Failed',
        description: error.response?.data?.message || error.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle MFA verification
  const handleMultiFactorAuth = async (user) => {
    try {
      console.log('🔐 Setting up MFA verification...');
      if (user.phoneNumber) {
        const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: 'invisible',
          callback: () => {
            console.log(' reCAPTCHA verified');
          }
        });
        
        const confirmationResult = await signInWithPhoneNumber(
          auth, 
          user.phoneNumber, 
          recaptchaVerifier
        );
        
        setMfaVerification({
          required: true,
          method: 'phone',
          verificationId: confirmationResult
        });
        
        toast({
          title: 'Verification Required',
          description: 'Please enter the code sent to your phone',
          status: 'info',
          duration: 5000,
        });
      }
    } catch (error) {
      console.error('❌ MFA Setup Error:', error);
      toast({
        title: 'MFA Setup Failed',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    }
  };

  // Enhanced Google Sign In
  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // Check if email matches allowed email
      if (user.email !== 'sasha@property.ca') {
        await signOut(auth); // Sign out unauthorized user
        toast({
          title: 'Access Denied',
          description: 'Only authorized personnel may access this application.',
          status: 'error',
          duration: 5000,
        });
        return;
      }
      
      // Continue with existing logic for authorized user
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      console.log('✨ Firebase sign-in successful!', {
        uid: user.uid,
        email: user.email,
        isNewUser: result._tokenResponse?.isNewUser
      });
      
      if (result._tokenResponse?.isNewUser) {
        console.log('🆕 New user detected, preparing profile data');
        setPendingUserData({
          initialData: {
            firstName: user.displayName?.split(' ')[0] || '',
            lastName: user.displayName?.split(' ').slice(1).join(' ') || '',
            email: user.email,
            phoneNumber: '',
            photoURL: user.photoURL,
            password: '', // Required for additional security
            confirmPassword: '',
            organizationName: `${user.displayName?.split(' ')[0] || 'New'}'s Organization`,
            authMethod: 'google'
          }
        });
        setShowProfileModal(true);
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('❌ Google Sign-in Error:', error);
      toast({
        title: 'Sign-in Error',
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  // Add error handling UI
  const handleError = () => {
    if (error) {
      return (
        <Text color="red.500" fontSize="sm">
          {error}
        </Text>
      );
    }
    return null;
  };

  const [matrixColumns, setMatrixColumns] = useState([]);

  useEffect(() => {
    const columns = [];
    // Reduce column count on mobile
    const columnCount = window.innerWidth < 768 ? 100 : 200;

    for (let i = 0; i < columnCount; i++) {
      columns.push({
        left: Math.random() * 100,
        duration: 15 + Math.random() * 20,
        delay: Math.random() * -20,
        characters: generateRandomCharacters(),
        size: 8 + Math.floor(Math.random() * 12), // Smaller font size range
        opacity: 0.5 + Math.random() * 0.5
      });
    }

    setMatrixColumns(columns);
  }, []);

  function generateRandomCharacters() {
    const interestingAffirmations = [
      "I Turn Houses Into Dream Homes",
      "My Negotiation Skills Are Legendary",
      "I'm The Architect Of Financial Futures",
      "I Don't Sell Properties, I Fulfill Destinies",
      "My Market Insights Are Razor-Sharp",
      "I Transform Neighborhoods One Sale At A Time",
      "My Network Is My Net Worth",
      "I'm The Sherlock Holmes Of Real Estate",
      "I Don't Chase Success, I Attract It",
      "My Clients Call Me The Deal Whisperer",
      "I'm Not In Real Estate, I'm In The People Business",
      "I Create Win-Win Scenarios In My Sleep",
      "My Business Card Reads: Opportunity Maker",
      "I'm The Michelangelo Of Home Staging",
      "I Don't Break Records, I Set New Standards",
      "My Persistence Moves Mountains And Closes Deals",
      "I'm The Alchemist Of Property Value",
      "My Handshake Is As Solid As My Contracts",
      "I Navigate Market Trends Like A Seasoned Captain",
      "I Don't Just Meet Expectations, I Redefine Them",
      "My Clients' Dreams Are My Blueprint For Success",
      "I'm The Maestro Of Mortgage Solutions",
      "I Turn 'For Sale' Into 'Sold' With Finesse",
      "My Intuition Is My Secret Weapon In Negotiations",
      "I'm The Bridge Between Houses And Happily Ever Afters",
      "My Optimism Is Contagious And Closes Deals",
      "I'm Not Just An Agent, I'm A Lifestyle Curator",
      "My Words Turn Skeptics Into Believers",
      "I Don't Just Sell Homes, I Craft Legacies",
      "My Determination Turns Challenges Into Triumphs"
    ];
    
    // Select a random affirmation
    const affirmation = interestingAffirmations[Math.floor(Math.random() * interestingAffirmations.length)];
    
    // Create a vertical string of characters from the affirmation
    return affirmation.split('').join('\n');
  }

  // Add state for email/password form
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState(null);

  // Add these state variables for validation and feedback
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [codeError, setCodeError] = useState('');

  // Add this function near your other helper functions
  const checkUserExists = async (email) => {
    try {
      // First check Firebase
      const firebaseMethods = await fetchSignInMethodsForEmail(auth, email);
      
      // If user exists in Firebase, return true
      if (firebaseMethods.length > 0) {
        return true;
      }

      // If not in Firebase, check MongoDB through our backend
      const mongoResponse = await axios.post('/api/auth/check-email', { email });
      return mongoResponse.data.exists;
    } catch (error) {
      console.error('Error checking user existence:', error);
      return false;
    }
  };

  // Update handleEmailSignIn with better user existence checking
  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    
    // Reset errors
    setEmailError("");
    setPasswordError("");
    setLoading(true);
    
    try {
      console.log('🔍 Starting email sign-in process');
      
      // Check both Firebase and MongoDB
      const exists = await checkUserExists(email);
      
      if (!exists) {
        setEmailError('No account found with this email');
        setLoading(false);
        return;
      }

      // Try Firebase sign in
      try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        console.log('✅ Firebase sign-in successful');
      } catch (firebaseError) {
        // If Firebase auth fails, but user exists in MongoDB
        if (firebaseError.code === 'auth/user-not-found') {
          // Create Firebase account
          try {
            await axios.post('/api/auth/create-firebase-account', {
              email,
              password
            });
            // Retry Firebase sign in
            await signInWithEmailAndPassword(auth, email, password);
          } catch (error) {
            console.error('Failed to create Firebase account:', error);
            throw error;
          }
        } else {
          throw firebaseError;
        }
      }

      // Check if profile needs to be created
      const userResponse = await axios.get('/api/users/profile');
      if (userResponse.data.shouldCreateProfile) {
        setShowProfileModal(true);
        setPendingUserData({
          initialData: {
            email,
            firstName: email.split('@')[0],
            lastName: '',
            authMethod: 'password',
            authMethods: ['password']
          }
        });
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('❌ Sign-in error:', error);
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  // Update the handleAuthError function
  const handleAuthError = (error) => {
    console.log('🚨 Auth error code:', error.code); // Add debug logging
    
    switch (error.code) {
      case 'auth/invalid-credential':
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        setEmailError('Invalid email or password');
        break;
      case 'auth/too-many-requests':
        setEmailError('Too many attempts. Please try again later');
        break;
      default:
        setEmailError(error.message);
    }
  };

  // Update phone sign-in handler
  const handlePhoneSignIn = async (e) => {
    e.preventDefault();
    setPhoneError("");
    
    const phoneValidation = validatePhone(phoneNumber);
    if (phoneValidation) {
      setPhoneError(phoneValidation);
      return;
    }
    
    setLoading(true);
    try {
      const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: () => {
          console.log('✅ reCAPTCHA verified');
        },
        'expired-callback': () => {
          toast({
            title: 'reCAPTCHA expired',
            description: 'Please try again',
            status: 'warning',
            duration: 3000,
          });
        }
      });

      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
      setVerificationId(confirmationResult);
      
      toast({
        title: 'Code Sent!',
        description: 'Please check your phone for the verification code',
        status: 'success',
        duration: 5000,
      });
    } catch (error) {
      console.error('Phone sign-in error:', error);
      handlePhoneAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  // Add phone-specific error handler
  const handlePhoneAuthError = (error) => {
    switch (error.code) {
      case 'auth/invalid-phone-number':
        setPhoneError('Invalid phone number format');
        break;
      case 'auth/quota-exceeded':
        toast({
          title: 'Too many attempts',
          description: 'Please try again later',
          status: 'error',
          duration: 5000,
        });
        break;
      default:
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
        });
    }
  };

  // Update handleVerifyCode with better validation
  const handleVerifyCode = async () => {
    setCodeError('');
    
    if (!verificationCode || verificationCode.length !== 6) {
      setCodeError('Please enter a valid 6-digit code');
      return;
    }
    
    setLoading(true);
    try {
      const result = await verificationId.confirm(verificationCode);
      console.log('Phone verification successful:', result.user.phoneNumber);
      
      toast({
        title: 'Success!',
        description: 'Phone number verified successfully',
        status: 'success',
        duration: 3000,
      });
      navigate('/dashboard');
    } catch (error) {
      console.error('Code verification error:', error);
      
      switch (error.code) {
        case 'auth/invalid-verification-code':
          setCodeError('Invalid verification code');
          break;
        case 'auth/code-expired':
          setCodeError('Verification code has expired');
          break;
        default:
          toast({
            title: 'Verification Error',
            description: error.message,
            status: 'error',
            duration: 5000,
          });
      }
    } finally {
      setLoading(false);
    }
  };

  // Add debug logging for modal state
  useEffect(() => {
    console.log('Modal state updated:', {
      showProfileModal,
      pendingUserData: !!pendingUserData,
      isNewUser,
      hasCurrentUser: !!currentUser
    });
  }, [showProfileModal, pendingUserData, isNewUser, currentUser]);

  // Add this state
  const [showCreateAccount, setShowCreateAccount] = useState(false);

  // Add this at the start of your Login component
  useEffect(() => {
    // Check if user is already authenticated
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('🔐 Auth State Changed:', user ? {
        uid: user.uid,
        email: user.email,
        emailVerified: user.emailVerified
      } : 'No user');
    });

    return () => unsubscribe();
  }, []);

  // Add new handlers
  const toggleEmailFields = () => {
    setShowEmailFields(!showEmailFields);
    setShowPhoneFields(false); // Close phone fields if open
  };

  const togglePhoneFields = () => {
    setShowPhoneFields(!showPhoneFields);
    setShowEmailFields(false); // Close email fields if open
  };

  return (
    <>
      <GlobalStyle />
      <Flex
        direction="column"
        height="100vh"
        bg="black"
        position="relative"
        overflow="hidden"
        {...(!showContent && {
          onMouseDown: handleHoldStart,
          onMouseUp: handleHoldEnd,
          onMouseLeave: handleHoldEnd,
          onTouchStart: handleHoldStart,
          onTouchEnd: handleHoldEnd,
          onTouchCancel: handleHoldEnd,
        })}
      >
        {/* Add background image layer */}
        <BackgroundImageLayer progress={holdProgress} />
        
        {/* Existing Matrix background */}
        <MatrixBackground style={{ opacity: 1 - holdProgress }}>
          {matrixColumns.map((column, index) => (
            <MatrixColumn
              key={index}
              left={column.left}
              duration={column.duration}
              delay={column.delay}
              size={column.size}
              opacity={column.opacity}
            >
              {column.characters}
            </MatrixColumn>
          ))}
        </MatrixBackground>

        {/* Pulsating background */}
        <PulsatingBackground />
        
        {/* Only show hold instruction if content isn't shown */}
        {!showContent && (
          <HoldInstruction show={true}>
            {isHolding ? `Take a deep breath and hold...` : 'x'}
          </HoldInstruction>
        )}

        {/* Main content */}
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          position="relative"
          opacity={showContent ? 1 : 0}
          transform={`scale(${showContent ? 1 : 0.95})`}
          transition="opacity 0.5s ease, transform 0.5s ease"
          pointerEvents={showContent ? 'auto' : 'none'} // Prevent interaction while hidden
        >
          <LoginContainer 
            spacing={4}
            p={[4, 6, 12]}
            rounded={6} 
            bg="rgba(0, 0, 0, 0.6)" 
            backdropFilter="blur(10px)"
            border="1px solid"
            borderColor={currentTheme.colors.primaryAccent} // Use the theme color here
          >
            <FixedWidthHeading 
              color="white" 
              fontFamily="'Matrix Code NFI', sans-serif"
              fontSize="3xl"
            >
              Welcome Back Home
            </FixedWidthHeading>

            <AuthButtonGroup>
              <StyledButton 
                onClick={handleGoogleSignIn}
                isLoading={loading}
                loadingText="Signing in..."
                leftIcon={<FontAwesomeIcon icon={faGoogle} />}
                width="100%"
              >
                Continue with Google
              </StyledButton>
            </AuthButtonGroup>

            {/* Email Option */}
            <LoginSection>
              <SignInOption
                onClick={toggleEmailFields}
                width="100%"
                isOpen={showEmailFields}
                leftIcon={<FontAwesomeIcon icon={faEnvelope} />}
              >
                Sign In with Email
              </SignInOption>
              
              <AnimatedSection isOpen={showEmailFields}>
                <form onSubmit={handleEmailSignIn} style={{ width: '100%' }}>
                  <FormContainer>
                    <StyledInput
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError('');
                      }}
                      onBlur={async () => {
                        if (email && !emailError) {
                          const exists = await checkUserExists(email);
                          if (!exists) {
                            setEmailError('No account found with this email');
                          }
                        }
                      }}
                      isInvalid={!!emailError}
                    />
                    {emailError && <Text color="red.500" fontSize="sm">{emailError}</Text>}
                    
                    <StyledInput
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      isInvalid={!!passwordError}
                    />
                    {passwordError && <Text color="red.500" fontSize="sm">{passwordError}</Text>}
                    <StyledButton 
                      type="submit" 
                      width="100%" 
                      isLoading={loading}
                      size="lg"
                    >
                      Sign in
                    </StyledButton>
                  </FormContainer>
                </form>
              </AnimatedSection>
            </LoginSection>

            {/* Phone Option */}
            <LoginSection>
              <SignInOption
                onClick={togglePhoneFields}
                width="100%"
                isOpen={showPhoneFields}
                leftIcon={<FontAwesomeIcon icon={faPhoneAlt} />}
              >
                Sign In with Phone
              </SignInOption>
              
              <AnimatedSection isOpen={showPhoneFields}>
                <FormContainer>
                  <StyledInput
                    type="tel"
                    placeholder="Phone Number (e.g., +1234567890)"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    isInvalid={!!phoneError}
                  />
                  {phoneError && <Text color="red.500" fontSize="sm">{phoneError}</Text>}
                  
                  {verificationId && (
                    <>
                      <StyledInput
                        type="text"
                        placeholder="Enter 6-digit code"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        isInvalid={!!codeError}
                        maxLength={6}
                      />
                      {codeError && <Text color="red.500" fontSize="sm">{codeError}</Text>}
                    </>
                  )}
                  {!verificationId ? (
                    <StyledButton 
                      type="button"
                      width="100%" 
                      onClick={handlePhoneSignIn}
                      isLoading={loading}
                      size="lg"
                    >
                      Send Verification Code
                    </StyledButton>
                  ) : (
                    <StyledButton 
                      type="button"
                      width="100%" 
                      onClick={handleVerifyCode}
                      isLoading={loading}
                      size="lg"
                    >
                      Verify Code
                    </StyledButton>
                  )}
                </FormContainer>
              </AnimatedSection>
            </LoginSection>

            <div id="recaptcha-container"></div>
            {handleError()}
            
            <InfoText>
              You'll be redirected to Google's secure login page.
            </InfoText>
          </LoginContainer>
        </Flex>
      </Flex>
      
      {showProfileModal && pendingUserData && (
        <ProfileConfirmationModal
          isOpen={showProfileModal}
          onClose={() => setShowProfileModal(false)}
          initialData={pendingUserData.initialData}
          onConfirm={handleProfileConfirm}
        />
      )}
    </>
  );
}

export default Login;
