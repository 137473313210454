import React from 'react';
import { Box, Flex, Text, Link, Input, Table, Thead, Tbody, Tr, Th, Td, Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { FaUsers, FaInbox, FaTasks, FaCalendarAlt, FaBriefcase, FaChartBar, FaCog, FaBell, FaPhone, FaUserPlus, FaEnvelope, FaSms, FaChevronDown, FaInfoCircle } from 'react-icons/fa';

function PrimaryMenu() {
  return (
    <Box bg="#2C3E50" p={4} px={8}>
      <Flex justify="space-between" align="center">
        <Flex>
          <Link href="#" mr={6} color="gray.300" _hover={{ color: "white" }}>
            <Flex align="center">
              <FaUsers style={{ marginRight: '8px' }} />
              People
            </Flex>
          </Link>
          <Link href="#" mr={6} color="gray.300" _hover={{ color: "white" }} position="relative">
            <Flex align="center">
              <FaInbox style={{ marginRight: '8px' }} />
              Inbox
              <Box
                as="span"
                position="absolute"
                top="-2px"
                right="-2px"
                bg="red.500"
                borderRadius="full"
                boxSize="8px"
              />
            </Flex>
          </Link>
          <Link href="#" mr={6} color="gray.300" _hover={{ color: "white" }}>
            <Flex align="center">
              <FaTasks style={{ marginRight: '8px' }} />
              Tasks
            </Flex>
          </Link>
          <Link href="#" mr={6} color="gray.300" _hover={{ color: "white" }}>
            <Flex align="center">
              <FaCalendarAlt style={{ marginRight: '8px' }} />
              Calendar
            </Flex>
          </Link>
          <Link href="#" mr={6} color="gray.300" _hover={{ color: "white" }}>
            <Flex align="center">
              <FaBriefcase style={{ marginRight: '8px' }} />
              Deals
            </Flex>
          </Link>
          <Link href="#" mr={6} color="gray.300" _hover={{ color: "white" }}>
            <Flex align="center">
              <FaChartBar style={{ marginRight: '8px' }} />
              Reporting
            </Flex>
          </Link>
          <Link href="#" mr={6} color="gray.300" _hover={{ color: "white" }}>
            <Flex align="center">
              <FaCog style={{ marginRight: '8px' }} />
              Admin
            </Flex>
          </Link>
        </Flex>
        <Flex align="center">
          <Input
            placeholder="Search"
            size="sm"
            width="250px"
            mr={4}
            bg="white"
            borderRadius="full"
            boxShadow="md"
            _placeholder={{ color: 'gray.500' }}
          />
          <Box bg="lightcoral" borderRadius="full" p={2} mr={2}>
            <FaBell style={{ color: 'white', cursor: 'pointer' }} />
          </Box>
          <Box bg="lightseagreen" borderRadius="full" p={2} mr={2}>
            <FaPhone style={{ color: 'white', cursor: 'pointer' }} />
          </Box>
          <Box bg="lightblue" borderRadius="full" p={2} mr={2}>
            <FaEnvelope style={{ color: 'white', cursor: 'pointer' }} />
          </Box>
          <Box bg="lightpink" borderRadius="full" p={2} mr={2}>
            <FaSms style={{ color: 'white', cursor: 'pointer' }} />
          </Box>
          <Box bg="lightskyblue" borderRadius="full" p={2}>
            <FaUserPlus style={{ color: 'white', cursor: 'pointer' }} />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

function SecondaryMenu() {
  const menuItems = [
    { name: "Overview", href: "#" },
    { name: "Business Registration", href: "#" },
    { name: "Lead Flow", href: "#" },
    { name: "Groups", href: "#" },
    { name: "Teams", href: "#" },
    { name: "Action Plans", href: "#" },
    { name: "Automations", href: "#" },
    { name: "Ponds", href: "#" },
    { name: "Email Templates", href: "#", isActive: true },
    { name: "Text Templates", href: "#" },
    { name: "Import", href: "#" },
    { name: "Custom Fields", href: "#" },
    { name: "Calling", href: "#" },
    { name: "Stages", href: "#" },
    { name: "Phone Numbers", href: "#" },
  ];

  const moreMenuItems = [
    "Tags",
    "Integration",
    "Company",
    "API",
    "Appointments",
    "Email Domain Authentication",
  ];

  return (
    <Box bg="white" p={4} px={8} boxShadow="md">
      <Flex justify="space-between" align="center">
        <Flex wrap="wrap">
          {menuItems.map((item, index) => (
            <Link
              key={index}
              href={item.href}
              mr={4}
              color="gray.700"
              fontWeight="medium"
              _hover={{ color: "blue.500" }}
              borderBottom={item.isActive ? "2px" : "none"}
              borderColor={item.isActive ? "blue.500" : "transparent"}
            >
              {item.name}
            </Link>
          ))}
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<FaChevronDown />}
              variant="ghost"
              fontWeight="medium"
              color="gray.700"
              bg="white"
              _hover={{ color: "blue.500", bg: "gray.50" }}
              _active={{ bg: "gray.100" }}
              border="1px solid"
              borderColor="gray.200"
              borderRadius="md"
            >
              More
            </MenuButton>
            <MenuList bg="white" boxShadow="lg" borderRadius="md">
              {moreMenuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  color="gray.700"
                  _hover={{ color: "blue.500", bg: "gray.50" }}
                >
                  {item}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        <Button
          colorScheme="blue"
          variant="solid"
          leftIcon={<FaInfoCircle />}
          bg="lightblue"
          _hover={{ bg: "blue.300" }}
        >
          Admin Panel More Info
        </Button>
      </Flex>
    </Box>
  );
}

function FFUBDash() {
  const mockTemplates = Array.from({ length: 119 }, (_, index) => ({
    name: `${index + 1} - Mock Template`,
    subject: `Subject for template ${index + 1}`,
    sent: Math.floor(Math.random() * 100),
    opens: `${Math.floor(Math.random() * 100)} • ${Math.floor(Math.random() * 100)}%`,
    clicks: `${Math.floor(Math.random() * 100)} • ${Math.floor(Math.random() * 100)}%`,
    replies: `${Math.floor(Math.random() * 100)} • ${Math.floor(Math.random() * 100)}%`,
    unsubscribes: `${Math.floor(Math.random() * 100)} • ${Math.floor(Math.random() * 100)}%`,
  }));

  return (
    <Box>
      <PrimaryMenu />
      <SecondaryMenu />
      <Box p={8} bg="gray.100" minH="100vh">
        <Flex justify="space-between" align="center" mb={4}>
          <Text fontSize="lg" fontWeight="bold" color="black">{mockTemplates.length} Email Templates</Text>
          <Button colorScheme="blue">+ Add Template</Button>
        </Flex>
        <Table variant="simple" bg="white" borderRadius="md" boxShadow="md">
          <Thead>
            <Tr>
              <Th color="black">Name</Th>
              <Th color="black">Subject</Th>
              <Th color="black">Sent</Th>
              <Th color="black">Opens</Th>
              <Th color="black">Clicks</Th>
              <Th color="black">Replies</Th>
              <Th color="black">Unsubscribes</Th>
              <Th color="black">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {mockTemplates.map((template, index) => (
              <Tr key={index}>
                <Td color="black">{template.name}</Td>
                <Td><Link color="blue.500">{template.subject}</Link></Td>
                <Td color="black">{template.sent}</Td>
                <Td color="black">{template.opens}</Td>
                <Td color="black">{template.clicks}</Td>
                <Td color="black">{template.replies}</Td>
                <Td color="black">{template.unsubscribes}</Td>
                <Td><Button size="sm" variant="ghost">Edit</Button></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}

export default FFUBDash; 