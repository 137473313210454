import React, { useEffect, useState, useRef } from 'react';
import { 
  Box, Text, VStack, Divider, Tabs, TabList, TabPanels, Tab, TabPanel,
  SimpleGrid, Badge, Heading, Card, CardHeader, CardBody, Stack,
  useColorModeValue, Button, useToast, Table, Thead, Tbody, Tr, Th, Td,
  Spinner,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter,
  FormControl, FormLabel, Input, Select, IconButton, Tooltip,
  useDisclosure, AlertDialog, AlertDialogContent, AlertDialogHeader,
  AlertDialogBody, AlertDialogFooter, AlertDialogOverlay, Checkbox, HStack, ButtonGroup, Switch
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon, LinkIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import { validateAndFormatPhoneNumber } from '../utils/phoneUtils';

function Debugging() {
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const { currentUser } = useAuth();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const { isOpen: isUserModalOpen, onOpen: onUserModalOpen, onClose: onUserModalClose } = useDisclosure();
  const { isOpen: isOrgModalOpen, onOpen: onOrgModalOpen, onClose: onOrgModalClose } = useDisclosure();
  const { isOpen: isDeleteAlertOpen, onOpen: onDeleteAlertOpen, onClose: onDeleteAlertClose } = useDisclosure();
  const cancelRef = useRef();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [isOrgDeleteConfirmOpen, setIsOrgDeleteConfirmOpen] = useState(false);
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
  const [isBulkOrgEditModalOpen, setIsBulkOrgEditModalOpen] = useState(false);
  const [bulkEditData, setBulkEditData] = useState({});
  const [bulkOrgEditData, setBulkOrgEditData] = useState({});
  const { isDark, currentTheme } = useTheme();
  const [currentUserFull, setCurrentUserFull] = useState(null);
  
  const cardBg = useColorModeValue(currentTheme.colors.card.light, currentTheme.colors.card.dark);
  const borderColor = useColorModeValue(currentTheme.colors.border.light, currentTheme.colors.border.dark);
  const bgColor = useColorModeValue(currentTheme.colors.bg.light, currentTheme.colors.bg.dark);
  const textColor = useColorModeValue(currentTheme.colors.text.light, currentTheme.colors.text.dark);

  const cardStyles = {
    bg: cardBg,
    borderColor: borderColor,
    borderWidth: '1px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  };

  useEffect(() => {
    fetchData();
    fetchCurrentUserFull();
  }, []);

  const fetchData = async () => {
    try {
      const [usersResponse, orgsResponse] = await Promise.all([
        axios.get('/api/users/all'),
        axios.get('/api/organizations/all')
      ]);
      setUsers(usersResponse.data.users);
      setOrganizations(orgsResponse.data);
      setLoading(false);
    } catch (err) {
      console.error('Failed to fetch data:', err);
      setError(err.response?.data?.message || 'Failed to fetch data');
      setLoading(false);
      toast({
        title: 'Error',
        description: err.response?.data?.message || 'Failed to load data',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCurrentUserFull = async () => {
    try {
      const response = await axios.get('/api/users/profile');
      setCurrentUserFull(response.data.user);
    } catch (err) {
      console.error('Failed to fetch current user:', err);
      toast({
        title: 'Error',
        description: 'Failed to load current user data',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getSubscriptionColor = (subscription) => {
    if (!subscription?.status) return 'gray';
    switch (subscription.status) {
      case 'active':
        return 'green';
      case 'trial':
        return 'blue';
      default:
        return 'red';
    }
  };

  const getSubscriptionStatus = (org) => {
    return org?.subscription?.status || 'unknown';
  };

  const handleUserEdit = async (userData) => {
    try {
      console.log('Updating user:', userData);
      
      // Format phone number if present
      if (userData.phoneNumber) {
        userData.phoneNumber = validateAndFormatPhoneNumber(userData.phoneNumber);
      }

      const response = await axios.put(`/api/users/${userData._id}`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email?.toLowerCase(),
        phoneNumber: userData.phoneNumber,
        organizationId: userData.organizationId,
        role: userData.role,
        authMethod: userData.authMethod,
        authMethods: userData.authMethods,
        isActive: userData.isActive,
        securitySettings: {
          mfaEnabled: userData.securitySettings?.mfaEnabled,
          emailVerified: userData.securitySettings?.emailVerified,
          phoneVerified: userData.securitySettings?.phoneVerified,
        },
        settings: {
          theme: userData.settings?.theme,
          preferences: userData.settings?.preferences
        }
      });

      // Update local state with the response data
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user._id === userData._id ? response.data.user : user
        )
      );

      toast({
        title: 'Success',
        description: `Successfully updated user ${userData.email}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onUserModalClose();
    } catch (error) {
      console.error('Failed to update user:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to update user',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOrgEdit = async (orgData) => {
    try {
      console.log('Updating organization:', orgData);
      const response = await axios.put(`/api/organizations/${orgData._id}`, {
        name: orgData.name,
        type: orgData.type,
        status: orgData.status,
        subscription: {
          status: orgData.subscription?.status
        }
      });

      toast({
        title: 'Organization Updated',
        description: `Successfully updated ${orgData.name}`,
        status: 'success',
        duration: 3000,
      });
      
      await fetchData(); // Refresh data after successful update
    } catch (error) {
      console.error('Failed to update organization:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to update organization',
        status: 'error',
        duration: 5000,
      });
    }
  };

  const handleAssignUserToOrg = async (userId, orgId) => {
    try {
      // First update the user's organizationId
      await axios.put(`/api/users/${userId}`, {
        organizationId: orgId
      });

      // Then add the user to the organization's users array
      await axios.post(`/api/organizations/${orgId}/add-user`, {
        userId,
        role: 'user'
      });

      toast({
        title: 'Success',
        description: 'Successfully assigned user to organization',
        status: 'success',
        duration: 3000,
      });
      
      await fetchData();
    } catch (error) {
      console.error('Failed to assign user:', error);
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to assign user',
        status: 'error',
        duration: 5000,
      });
    }
  };

  const handleBulkDelete = async () => {
    try {
      const response = await axios.post('/api/users/bulk-delete', {
        userIds: selectedUsers
      });
      
      toast({
        title: 'Success',
        description: `Successfully deleted ${response.data.deletedCount} users`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      
      setSelectedUsers([]);
      fetchData();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to delete users',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsDeleteConfirmOpen(false);
  };

  const handleBulkOrgDelete = async () => {
    try {
      const response = await axios.post('/api/organizations/bulk-delete', {
        organizationIds: selectedOrgs
      });
      
      toast({
        title: 'Success',
        description: `Successfully deleted ${response.data.deletedCount} organizations`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      
      setSelectedOrgs([]);
      fetchData();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to delete organizations',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsOrgDeleteConfirmOpen(false);
  };

  const handleBulkUserEdit = async () => {
    try {
      const response = await axios.post('/api/users/bulk-update', {
        userIds: selectedUsers,
        updates: bulkEditData
      });
      
      toast({
        title: 'Success',
        description: `Successfully updated ${response.data.modifiedCount} users`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      
      setSelectedUsers([]);
      setIsBulkEditModalOpen(false);
      fetchData();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to update users',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleBulkOrgEdit = async () => {
    try {
      const response = await axios.post('/api/organizations/bulk-update', {
        organizationIds: selectedOrgs,
        updates: bulkOrgEditData
      });
      
      toast({
        title: 'Success',
        description: `Successfully updated ${response.data.modifiedCount} organizations`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      
      setSelectedOrgs([]);
      setIsBulkOrgEditModalOpen(false);
      fetchData();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to update organizations',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const UserEditModal = () => {
    const { isDark, currentTheme } = useTheme();
    const [localUser, setLocalUser] = useState(selectedUser);

    useEffect(() => {
      setLocalUser(selectedUser);
    }, [selectedUser]);

    const handleAuthMethodsChange = (method, checked) => {
      const methods = checked
        ? [...(localUser?.authMethods || []), method]
        : localUser?.authMethods?.filter(m => m !== method);
      
      setLocalUser(prev => ({
        ...prev,
        authMethods: methods
      }));
    };

    const modalStyles = {
      border: '1px solid',
      borderColor: isDark ? currentTheme.colors.border.dark : currentTheme.colors.border.light,
      bg: isDark ? currentTheme.colors.bg.dark : currentTheme.colors.bg.light,
      boxShadow: isDark ? '0 0 10px var(--primary-accent)' : '0 4px 6px rgba(0, 0, 0, 0.1)',
    };

    const sectionStyles = {
      border: '1px solid',
      borderColor: isDark ? currentTheme.colors.border.dark : currentTheme.colors.border.light,
      borderRadius: 'md',
      p: 4,
      bg: isDark ? 'surfaceBg' : 'white',
      transition: 'all 0.2s ease',
      _hover: {
        boxShadow: isDark ? '0 0 15px var(--primary-accent)' : '0 6px 8px rgba(0, 0, 0, 0.15)',
      }
    };

    const headingColor = isDark ? 'primaryAccent' : 'blue.500';

    return (
      <Modal 
        isOpen={isUserModalOpen} 
        onClose={onUserModalClose} 
        size="6xl"
        scrollBehavior="inside"
      >
        <ModalOverlay 
          bg={isDark ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.4)'}
          backdropFilter="blur(5px)"
        />
        <ModalContent {...modalStyles}>
          <ModalHeader 
            borderBottom="1px solid"
            borderColor={isDark ? 'primaryAccent' : 'gray.200'}
            color={headingColor}
          >
            <HStack justify="space-between">
              <Text>Edit User: {selectedUser?.email}</Text>
              <Badge 
                colorScheme={selectedUser?.isActive ? 'green' : 'red'}
                fontSize="0.8em"
              >
                {selectedUser?.isActive ? 'Active' : 'Inactive'}
              </Badge>
            </HStack>
          </ModalHeader>

          <ModalBody py={6}>
            <VStack spacing={6} align="stretch">
              {/* Basic Information */}
              <Box {...sectionStyles}>
                <Heading size="sm" mb={4} color={headingColor}>
                  Basic Information
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input 
                      value={selectedUser?.firstName || ''}
                      onChange={(e) => setSelectedUser({...selectedUser, firstName: e.target.value})}
                      variant={isDark ? 'filled' : 'outline'}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input 
                      value={selectedUser?.lastName || ''}
                      onChange={(e) => setSelectedUser({...selectedUser, lastName: e.target.value})}
                      variant={isDark ? 'filled' : 'outline'}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input 
                      value={selectedUser?.email || ''}
                      onChange={(e) => setSelectedUser({...selectedUser, email: e.target.value})}
                      type="email"
                      variant={isDark ? 'filled' : 'outline'}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Phone Number</FormLabel>
                    <Input 
                      value={selectedUser?.phoneNumber || ''}
                      onChange={(e) => setSelectedUser({...selectedUser, phoneNumber: e.target.value})}
                      variant={isDark ? 'filled' : 'outline'}
                    />
                  </FormControl>
                </SimpleGrid>
              </Box>

              {/* Organization & Role */}
              <Box {...sectionStyles}>
                <Heading size="sm" mb={4} color={headingColor}>
                  Organization & Role
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <FormControl>
                    <FormLabel>Organization</FormLabel>
                    <Select
                      value={selectedUser?.organizationId?._id || ''}
                      onChange={(e) => setSelectedUser({...selectedUser, organizationId: e.target.value})}
                      variant={isDark ? 'filled' : 'outline'}
                    >
                      <option value="">No Organization</option>
                      {organizations.map(org => (
                        <option key={org._id} value={org._id}>{org.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Role</FormLabel>
                    <Select
                      value={selectedUser?.role || ''}
                      onChange={(e) => setSelectedUser({...selectedUser, role: e.target.value})}
                      variant={isDark ? 'filled' : 'outline'}
                    >
                      {['brand_ambassador', 'contractor', 'agent', 'office_admin', 'manager', 'owner', 'super_admin']
                        .map(role => (
                          <option key={role} value={role}>
                            {role.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </SimpleGrid>
              </Box>

              {/* Authentication Settings */}
              <Box {...sectionStyles}>
                <Heading size="sm" mb={4} color={headingColor}>
                  Authentication Settings
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <FormControl>
                    <FormLabel>Primary Auth Method</FormLabel>
                    <Select
                      value={localUser?.authMethod || ''}
                      onChange={(e) => setLocalUser(prev => ({
                        ...prev,
                        authMethod: e.target.value
                      }))}
                      variant={isDark ? 'filled' : 'outline'}
                    >
                      <option value="google">Google</option>
                      <option value="password">Password</option>
                      <option value="phone">Phone</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Auth Methods</FormLabel>
                    <Stack direction="row" spacing={2}>
                      {['google', 'password', 'phone'].map(method => (
                        <Checkbox
                          key={method}
                          isChecked={localUser?.authMethods?.includes(method)}
                          onChange={(e) => handleAuthMethodsChange(method, e.target.checked)}
                          colorScheme={isDark ? 'yellow' : 'blue'}
                        >
                          {method.charAt(0).toUpperCase() + method.slice(1)}
                        </Checkbox>
                      ))}
                    </Stack>
                  </FormControl>
                </SimpleGrid>
              </Box>

              {/* Security Settings */}
              <Box {...sectionStyles}>
                <Heading size="sm" mb={4} color={headingColor}>
                  Security Settings
                </Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <VStack align="start" spacing={4}>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel mb="0">Account Status</FormLabel>
                      <Switch
                        isChecked={selectedUser?.isActive}
                        onChange={(e) => setSelectedUser({...selectedUser, isActive: e.target.checked})}
                        colorScheme={isDark ? 'yellow' : 'blue'}
                      />
                    </FormControl>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel mb="0">MFA Enabled</FormLabel>
                      <Switch
                        isChecked={selectedUser?.securitySettings?.mfaEnabled}
                        onChange={(e) => setSelectedUser({
                          ...selectedUser,
                          securitySettings: {
                            ...selectedUser.securitySettings,
                            mfaEnabled: e.target.checked
                          }
                        })}
                        colorScheme={isDark ? 'yellow' : 'blue'}
                      />
                    </FormControl>
                  </VStack>
                  <VStack align="start" spacing={4}>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel mb="0">Email Verified</FormLabel>
                      <Switch
                        isChecked={selectedUser?.securitySettings?.emailVerified}
                        onChange={(e) => setSelectedUser({
                          ...selectedUser,
                          securitySettings: {
                            ...selectedUser.securitySettings,
                            emailVerified: e.target.checked
                          }
                        })}
                        colorScheme={isDark ? 'yellow' : 'blue'}
                      />
                    </FormControl>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel mb="0">Phone Verified</FormLabel>
                      <Switch
                        isChecked={selectedUser?.securitySettings?.phoneVerified}
                        onChange={(e) => setSelectedUser({
                          ...selectedUser,
                          securitySettings: {
                            ...selectedUser.securitySettings,
                            phoneVerified: e.target.checked
                          }
                        })}
                        colorScheme={isDark ? 'yellow' : 'blue'}
                      />
                    </FormControl>
                  </VStack>
                </SimpleGrid>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter
            borderTop="1px solid"
            borderColor={isDark ? 'primaryAccent' : 'gray.200'}
          >
            <Button 
              colorScheme={isDark ? 'yellow' : 'blue'} 
              mr={3} 
              onClick={() => handleUserEdit(localUser)}
            >
              Save Changes
            </Button>
            <Button variant="ghost" onClick={onUserModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const UsersTable = () => (
    <>
      <HStack mb={4} justify="space-between">
        <Text>Selected: {selectedUsers.length} users</Text>
        {selectedUsers.length > 0 && (
          <ButtonGroup>
            <Button
              colorScheme="blue"
              leftIcon={<EditIcon />}
              onClick={() => setIsBulkEditModalOpen(true)}
            >
              Bulk Edit ({selectedUsers.length})
            </Button>
            <Button
              colorScheme="red"
              leftIcon={<DeleteIcon />}
              onClick={() => setIsDeleteConfirmOpen(true)}
            >
              Delete Selected ({selectedUsers.length})
            </Button>
          </ButtonGroup>
        )}
      </HStack>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>
              <Checkbox
                isChecked={users.length > 0 && selectedUsers.length === users.length}
                isIndeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedUsers(users.map(user => user._id));
                  } else {
                    setSelectedUsers([]);
                  }
                }}
              />
            </Th>
            <Th>ID</Th>
            <Th>Name</Th>
            <Th>Contact Info</Th>
            <Th>Auth Details</Th>
            <Th>Organization</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user._id}>
              <Td>
                <Checkbox
                  isChecked={selectedUsers.includes(user._id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedUsers([...selectedUsers, user._id]);
                    } else {
                      setSelectedUsers(selectedUsers.filter(id => id !== user._id));
                    }
                  }}
                />
              </Td>
              <Td>
                <Tooltip label="Click to copy ID">
                  <Text
                    cursor="pointer"
                    fontSize="sm"
                    fontFamily="monospace"
                    onClick={() => {
                      navigator.clipboard.writeText(user._id);
                      toast({
                        title: "ID Copied",
                        description: "User ID copied to clipboard",
                        status: "success",
                        duration: 2000,
                      });
                    }}
                  >
                    {user._id}
                  </Text>
                </Tooltip>
              </Td>
              <Td>
                <VStack align="start" spacing={0}>
                  <Text>{user.firstName} {user.lastName}</Text>
                  <Text fontSize="sm" color="gray.500">
                    Role: <Badge colorScheme="purple">{user.role}</Badge>
                  </Text>
                </VStack>
              </Td>
              <Td>
                <VStack align="start" spacing={1}>
                  <Text fontSize="sm">
                    📧 {user.email}
                  </Text>
                  {user.phoneNumber && (
                    <Text fontSize="sm">
                      📱 {user.phoneNumber}
                    </Text>
                  )}
                  {user.contactInfo?.phone && user.contactInfo.phone !== user.phoneNumber && (
                    <Text fontSize="sm" color="gray.500">
                       {user.contactInfo.phone}
                    </Text>
                  )}
                </VStack>
              </Td>
              <Td>
                <VStack align="start" spacing={1}>
                  <HStack>
                    <Text fontSize="sm">Methods:</Text>
                    {user.authMethods?.map(method => (
                      <Badge key={method} colorScheme={
                        method === 'google' ? 'red' :
                        method === 'phone' ? 'green' : 'blue'
                      }>
                        {method}
                      </Badge>
                    ))}
                  </HStack>
                  <HStack>
                    <Text fontSize="sm">Verified:</Text>
                    <Badge colorScheme={user.securitySettings?.emailVerified ? 'green' : 'red'}>
                      Email {user.securitySettings?.emailVerified ? '✓' : '✗'}
                    </Badge>
                    {user.phoneNumber && (
                      <Badge colorScheme={user.securitySettings?.phoneVerified ? 'green' : 'red'}>
                        Phone {user.securitySettings?.phoneVerified ? '✓' : '✗'}
                      </Badge>
                    )}
                  </HStack>
                </VStack>
              </Td>
              <Td>
                {user.organizationId ? (
                  <VStack align="start" spacing={0}>
                    <Text fontSize="sm">{user.organizationId.name}</Text>
                    <Badge size="sm" colorScheme={
                      user.organizationId.status === 'active' ? 'green' : 'red'
                    }>
                      {user.organizationId.type} - {user.organizationId.status}
                    </Badge>
                  </VStack>
                ) : (
                  <Text fontSize="sm" color="gray.500">No Organization</Text>
                )}
              </Td>
              <Td>
                <Badge colorScheme={user.isActive ? 'green' : 'red'}>
                  {user.isActive ? 'Active' : 'Inactive'}
                </Badge>
              </Td>
              <Td>
                <ButtonGroup size="sm">
                  <Tooltip label="Edit User">
                    <IconButton
                      icon={<EditIcon />}
                      onClick={() => {
                        setSelectedUser(user);
                        onUserModalOpen();
                      }}
                    />
                  </Tooltip>
                  <Tooltip label="Delete User">
                    <IconButton
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      onClick={() => {
                        setSelectedUser(user);
                        onDeleteAlertOpen();
                      }}
                    />
                  </Tooltip>
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <AlertDialog
        isOpen={isDeleteConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteConfirmOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete Users</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete {selectedUsers.length} users? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteConfirmOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" ml={3} onClick={handleBulkDelete}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );

  const OrganizationEditModal = () => (
    <Modal isOpen={isOrgModalOpen} onClose={onOrgModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Organization: {selectedOrg?.name}</ModalHeader>
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Name</FormLabel>
            <Input 
              value={selectedOrg?.name || ''}
              onChange={(e) => setSelectedOrg({...selectedOrg, name: e.target.value})}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Type</FormLabel>
            <Select
              value={selectedOrg?.type || ''}
              onChange={(e) => setSelectedOrg({...selectedOrg, type: e.target.value})}
            >
              <option value="solo">Solo</option>
              <option value="team">Team</option>
              <option value="enterprise">Enterprise</option>
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Subscription Status</FormLabel>
            <Select
              value={selectedOrg?.subscription?.status || ''}
              onChange={(e) => setSelectedOrg({
                ...selectedOrg, 
                subscription: { ...selectedOrg.subscription, status: e.target.value }
              })}
            >
              <option value="trial">Trial</option>
              <option value="active">Active</option>
              <option value="past_due">Past Due</option>
              <option value="cancelled">Cancelled</option>
              <option value="inactive">Inactive</option>
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Organization Status</FormLabel>
            <Select
              value={selectedOrg?.status || ''}
              onChange={(e) => setSelectedOrg({...selectedOrg, status: e.target.value})}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="suspended">Suspended</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => {
            handleOrgEdit(selectedOrg);
            onOrgModalClose();
          }}>
            Save
          </Button>
          <Button onClick={onOrgModalClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const BulkUserEditModal = () => (
    <Modal isOpen={isBulkEditModalOpen} onClose={() => setIsBulkEditModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Bulk Edit Users ({selectedUsers.length} selected)</ModalHeader>
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Role</FormLabel>
            <Select
              value={bulkEditData.role || ''}
              onChange={(e) => setBulkEditData({...bulkEditData, role: e.target.value})}
            >
              <option value="">No Change</option>
              {['brand_ambassador', 'contractor', 'agent', 'office_admin', 'manager', 'owner']
                .map(role => (
                  <option key={role} value={role}>
                    {role.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  </option>
                ))}
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Status</FormLabel>
            <Select
              value={bulkEditData.isActive === undefined ? '' : bulkEditData.isActive}
              onChange={(e) => setBulkEditData({...bulkEditData, isActive: e.target.value === 'true'})}
            >
              <option value="">No Change</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleBulkUserEdit}>
            Update All
          </Button>
          <Button onClick={() => setIsBulkEditModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const BulkOrgEditModal = () => (
    <Modal isOpen={isBulkOrgEditModalOpen} onClose={() => setIsBulkOrgEditModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Bulk Edit Organizations ({selectedOrgs.length} selected)</ModalHeader>
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Type</FormLabel>
            <Select
              value={bulkOrgEditData.type || ''}
              onChange={(e) => setBulkOrgEditData({...bulkOrgEditData, type: e.target.value})}
            >
              <option value="">No Change</option>
              <option value="solo">Solo</option>
              <option value="team">Team</option>
              <option value="enterprise">Enterprise</option>
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Status</FormLabel>
            <Select
              value={bulkOrgEditData.status || ''}
              onChange={(e) => setBulkOrgEditData({...bulkOrgEditData, status: e.target.value})}
            >
              <option value="">No Change</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="suspended">Suspended</option>
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Subscription Status</FormLabel>
            <Select
              value={bulkOrgEditData.subscriptionStatus || ''}
              onChange={(e) => setBulkOrgEditData({...bulkOrgEditData, subscriptionStatus: e.target.value})}
            >
              <option value="">No Change</option>
              <option value="trial">Trial</option>
              <option value="active">Active</option>
              <option value="past_due">Past Due</option>
              <option value="cancelled">Cancelled</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleBulkOrgEdit}>
            Update All
          </Button>
          <Button onClick={() => setIsBulkOrgEditModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  if (loading) {
    return (
      <Box p={8} display="flex" justifyContent="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={8}>
        <Heading mb={6}>System Management</Heading>
        <Tabs>
          <TabList>
            <Tab>Users</Tab>
            <Tab>Organizations</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Card>
                <CardHeader>
                  <Text fontSize="xl">All Users ({users.length})</Text>
                </CardHeader>
                <CardBody>
                  <UsersTable />
                </CardBody>
              </Card>
            </TabPanel>

            <TabPanel>
              <Card>
                <CardHeader>
                  <HStack justify="space-between">
                    <Text fontSize="xl">All Organizations ({organizations.length})</Text>
                    {selectedOrgs.length > 0 && (
                      <ButtonGroup>
                        <Button
                          colorScheme="blue"
                          leftIcon={<EditIcon />}
                          onClick={() => setIsBulkOrgEditModalOpen(true)}
                        >
                          Bulk Edit ({selectedOrgs.length})
                        </Button>
                        <Button
                          colorScheme="red"
                          leftIcon={<DeleteIcon />}
                          onClick={() => setIsOrgDeleteConfirmOpen(true)}
                        >
                          Delete Selected ({selectedOrgs.length})
                        </Button>
                      </ButtonGroup>
                    )}
                  </HStack>
                </CardHeader>
                <CardBody>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>
                          <Checkbox
                            isChecked={organizations.length > 0 && selectedOrgs.length === organizations.length}
                            isIndeterminate={selectedOrgs.length > 0 && selectedOrgs.length < organizations.length}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedOrgs(organizations.map(org => org._id));
                              } else {
                                setSelectedOrgs([]);
                              }
                            }}
                          />
                        </Th>
                        <Th>ID</Th>
                        <Th>Name</Th>
                        <Th>Type</Th>
                        <Th>Subscription</Th>
                        <Th>Members</Th>
                        <Th>Status</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {organizations.map((org) => (
                        <Tr key={org._id}>
                          <Td>
                            <Checkbox
                              isChecked={selectedOrgs.includes(org._id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedOrgs([...selectedOrgs, org._id]);
                                } else {
                                  setSelectedOrgs(selectedOrgs.filter(id => id !== org._id));
                                }
                              }}
                            />
                          </Td>
                          <Td>
                            <Tooltip label="Click to copy ID">
                              <Text
                                cursor="pointer"
                                fontSize="sm"
                                fontFamily="monospace"
                                onClick={() => {
                                  navigator.clipboard.writeText(org._id);
                                  toast({
                                    title: "ID Copied",
                                    description: "Organization ID copied to clipboard",
                                    status: "success",
                                    duration: 2000,
                                  });
                                }}
                              >
                                {org._id}
                              </Text>
                            </Tooltip>
                          </Td>
                          <Td>{org.name || 'Unnamed Organization'}</Td>
                          <Td>
                            <Badge colorScheme="purple">
                              {org.type || 'unknown'}
                            </Badge>
                          </Td>
                          <Td>
                            <Badge colorScheme={getSubscriptionColor(org.subscription)}>
                              {getSubscriptionStatus(org)}
                            </Badge>
                          </Td>
                          <Td>
                            <VStack align="start" spacing={1}>
                              {org.users?.filter(user => user?.user)  // Filter out null users
                                .map((user) => (
                                  <HStack key={user.user._id}>
                                    <Text fontSize="sm">
                                      {user.user?.firstName || 'Unknown'} {user.user?.lastName || ''}
                                    </Text>
                                    <Badge size="sm" colorScheme="blue">
                                      {user.role || 'user'}
                                    </Badge>
                                  </HStack>
                                ))}
                              {(!org.users || org.users.length === 0) && (
                                <Text fontSize="sm" color="gray.500">
                                  No members
                                </Text>
                              )}
                            </VStack>
                          </Td>
                          <Td>
                            <Badge colorScheme={org.status === 'active' ? 'green' : 'red'}>
                              {org.status || 'unknown'}
                            </Badge>
                          </Td>
                          <Td>
                            <ButtonGroup size="sm">
                              <Tooltip label="Edit Organization">
                                <IconButton
                                  icon={<EditIcon />}
                                  onClick={() => {
                                    setSelectedOrg(org);
                                    onOrgModalOpen();
                                  }}
                                />
                              </Tooltip>
                              <Tooltip label="Delete Organization">
                                <IconButton
                                  icon={<DeleteIcon />}
                                  colorScheme="red"
                                  onClick={() => {
                                    setSelectedOrg(org);
                                    onDeleteAlertOpen();
                                  }}
                                />
                              </Tooltip>
                            </ButtonGroup>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>

                  <AlertDialog
                    isOpen={isOrgDeleteConfirmOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={() => setIsOrgDeleteConfirmOpen(false)}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader>Delete Organizations</AlertDialogHeader>
                        <AlertDialogBody>
                          Are you sure you want to delete {selectedOrgs.length} organizations? This action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                          <Button ref={cancelRef} onClick={() => setIsOrgDeleteConfirmOpen(false)}>
                            Cancel
                          </Button>
                          <Button colorScheme="red" ml={3} onClick={handleBulkOrgDelete}>
                            Delete
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </CardBody>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );
  }

  return (
    <Box p={8} bg={bgColor}>
      <Heading mb={6} color={textColor}>
        System Management
      </Heading>
      
      {currentUserFull && (
        <Card {...cardStyles} mb={6}>
          <CardHeader>
            <Text fontSize="xl">Current User Details</Text>
          </CardHeader>
          <CardBody>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
              {/* Basic Information */}
              <VStack align="start">
                <Text fontWeight="bold">Basic Info</Text>
                <Text>ID: {currentUserFull._id}</Text>
                <Text>Name: {currentUserFull.firstName} {currentUserFull.lastName}</Text>
                <Text>Email: {currentUserFull.email}</Text>
                <Text>Phone: {currentUserFull.phoneNumber || 'N/A'}</Text>
                <Text>Role: <Badge colorScheme="purple">{currentUserFull.role}</Badge></Text>
                <Text>Created: {new Date(currentUserFull.createdAt).toLocaleString()}</Text>
                <Text>Updated: {new Date(currentUserFull.updatedAt).toLocaleString()}</Text>
              </VStack>

              {/* Authentication Details */}
              <VStack align="start">
                <Text fontWeight="bold">Authentication</Text>
                <Text>Firebase UID: {currentUserFull.firebaseUid}</Text>
                <Text>Primary Method: <Badge>{currentUserFull.authMethod}</Badge></Text>
                <Text>Auth Methods: {currentUserFull.authMethods?.map(method => (
                  <Badge key={method} ml={1} colorScheme="blue">{method}</Badge>
                ))}</Text>
                <Text>Status: <Badge colorScheme={currentUserFull.isActive ? 'green' : 'red'}>
                  {currentUserFull.isActive ? 'Active' : 'Inactive'}
                </Badge></Text>
                
                {/* Security Settings */}
                <Text fontWeight="bold" mt={4}>Security Settings</Text>
                <HStack>
                  <Badge colorScheme={currentUserFull.securitySettings?.mfaEnabled ? 'green' : 'red'}>
                    MFA {currentUserFull.securitySettings?.mfaEnabled ? 'Enabled' : 'Disabled'}
                  </Badge>
                  <Badge colorScheme={currentUserFull.securitySettings?.emailVerified ? 'green' : 'red'}>
                    Email {currentUserFull.securitySettings?.emailVerified ? 'Verified' : 'Unverified'}
                  </Badge>
                  <Badge colorScheme={currentUserFull.securitySettings?.phoneVerified ? 'green' : 'red'}>
                    Phone {currentUserFull.securitySettings?.phoneVerified ? 'Verified' : 'Unverified'}
                  </Badge>
                </HStack>
                <Text>Failed Logins: {currentUserFull.securitySettings?.failedLoginAttempts || 0}</Text>
                {currentUserFull.securitySettings?.lastFailedLogin && (
                  <Text>Last Failed: {new Date(currentUserFull.securitySettings.lastFailedLogin).toLocaleString()}</Text>
                )}
              </VStack>

              {/* Additional Details */}
              <VStack align="start">
                <Text fontWeight="bold">Organization</Text>
                {currentUserFull.organizationId ? (
                  <>
                    <Text>Org ID: {currentUserFull.organizationId._id}</Text>
                    <Text>Name: {currentUserFull.organizationId.name}</Text>
                    <Text>Type: <Badge>{currentUserFull.organizationId.type}</Badge></Text>
                    <Text>Status: <Badge colorScheme={
                      currentUserFull.organizationId.status === 'active' ? 'green' : 'red'
                    }>{currentUserFull.organizationId.status}</Badge></Text>
                  </>
                ) : (
                  <Text>No Organization</Text>
                )}

                {/* Settings */}
                <Text fontWeight="bold" mt={4}>Preferences</Text>
                <Text>Theme: {currentUserFull.settings?.theme || 'default'}</Text>
                <Text>Language: {currentUserFull.settings?.preferences?.language || 'en'}</Text>
                <Text>Email Updates: {currentUserFull.settings?.preferences?.emailUpdates ? 'Yes' : 'No'}</Text>
                
                {/* Known Devices */}
                {currentUserFull.knownDevices?.length > 0 && (
                  <>
                    <Text fontWeight="bold" mt={4}>Known Devices</Text>
                    {currentUserFull.knownDevices.map((device, index) => (
                      <Box key={index} fontSize="sm">
                        <Text>Device {index + 1}: {device.details?.browser?.name || 'Unknown'}</Text>
                        <Text>Last Seen: {new Date(device.lastSeen).toLocaleDateString()}</Text>
                      </Box>
                    ))}
                  </>
                )}
              </VStack>
            </SimpleGrid>
          </CardBody>
        </Card>
      )}

      <Tabs>
        <TabList>
          <Tab>Users</Tab>
          <Tab>Organizations</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Card {...cardStyles}>
              <CardHeader>
                <Text fontSize="xl">All Users ({users.length})</Text>
              </CardHeader>
              <CardBody>
                <UsersTable />
              </CardBody>
            </Card>
          </TabPanel>

          <TabPanel>
            <Card {...cardStyles}>
              <CardHeader>
                <HStack justify="space-between">
                  <Text fontSize="xl">All Organizations ({organizations.length})</Text>
                  {selectedOrgs.length > 0 && (
                    <ButtonGroup>
                      <Button
                        colorScheme="blue"
                        leftIcon={<EditIcon />}
                        onClick={() => setIsBulkOrgEditModalOpen(true)}
                      >
                        Bulk Edit ({selectedOrgs.length})
                      </Button>
                      <Button
                        colorScheme="red"
                        leftIcon={<DeleteIcon />}
                        onClick={() => setIsOrgDeleteConfirmOpen(true)}
                      >
                        Delete Selected ({selectedOrgs.length})
                      </Button>
                    </ButtonGroup>
                  )}
                </HStack>
              </CardHeader>
              <CardBody>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>
                        <Checkbox
                          isChecked={organizations.length > 0 && selectedOrgs.length === organizations.length}
                          isIndeterminate={selectedOrgs.length > 0 && selectedOrgs.length < organizations.length}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedOrgs(organizations.map(org => org._id));
                            } else {
                              setSelectedOrgs([]);
                            }
                          }}
                        />
                      </Th>
                      <Th>ID</Th>
                      <Th>Name</Th>
                      <Th>Type</Th>
                      <Th>Subscription</Th>
                      <Th>Members</Th>
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {organizations.map((org) => (
                      <Tr key={org._id}>
                        <Td>
                          <Checkbox
                            isChecked={selectedOrgs.includes(org._id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedOrgs([...selectedOrgs, org._id]);
                              } else {
                                setSelectedOrgs(selectedOrgs.filter(id => id !== org._id));
                              }
                            }}
                          />
                        </Td>
                        <Td>
                          <Tooltip label="Click to copy ID">
                            <Text
                              cursor="pointer"
                              fontSize="sm"
                              fontFamily="monospace"
                              onClick={() => {
                                navigator.clipboard.writeText(org._id);
                                toast({
                                  title: "ID Copied",
                                  description: "Organization ID copied to clipboard",
                                  status: "success",
                                  duration: 2000,
                                });
                              }}
                            >
                              {org._id}
                            </Text>
                          </Tooltip>
                        </Td>
                        <Td>{org.name || 'Unnamed Organization'}</Td>
                        <Td>
                          <Badge colorScheme="purple">
                            {org.type || 'unknown'}
                          </Badge>
                        </Td>
                        <Td>
                          <Badge colorScheme={getSubscriptionColor(org.subscription)}>
                            {getSubscriptionStatus(org)}
                          </Badge>
                        </Td>
                        <Td>
                          <VStack align="start" spacing={1}>
                            {org.users?.filter(user => user?.user)  // Filter out null users
                              .map((user) => (
                                <HStack key={user.user._id}>
                                  <Text fontSize="sm">
                                    {user.user?.firstName || 'Unknown'} {user.user?.lastName || ''}
                                  </Text>
                                  <Badge size="sm" colorScheme="blue">
                                    {user.role || 'user'}
                                  </Badge>
                                </HStack>
                              ))}
                            {(!org.users || org.users.length === 0) && (
                              <Text fontSize="sm" color="gray.500">
                                No members
                              </Text>
                            )}
                          </VStack>
                        </Td>
                        <Td>
                          <Badge colorScheme={org.status === 'active' ? 'green' : 'red'}>
                            {org.status || 'unknown'}
                          </Badge>
                        </Td>
                        <Td>
                          <ButtonGroup size="sm">
                            <Tooltip label="Edit Organization">
                              <IconButton
                                icon={<EditIcon />}
                                onClick={() => {
                                  setSelectedOrg(org);
                                  onOrgModalOpen();
                                }}
                              />
                            </Tooltip>
                            <Tooltip label="Delete Organization">
                              <IconButton
                                icon={<DeleteIcon />}
                                colorScheme="red"
                                onClick={() => {
                                  setSelectedOrg(org);
                                  onDeleteAlertOpen();
                                }}
                              />
                            </Tooltip>
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>

                <AlertDialog
                  isOpen={isOrgDeleteConfirmOpen}
                  leastDestructiveRef={cancelRef}
                  onClose={() => setIsOrgDeleteConfirmOpen(false)}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader>Delete Organizations</AlertDialogHeader>
                      <AlertDialogBody>
                        Are you sure you want to delete {selectedOrgs.length} organizations? This action cannot be undone.
                      </AlertDialogBody>
                      <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={() => setIsOrgDeleteConfirmOpen(false)}>
                          Cancel
                        </Button>
                        <Button colorScheme="red" ml={3} onClick={handleBulkOrgDelete}>
                          Delete
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {selectedUser && <UserEditModal />}
      {selectedOrg && <OrganizationEditModal />}
      <BulkUserEditModal />
      <BulkOrgEditModal />
    </Box>
  );
}

export default Debugging;
