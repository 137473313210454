import React from 'react';
import {
  Box, Button, Container, Heading, Text, Stack, SimpleGrid, 
  Icon, Flex, VStack, Stat, StatLabel, StatNumber, StatHelpText,
  StatArrow
} from '@chakra-ui/react';
import { BsTelephone, BsPeople, BsGraphUp, BsChatDots } from 'react-icons/bs';
import { FaHome, FaUserPlus, FaHandshake, FaDollarSign } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const StatCard = ({ title, stat, icon, helpText, onClick }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        p={6}
        bg="gray.800"
        borderRadius="lg"
        border="1px solid"
        borderColor="orange.400"
        cursor={onClick ? "pointer" : "default"}
        onClick={onClick}
        _hover={{
          transform: "translateY(-5px)",
          boxShadow: "xl",
          borderColor: "orange.500"
        }}
        transition="all 0.3s"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Stat>
            <StatLabel color="gray.400">{title}</StatLabel>
            <StatNumber fontSize="3xl" color="white">{stat}</StatNumber>
            {helpText && (
              <StatHelpText color="orange.400">{helpText}</StatHelpText>
            )}
          </Stat>
          <Icon as={icon} w={8} h={8} color="orange.400" />
        </Flex>
      </Box>
    </motion.div>
  );
};

const TopDashboardSection = ({ theme }) => {
  const navigate = useNavigate();

  return (
    <Box mb={8}>
      <Heading 
        mb={6}
        fontSize={{ base: "2xl", md: "4xl" }}
        textAlign="center"
        color="white"
      >
        Welcome to Real•Talk•Pro Dashboard
      </Heading>

      {/* Stats Cards */}
      <SimpleGrid 
        columns={{ base: 1, md: 2, lg: 4 }} 
        spacing={{ base: 4, md: 6 }}
        mb={{ base: 6, md: 8 }}
      >
        <StatCard
          title="Total Leads"
          stat="247"
          icon={BsPeople}
          helpText="+12% from last month"
          onClick={() => navigate('/leads')}
        />
        <StatCard
          title="Calls Made"
          stat="1,432"
          icon={BsTelephone}
          helpText="Last 30 days"
          onClick={() => navigate('/calls-log')}
        />
        <StatCard
          title="Messages Sent"
          stat="893"
          icon={BsChatDots}
          helpText="85% response rate"
          onClick={() => navigate('/communications')}
        />
        <StatCard
          title="Conversion Rate"
          stat="24%"
          icon={BsGraphUp}
          helpText="↑ 5% increase"
          onClick={() => navigate('/reports')}
        />
      </SimpleGrid>

      {/* Quick Actions */}
      <Box
        p={{ base: 4, md: 6 }}
        bg="gray.800"
        borderRadius="lg"
        border="1px solid"
        borderColor="orange.400"
        mb={{ base: 6, md: 8 }}
      >
        <Heading size="md" color="white" mb={4}>
          Quick Actions
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 3, md: 4 }}>
          <Button
            leftIcon={<BsPeople />}
            colorScheme="orange"
            variant="outline"
            onClick={() => navigate('/leads/new')}
          >
            Add New Lead
          </Button>
          <Button
            leftIcon={<BsTelephone />}
            colorScheme="orange"
            variant="outline"
            onClick={() => navigate('/call-script')}
          >
            Start Call Script
          </Button>
          <Button
            leftIcon={<BsGraphUp />}
            colorScheme="orange"
            variant="outline"
            onClick={() => navigate('/reports')}
          >
            View Reports
          </Button>
        </SimpleGrid>
      </Box>

      {/* Original KPI Section and other content... */}
      {/* Keep the rest of your existing TopDashboardSection code here */}
    </Box>
  );
};

export default TopDashboardSection; 