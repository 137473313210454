import React, { createContext, useContext, useState, useEffect } from 'react';
import darkTheme from '../theme/darkTheme';
import lightTheme from '../theme/lightTheme';
import { useLocation } from 'react-router-dom';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const location = useLocation();
  const isRTPDash2 = location.pathname === '/rtp-dash2';

  // Check system preference
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  
  const [isDark, setIsDark] = useState(() => {
    const savedTheme = localStorage.getItem('isDarkTheme');
    return savedTheme ? JSON.parse(savedTheme) : prefersDark.matches;
  });
  
  const [showBorders, setShowBorders] = useState(true);

  // Save theme preference
  useEffect(() => {
    localStorage.setItem('isDarkTheme', JSON.stringify(isDark));
  }, [isDark]);

  // Listen for system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      if (!localStorage.getItem('isDarkTheme')) {
        setIsDark(e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const toggleTheme = () => setIsDark(!isDark);
  const toggleBorders = () => setShowBorders(!showBorders);

  const currentTheme = isRTPDash2 ? lightTheme : (isDark ? darkTheme : lightTheme);

  return (
    <ThemeContext.Provider value={{ 
      isDark, 
      toggleTheme, 
      currentTheme,
      showBorders: isRTPDash2 ? false : showBorders,
      toggleBorders
    }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext); 